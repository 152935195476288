import React, { useState, useCallback } from 'react';
import { FormControlLabel, Checkbox as Checkbox$1 } from '@hermes/web-components';

const Checkbox = ({ defaultValue, isError, label, onChange, }) => {
    const [checked, setChecked] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : false);
    const handleChange = useCallback((e) => {
        var _a, _b;
        const value = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.checked) !== null && _b !== void 0 ? _b : false;
        setChecked(value);
        onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }, []);
    return (React.createElement(FormControlLabel, { sx: { alignItems: 'flex-start' }, control: React.createElement(Checkbox$1, { sx: {
                paddingTop: '1px',
                '& .MuiSvgIcon-root': {
                    fontSize: 22,
                    color: isError ? '#CC4F4A' : 'inherit',
                },
            }, checked: checked, onChange: handleChange }), label: label }));
};

export { Checkbox as default };
