import React from 'react';
import { Container, ValueWrapper, FirstAvailable } from './styles.esm.js';

const DateItem = ({ sx, value, firstAvailable, selected, disabled, onClick, }) => {
    return (React.createElement(Container, { sx: sx, onClick: onClick, disabled: disabled, selected: selected },
        React.createElement(ValueWrapper, { component: "span" }, value),
        firstAvailable ? React.createElement(FirstAvailable, null) : null));
};

export { DateItem as default };
