import React__default from 'react';
import Grid from '../../node_modules/@mui/material/Grid/Grid.esm.js';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

var StarIconDimensions;
(function (StarIconDimensions) {
    StarIconDimensions["XS"] = "xs";
    StarIconDimensions["SMALL"] = "small";
    StarIconDimensions["MEDIUM"] = "medium";
    StarIconDimensions["LARGE"] = "large";
})(StarIconDimensions || (StarIconDimensions = {}));
var StarIconSizeValue;
(function (StarIconSizeValue) {
    StarIconSizeValue[StarIconSizeValue["XS"] = 12] = "XS";
    StarIconSizeValue[StarIconSizeValue["SMALL"] = 16] = "SMALL";
    StarIconSizeValue[StarIconSizeValue["MEDIUM"] = 24] = "MEDIUM";
    StarIconSizeValue[StarIconSizeValue["LARGE"] = 40] = "LARGE";
    StarIconSizeValue[StarIconSizeValue["DEFAULT"] = 32] = "DEFAULT";
})(StarIconSizeValue || (StarIconSizeValue = {}));
const getStarIconSize = (size) => {
    switch (size) {
        case StarIconDimensions.XS:
            return StarIconSizeValue.XS;
        case StarIconDimensions.SMALL:
            return StarIconSizeValue.SMALL;
        case StarIconDimensions.MEDIUM:
            return StarIconSizeValue.MEDIUM;
        case StarIconDimensions.LARGE:
            return StarIconSizeValue.LARGE;
        default:
            return StarIconSizeValue.DEFAULT;
    }
};
const Rating = ({ max = 5, sx, value = 0, size = 'medium', starIconId = '#rating-star-icon', emptyStarIconId = '#rating-empty-star-icon' }) => {
    const iconSize = getStarIconSize(size);
    return (React__default.createElement(Grid, { container: true, gap: "4px", alignItems: "center", height: "100%", wrap: "nowrap", width: "auto", m: "0 2px", boxSizing: "border-box", sx: sx }, Array.from({ length: max }).map((_, index) => index >= value ? (React__default.createElement(SvgIcon, { fontSize: "inherit", viewBox: "0 0 18 17", key: `star-rating-item-${index}-empty`, sx: {
            width: iconSize,
            height: iconSize
        } },
        React__default.createElement("use", { href: emptyStarIconId }))) : (React__default.createElement(SvgIcon, { fontSize: "inherit", viewBox: "0 0 24 23", key: `star-rating-item-${index}`, sx: {
            width: iconSize,
            height: iconSize
        } },
        React__default.createElement("use", { href: starIconId }))))));
};

export { Rating as default };
