import React, { PropsWithChildren, useContext } from 'react'
import { AppDataContext } from '../providers/AppData'
import Header from './Header'
import Footer from './Footer'
import { useRouter } from 'next/router'
import { useWpaTenant } from '../hooks/useWpaTenant'
import WpaHeader from './WpaHeader'
import WpaFooter from './WpaFooter'

function App({ children }: PropsWithChildren<object>) {
  const router = useRouter()
  const { isWPA } = useWpaTenant()
  const { tenantSettings, locale, language } = useContext(AppDataContext)

  return (
    <>
      {isWPA ? <WpaHeader /> : <Header />}
      {children}
      {isWPA ? (
        <WpaFooter
          locale={locale}
          lang={language}
          footerColumnLinks={tenantSettings?.footerColumnLinks}
          footerSocialLinks={tenantSettings?.footerSocialLinks}
          pathname={router.pathname}
        />
      ) : (
        <Footer
          locale={locale}
          lang={language}
          footerColumnLinks={tenantSettings?.footerColumnLinks}
          footerSocialLinks={tenantSettings?.footerSocialLinks}
          pathname={router.pathname}
        />
      )}
    </>
  )
}

export default App
