import React, { useMemo, useContext } from 'react'
import {
  Typography,
  Grid,
  LocationOnIcon,
  Box,
  UnverifiedIcon,
  useTheme,
  MedicalServicesIcon,
  Edit,
  Button,
  Hidden,
  PlayCircleIcon,
  Chip,
  ConnectionsBadgeIcon,
  WhiteTooltip,
  useMediaQuery,
  ShiledManIcon,
  formatNumberWithPlus,
  CheckCircleOutlineIcon
} from '@hermes/web-components'
import { useRouter } from 'next/router'
import { useKeywordsMapper, useTopLevelKeywords } from '../../../hooks/useKeywordsMapper'
import { AppDataContext } from '../../../providers/AppData'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { getYearsDiff } from '../../../utils/date'
import { handleAddCookieCTA } from '../../../utils/cookie'
import {
  getAddressFromPractices,
  transformAddressToShortString,
  transformAddressToString
} from '../../../utils/address'
import {
  CONNECTION_COUNT_TRUST_BADGE_PRACTICE,
  CONNECTION_COUNT_TRUST_BADGE_SPECIALIST,
  doctifyProviderDomain,
  endpoint,
  pathMap
} from '../../../constants/profile'
import { ProfileIntroProps } from '../../../types'
import getLanguageFromLocale from '../../../utils/getLanguageFromLocale'
import {
  TextBox,
  StyledPlayVideoButton,
  StyledTypographyAvatar,
  IntroTextInformation,
  TopKeywordText,
  StyledAvatar,
  StyledAvatarContainer,
  ProfileDetailsContainer
} from './styles'
import getImageWithWWW from '../../../utils/getImageWithWWW'
import { themedColor } from '../styles'
import FeeAgreedBadge from '../../../components/Icons/FeeAgreedBadge'
import FeeAgreedBadgeReversed from '../../../components/Icons/FeeAgreedBadgeReversed'
import { useWpaTenant } from '../../../hooks/useWpaTenant'
import { TEST_IDS } from '../../../__tests__/testUtils/constants'

const claimYourProfile = 'claim-your-profile'

const Badge = ({
  icon,
  title,
  color,
  testId
}: {
  icon: JSX.Element
  title: string
  color?: string
  testId?: string
}) => (
  <TextBox color={color || 'common.white'} height={'32px'}>
    {icon}
    <Typography
      variant="subtitle2"
      color={color || 'system.navy'}
      textTransform="uppercase"
      lineHeight="initial"
      ml={1}
      mr={2}
      my={0.5}
      fontWeight={500}
      display="flex"
      component="p"
      alignItems="center"
      data-testid={testId}
    >
      {title}
    </Typography>
  </TextBox>
)

const FeeAgreedTooltip = ({ children }: { children: React.ReactNode }) => {
  const translate = useTranslateMessage()

  return (
    <WhiteTooltip title={translate('filter.fee-agreed.description')}>
      <Box sx={{ width: 'fit-content' }}>{children}</Box>
    </WhiteTooltip>
  )
}

const PremiumTooltip = ({ children }: { children: React.ReactNode }) => {
  const translate = useTranslateMessage()

  return (
    <WhiteTooltip title={translate('filter.premium.description')}>
      <Box sx={{ width: 'fit-content' }}>{children}</Box>
    </WhiteTooltip>
  )
}

export const getInitialsFromFullName = (fullName: string): string => {
  const splittedName = fullName.split(' ')
  const firstName = splittedName[0]
  const lastName = splittedName[splittedName.length - 1]
  return firstName[0] + lastName[0]
}

// todo: move out if it's grow
const useComponentsConfig = () => {
  const { isWPA } = useWpaTenant()

  return { displayVideoConsultationBadge: !isWPA }
}

// todo: fix complexity
// eslint-disable-next-line complexity
function ProfileIntro({
  id,
  name,
  suffix,
  premium,
  feeAgreed,
  plan,
  slug,
  image,
  keywords,
  yearsOfExperience,
  address,
  isPractice: isClinic,
  practices,
  isUnclaimed = false,
  isEntry = false,
  videoConsultation,
  introVideoAvailable = false,
  setVideoPlayerVisibility = () => null,
  profileAnalytics,
  connectionsCount = 0
}: ProfileIntroProps) {
  const theme = useTheme()
  const { tenantSettings, language, locale, isRtl } = useContext(AppDataContext)
  const translate = useTranslateMessage()
  const router = useRouter()
  const { isWPA } = useWpaTenant()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const imageWithWWW = getImageWithWWW(image)
  const formattedCount = useMemo(
    () => (isClinic ? formatNumberWithPlus(connectionsCount) : connectionsCount),
    [isClinic, connectionsCount]
  )
  const { textColor, iconColor } = themedColor()
  const isEntryOrUnclaimed = useMemo(() => isEntry || isUnclaimed, [isEntry, isUnclaimed])

  const { displayVideoConsultationBadge } = useComponentsConfig()

  const showTrustBadge = useMemo(() => {
    if (isWPA) {
      return false
    }

    if (isClinic) {
      return connectionsCount >= CONNECTION_COUNT_TRUST_BADGE_PRACTICE
    }

    return connectionsCount >= CONNECTION_COUNT_TRUST_BADGE_SPECIALIST
  }, [connectionsCount, isClinic, isWPA])

  const trustBadgeTooltipText = useMemo(
    () =>
      translate(`profile.intro.trustedNetwork.tooltip${isClinic ? '-practice' : ''}`, {
        connectionsCount: String(formattedCount)
      }),
    [formattedCount, isClinic, translate]
  )

  const isMarketingEmail = router.query?.utm_source === 'marketingemail'

  const CTAUrl = isMarketingEmail
    ? `/${locale}${router.asPath.split('?')[0]}/${
        locale === 'de' || locale === 'de-at' ? 'beanspruchen-sie-ihr-profil' : claimYourProfile
      }`
    : doctifyProviderDomain + pathMap[locale] + endpoint

  const initials = getInitialsFromFullName(name)
  const topLevelKeywords = useTopLevelKeywords(keywords || [])
  const { specialties, subSpecialties } = useKeywordsMapper(keywords || [], isClinic)
  const lang = getLanguageFromLocale(locale)
  const userTitle = useMemo(() => {
    let ut = name
    if (suffix) {
      ut = `${suffix} ${ut}`
    }
    return ut
  }, [name, suffix])

  const yearsOfExperienceValue = useMemo(() => getYearsDiff(yearsOfExperience || ''), [yearsOfExperience])

  const transformedAddress = useMemo(() => {
    const addressToTransform = isClinic ? address : getAddressFromPractices(practices)
    if (!addressToTransform) {
      return ''
    }

    return isClinic
      ? transformAddressToString(addressToTransform, language)
      : transformAddressToShortString(addressToTransform, language)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, practices, isClinic])

  const profileVerifiedStatus = useMemo(() => {
    if (isUnclaimed) {
      return translate('unverivied_profile')
    }

    if (isWPA) {
      return isClinic ? translate('verified_practice') : translate('verified_practitioner')
    }

    return translate('verified_profile')
  }, [isClinic, isUnclaimed, isWPA, translate])

  const medicalServicesIcon = <MedicalServicesIcon width="32px" height="32px" />

  const handleOpenVideoPlayer = () => {
    if (introVideoAvailable) {
      if (window) {
        window.dataLayer.push({
          event: 'prf_pg_prf_vid_click',
          cont_prf_name: userTitle,
          ...profileAnalytics
        })
      }
      setVideoPlayerVisibility(true)
    }
  }

  const verifiedIcon = (
    <svg width="32" height="32">
      <use xlinkHref="#verified-circle-icon" />
    </svg>
  )

  const unverifiedIcon = (
    <Box
      borderRadius={5}
      bgcolor="system.coral200"
      p={0.5}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="32px"
    >
      <UnverifiedIcon sx={{ height: 32, width: 32 }} color="inherit" fill="#051851" />
    </Box>
  )

  const renderBadges = () => {
    if (isWPA) {
      return (
        <Hidden smUp implementation="css">
          {!isClinic && !!yearsOfExperienceValue && (
            <Grid container gap={1} flexDirection="row" alignItems="center">
              <ShiledManIcon
                sx={{
                  width: '16px',
                  fill: 'none',
                  path: { stroke: theme.palette.common.white }
                }}
              />
              <IntroTextInformation color={textColor} textTransform="capitalize">
                {translate('years_of_experience', { years: yearsOfExperienceValue })}
              </IntroTextInformation>
            </Grid>
          )}
          <Grid container gap={1} flexDirection="row" alignItems="center">
            <CheckCircleOutlineIcon
              sx={{
                width: '16px',
                path: { color: theme.palette.common.white }
              }}
            />
            <IntroTextInformation color={textColor} textTransform="capitalize">
              {profileVerifiedStatus}
            </IntroTextInformation>
          </Grid>
        </Hidden>
      )
    }

    return (
      <Grid container display="flex" gap={2} flexDirection="row" wrap="nowrap">
        {isUnclaimed ? (
          <Badge icon={unverifiedIcon} title={translate('unverivied_profile')} />
        ) : (
          <Badge icon={verifiedIcon} testId={TEST_IDS.PROFILE_INTRO_VERIFIED} title={translate('verified_profile')} />
        )}
        {!isClinic && !!yearsOfExperienceValue && (
          <Badge
            icon={medicalServicesIcon}
            title={translate('years_of_experience', { years: yearsOfExperienceValue })}
          />
        )}
      </Grid>
    )
  }
  const ProfileDetails = () => (
    <ProfileDetailsContainer container direction="column" sx={{ pb: { xs: '8px', sm: 0 } }}>
      {renderBadges()}
      {showTrustBadge && (
        <Grid container alignItems="center" gap="6px" my="8px">
          <WhiteTooltip
            isRtl={isRtl}
            title={trustBadgeTooltipText}
            componentsProps={{
              tooltip: {
                sx: {
                  ...(isMobile
                    ? {
                        '&:before': {
                          left: '12% !important'
                        },
                        '&:after': {
                          left: '12% !important'
                        }
                      }
                    : {}),
                  height: 'auto !important',
                  padding: '15px 10px !important'
                }
              }
            }}
          >
            <Box>
              <ConnectionsBadgeIcon withCheck={false} count={formattedCount} width="16px" height="16px" />
            </Box>
          </WhiteTooltip>
          <Typography variant="body2" color="text.darkNavy">
            {translate('profile.intro.trustedNetwork')}
          </Typography>
        </Grid>
      )}
      {isClinic && isUnclaimed && !isWPA && (
        <Grid item>
          <Box>
            <Typography color="primary.main" variant="body2">
              {translate('are_you_manager', { name, skipBold: true, defaultMessage: `Are you ${name} manager?` })}
            </Typography>
          </Box>
          <Button
            variant="contained"
            size="small"
            sx={{ mt: 1, px: 1, py: 0.5 }}
            href={CTAUrl}
            onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
          >
            <Typography variant="caption" mr={1} fontWeight={500}>
              {translate(isMarketingEmail ? claimYourProfile : 'manage_your_profile')}
            </Typography>
            <Edit fontSize="inherit" />
          </Button>
        </Grid>
      )}
      {isUnclaimed && !isClinic && !isWPA && (
        <Grid item>
          <Box borderRadius={1} p={{ lg: theme.spacing(0.5, 1) }} bgcolor="common.white" display="inline-block">
            <Typography variant="body2" display="inline" mr={2} lineHeight={2}>
              {translate('are_you', { name }, { bold: { fontWeight: 600 } })}
            </Typography>
            <Button
              variant="contained"
              sx={{ padding: theme.spacing(0.5, 1.5) }}
              href={CTAUrl}
              onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
            >
              <Typography color="common.white" fontWeight={500} variant="caption">
                {translate(isMarketingEmail ? claimYourProfile : 'manage_your_profile')}
              </Typography>
            </Button>
          </Box>
        </Grid>
      )}
      {!isClinic && videoConsultation && displayVideoConsultationBadge && (
        <Grid item>
          <Badge
            icon={
              <svg width="32" height="32">
                <use xlinkHref="#video-cam-icon" />
              </svg>
            }
            title={`${translate('video')} ${translate('consultation')}`}
          />
        </Grid>
      )}
      <Hidden smDown implementation="css">
        {isClinic && premium && isWPA && (
          <PremiumTooltip>
            <svg style={{ width: '124px', height: '35px' }} data-testid={TEST_IDS.PREMIUM_BADGE}>
              <use xlinkHref="#premium-badge-lined-icon" />
            </svg>
          </PremiumTooltip>
        )}
        {!isClinic && feeAgreed && isWPA && (
          <FeeAgreedTooltip>
            <FeeAgreedBadge data-testid={TEST_IDS.FEE_AGREED_BADGE} sx={{ width: '100px', height: '30px' }} />
          </FeeAgreedTooltip>
        )}
      </Hidden>
    </ProfileDetailsContainer>
  )

  return (
    <>
      <Grid container wrap="nowrap" sx={{ pb: { xs: '8px', sm: '32px' } }}>
        <Grid>
          <StyledAvatarContainer item maxHeight="min-content" position="relative" mr={2}>
            <Button
              onClick={handleOpenVideoPlayer}
              variant="ghost"
              sx={{
                padding: 0
              }}
            >
              <StyledAvatar
                width={104}
                height={104}
                alt={userTitle}
                src={!isEntryOrUnclaimed ? imageWithWWW : ''}
                preload
                sx={{
                  backgroundColor: !isEntryOrUnclaimed && image ? 'transparent' : 'blue.200'
                }}
              >
                <StyledTypographyAvatar>{initials}</StyledTypographyAvatar>
              </StyledAvatar>
            </Button>
            {introVideoAvailable && (
              <StyledPlayVideoButton
                aria-label={translate('media_center.introduction_video')}
                variant="ghost"
                onClick={handleOpenVideoPlayer}
                data-testid={TEST_IDS.PROFILE_INTRO_VIDEO_BUTTON}
                sx={theme?.custom?.SearchProfileCard?.profileAvatarContainer ?? {}}
              >
                <PlayCircleIcon
                  sx={{
                    width: '35px',
                    height: '35px'
                  }}
                />
              </StyledPlayVideoButton>
            )}
          </StyledAvatarContainer>
          <Hidden smUp implementation="css">
            {isClinic && premium && isWPA && (
              <PremiumTooltip>
                <svg style={{ width: '124px', height: '35px', ...theme.custom.SearchProfileCard.mobileBadge }}>
                  <use xlinkHref="#premium-badge-lined-icon" />
                </svg>
              </PremiumTooltip>
            )}
            {!isClinic &&
              feeAgreed &&
              isWPA &&
              (isMobile ? (
                <FeeAgreedTooltip>
                  <FeeAgreedBadgeReversed
                    sx={{
                      width: '100px',
                      height: '30px',
                      ...(theme?.custom?.SearchProfileCard?.mobileBadge ?? {})
                    }}
                  />
                </FeeAgreedTooltip>
              ) : (
                <FeeAgreedTooltip>
                  <FeeAgreedBadge
                    sx={{
                      width: '125px',
                      height: '35px',
                      ...(theme?.custom?.SearchProfileCard?.mobileBadge ?? {})
                    }}
                  />
                </FeeAgreedTooltip>
              ))}
          </Hidden>
        </Grid>
        <Grid container xs={7.4} direction="column">
          <Typography
            variant="h2"
            component="h1"
            sx={{ color: 'titleColor.color', ...theme.custom.ProfileIntro.profileTitleTypography }}
            data-testid={TEST_IDS.PROFILE_INTRO_AVATAR}
          >
            {userTitle}
          </Typography>
          <Hidden smDown>
            {isWPA && !isClinic && !!topLevelKeywords ? (
              <TopKeywordText color={textColor}>{topLevelKeywords}</TopKeywordText>
            ) : null}
            <Grid
              container
              mt={1}
              gap={1}
              sx={{
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                  alignItems: 'start',
                  flexDirection: 'column'
                }
              }}
            >
              {!isWPA && !isClinic && !!topLevelKeywords ? (
                <TopKeywordText color={textColor}>{topLevelKeywords}</TopKeywordText>
              ) : null}
              {isClinic && !!subSpecialties?.length && (
                <Grid container alignItems="center" gap="12px" sx={{ pt: { xs: 2 }, pl: { xs: 2.5 } }}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {specialties[0]?.name[lang]}
                  </Typography>
                  <Chip
                    sx={{
                      fontSize: '10.53px',
                      fontWeight: 500,
                      lineHeight: '15.795px',
                      color: 'text.dark',
                      padding: '1px 6px',
                      span: {
                        padding: 0
                      }
                    }}
                    label={`+${subSpecialties.length}`}
                    variant="blue"
                  />
                </Grid>
              )}
              <Grid
                item
                display="flex"
                alignItems="center"
                wrap="nowrap"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...(!isClinic ? { paddingTop: '0 !important' } : {})
                  }
                }}
              >
                <Hidden smDown>
                  <LocationOnIcon
                    sx={{
                      ml: isWPA ? 0 : Number(!isClinic),
                      width: 16,
                      height: 16,
                      marginRight: '4px',
                      path: { fill: iconColor }
                    }}
                  />
                </Hidden>
                <Hidden smUp>
                  {isClinic && (
                    <LocationOnIcon
                      sx={{
                        width: 16,
                        height: 16,
                        marginRight: '4px',
                        path: { fill: iconColor }
                      }}
                    />
                  )}
                </Hidden>
                <IntroTextInformation
                  color={textColor}
                  data-testid={TEST_IDS.PROFILE_INTRO_LOCATION}
                  textTransform="capitalize"
                >
                  {transformedAddress || tenantSettings?.defaultLocations?.[0]?.country}
                </IntroTextInformation>
              </Grid>
              {isWPA && (
                <Hidden smDown>
                  {!isClinic && !!yearsOfExperienceValue && (
                    <Grid item gap={1} display="flex" alignItems="center">
                      <ShiledManIcon
                        sx={{
                          width: 16,
                          height: 16,
                          fill: 'none',
                          path: { stroke: theme.palette.common.white }
                        }}
                      />
                      <IntroTextInformation color={textColor} textTransform="capitalize">
                        {translate('years_of_experience', { years: yearsOfExperienceValue })}
                      </IntroTextInformation>
                    </Grid>
                  )}
                  <Grid item gap={1} display="flex" alignItems="center">
                    <CheckCircleOutlineIcon
                      sx={{
                        width: 16,
                        height: 16,
                        path: { color: theme.palette.common.white }
                      }}
                    />
                    <IntroTextInformation color={textColor} textTransform="capitalize">
                      {profileVerifiedStatus}
                    </IntroTextInformation>
                  </Grid>
                </Hidden>
              )}
            </Grid>
            <ProfileDetails />
          </Hidden>
          <Hidden smUp implementation="css">
            <Grid
              container
              mt={1}
              sx={{
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                  alignItems: 'start',
                  flexDirection: 'column'
                }
              }}
            >
              {!isClinic && !!topLevelKeywords && <TopKeywordText color={textColor}>{topLevelKeywords}</TopKeywordText>}
              {isClinic && !!subSpecialties?.length && (
                <Grid container alignItems="center" gap="12px" sx={{ pt: { xs: 2 }, pl: { xs: 2.5 } }}>
                  <Typography variant="subtitle1" fontWeight={500}>
                    {specialties[0]?.name[lang]}
                  </Typography>
                  <Chip
                    sx={{
                      fontSize: '10.53px',
                      fontWeight: 500,
                      lineHeight: '15.795px',
                      color: 'text.dark',
                      padding: '1px 6px',
                      span: {
                        padding: 0
                      }
                    }}
                    label={`+${subSpecialties.length}`}
                    variant="blue"
                  />
                </Grid>
              )}
              <Grid
                container
                alignItems="center"
                flexWrap="nowrap"
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    ...(!isClinic ? { paddingTop: '0 !important' } : {})
                  }
                }}
              >
                <LocationOnIcon
                  sx={{
                    width: 16,
                    height: 16,
                    marginRight: '4px',
                    path: { fill: iconColor }
                  }}
                />
                <IntroTextInformation color={textColor} textTransform="capitalize">
                  {transformedAddress || tenantSettings?.defaultLocations?.[0]?.country}
                </IntroTextInformation>
              </Grid>
            </Grid>
            {isWPA && <ProfileDetails />}
          </Hidden>
        </Grid>
      </Grid>
      <Hidden implementation="css" smUp>
        {!isWPA && <ProfileDetails />}
      </Hidden>
    </>
  )
}

export default ProfileIntro
