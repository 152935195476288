import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '24px',
});
const ContentWrapper = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '32px',
    textAlign: 'center',
});
const Title = styledComponent('h1')(({ theme }) => ({
    color: theme.palette.system.navy900,
    textAlign: 'center',
    fontFamily: `'Klarheit Kurrent', sans-serif`,
    fontSize: '30px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-1px',
    margin: 0,
}));
const LocationSection = styledComponent('div')(({ theme }) => ({
    color: theme.palette.system.navy900,
    textAlign: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    '&>b': {
        fontWeight: 600,
    },
}));
const SummarySection = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    color: theme.palette.system.navy900,
    textAlign: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '20px',
}));
const Link = styledComponent('a')(({ theme }) => ({
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    textDecorationLine: 'underline',
}));

export { Container, ContentWrapper, Link, LocationSection, SummarySection, Title };
