import React, { useContext, useMemo } from 'react'
import { Typography, LocationOnIcon, Grid, Paper, Link, theme, Rating } from '@hermes/web-components'
import { getAbbreviation } from '../../../../utils/getAbbreviation'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { getAddressFromPractices, transformAddressToString } from '../../../../utils/address'
import Avatar from '../../../Avatar'

import { AppDataContext } from '../../../../providers/AppData'
import { fixSlug } from '../../../../utils/checkProfileSlugRedirect'
import { SpecialistProfile } from '../../../../types/specialist'

function SpecialistCard({ specialist }: { specialist: SpecialistProfile }) {
  const translate = useTranslateMessage()
  const { language, tenantSettings } = useContext(AppDataContext)

  const { specialistFullName, addressToTransform } = useMemo(
    () => ({
      specialistFullName: `${specialist?.title?.[language] ?? ''} ${specialist.firstName[language]} ${
        specialist.lastName[language]
      }`.trim(),
      addressToTransform: getAddressFromPractices(specialist.practices)
    }),
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [specialist]
  )
  const { topLevelKeyword } = useMemo(() => {
    const topKeyword = specialist?.keywords?.filter((k) => k.isTopLevelChild) || []
    return {
      topLevelKeyword: topKeyword.filter((keyword) => keyword.keywordType === 'specialty')
    }
  }, [specialist.keywords])

  const nameAbbreviation = getAbbreviation({ specialist, language })

  return (
    <Paper
      sx={{
        border: 1,
        borderColor: theme.palette.background.default,
        p: 2,
        height: '100%',
        boxShadow:
          '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04)',
        borderRadius: 2
      }}
    >
      <Link href={fixSlug(specialist.slug)} underline="none">
        <Grid
          container
          gap="16px"
          direction="row"
          justifyContent="space-between"
          borderRadius="8px"
          bgcolor="white"
          height="100%"
        >
          <Grid container alignItems="flex-start" item columnSpacing={2}>
            <Grid item>
              <Avatar
                alt={specialistFullName}
                width={64}
                height={64}
                src={specialist?.images?.logo}
                withDoctifyCDNLoader
              >
                <Typography variant="body2" fontSize={24} textTransform="uppercase">
                  {nameAbbreviation}
                </Typography>
              </Avatar>
            </Grid>
            <Grid container item xs direction="row" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4" component="h4" color="text.primary" fontWeight={600} gutterBottom p={0}>
                  {specialistFullName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" color="text.disabled">
                  {topLevelKeyword?.[0]?.practitioner?.[language] || ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={1} alignItems="center">
            <Grid item xs="auto">
              <LocationOnIcon />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="text.disabled">
                {addressToTransform
                  ? transformAddressToString(addressToTransform, language)
                  : tenantSettings?.defaultLocations[0].name}
              </Typography>
            </Grid>
          </Grid>
          <Grid alignSelf="flex-end" item xs={12}>
            <Paper
              sx={{
                px: 2,
                py: 1.5,
                boxShadow: 'none',
                backgroundColor: 'background.default'
              }}
            >
              <Grid container spacing={2}>
                <Grid item display="flex" alignItems="center">
                  <Typography color="primary.dark" variant="h2" fontWeight={900}>
                    {specialist.review?.averageRating?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid container item xs>
                  <Grid item xs={12}>
                    <Rating value={specialist.review?.averageRating} size="small" readOnly />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {`(${translate('reviews.total_patient_reviews', {
                        total: specialist.reviewsTotal
                      })})`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Link>
    </Paper>
  )
}

export default SpecialistCard
