import { DATE_LOCALE } from '../../constants.esm.js';

const getDaysInMonth = (year, month) => new Date(year, month + 1, 0).getDate();
const getStartDayOfMonth = (date) => {
    const startDay = new Date(date.getFullYear(), date.getMonth(), 1).getDay();
    return (startDay + 6) % 7;
};
const getToday = () => new Date();
const formatDate = (date) => date.toISOString().split('T')[0];
const formatTime = (date) => {
    var _a, _b, _c, _d;
    return (_d = (_c = (_b = (_a = date
        .toLocaleDateString(DATE_LOCALE, {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    })).split) === null || _b === void 0 ? void 0 : _b.call(_a, ', ')) === null || _c === void 0 ? void 0 : _c[1]) === null || _d === void 0 ? void 0 : _d.replace(' ', '');
};
const getWithTimezoneOffset = (date) => {
    const userTimezoneOffset = -date.getTimezoneOffset() * 60 * 1000;
    return new Date(date.getTime() + userTimezoneOffset);
};
const formatDateStringToDisplay = (dateString) => dateString
    ? getWithTimezoneOffset(new Date(dateString)).toLocaleDateString(DATE_LOCALE, {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
    })
    : undefined;
const formatTimeStringToDisplay = (timeString) => timeString ? formatTime(new Date(`1875-05-20 ${timeString}`)) : undefined;
const sortDatesFn = (a, b) => a.getTime() - b.getTime();
const generateDaysForMonth = (year, month) => {
    const daysInThisMonth = getDaysInMonth(year, month);
    const firstDayIndex = getStartDayOfMonth(new Date(year, month));
    const today = getToday();
    const currentMonthDays = [];
    for (let i = 1; i <= daysInThisMonth; i++) {
        const date = new Date(year, month, i);
        currentMonthDays.push({
            date: getWithTimezoneOffset(date),
            isCurrentMonth: true,
            isToday: today.getDate() === i &&
                today.getMonth() === month &&
                today.getFullYear() === year,
        });
    }
    const previousMonthDays = Array.from({ length: firstDayIndex }, (_, i) => ({
        date: new Date(year, month, i - firstDayIndex + 1),
        isCurrentMonth: false,
        isToday: false,
    }));
    return [...previousMonthDays, ...currentMonthDays];
};

export { formatDate, formatDateStringToDisplay, formatTime, formatTimeStringToDisplay, generateDaysForMonth, getWithTimezoneOffset, sortDatesFn };
