import { useMemo, useContext } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { Typography, styled, Box } from '@hermes/web-components'
import { useVisibilityComponents } from '../../../hooks/useVisibilityComponents'
import FaqItem from '../FaqItem'
import { AppDataContext } from '../../../providers/AppData'
import { getAddressFromPractices, transformAddressToString } from '../../../utils/address'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import useSpecialistWorkingHours, { dayOfWeekMapping } from '../../../hooks/useSpecialistWorkingHours'
import { capitalise } from '../../../utils/strings'

import { SpecialistProfile } from '../../../types/specialist'

const StyledLink = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  textTransform: 'lowercase',
  '&:hover': {
    cursor: 'pointer'
  }
}))

const SpecialistFAQ = ({ specialist, onGetInTouch }: { specialist: SpecialistProfile; onGetInTouch?: () => void }) => {
  const router = useRouter()
  const [pathName] = router.asPath.split('#')
  const { language, locale } = useContext(AppDataContext)
  const isGermany = locale.includes('de')

  const translate = useTranslateMessage()
  const { fullName, address, mainPracticeName } = useMemo(() => {
    const { title, firstName, lastName, practices } = specialist
    const addressToTransform = getAddressFromPractices(practices)
    return {
      fullName: [title?.[language], firstName?.[language], lastName?.[language]].filter((n) => !!n).join(' '),
      address: addressToTransform ? transformAddressToString(addressToTransform, language) : '',
      mainPracticeName: practices?.[0]?.name?.[language]
    }
  }, [specialist, language])

  const { workingHours } = useSpecialistWorkingHours(specialist.practices)

  const workingHoursString = useMemo(
    () =>
      Object.entries(dayOfWeekMapping)
        .map(([key, value]) => {
          const workingTimes = workingHours?.all?.[value]
          if (!workingTimes?.length) {
            return ''
          }

          // todo: refactor
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          return `${translate(key)} (${workingTimes.map(({ open, close }) => `${open} - ${close}`).join(', ')})`
        })
        .filter((workingTime) => !!workingTime)
        .join(', '),
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [workingHours.all]
  )
  const languagesCount = specialist.languages.length

  const visibilityComponents = useVisibilityComponents({ specialist })

  const onClickGetInTouch = () => {
    if (onGetInTouch) {
      onGetInTouch()
    }
  }

  return (
    <Box>
      <Typography
        variant="h2"
        component="h2"
        lineHeight="40px"
        color="text.darkNavy"
        textTransform="uppercase"
        sx={{ mb: 2 }}
      >
        {translate('faq')}
      </Typography>
      {visibilityComponents.faqQuestions.faqQuestionReviews && (
        <FaqItem
          title={translate('profile.question_reviews', { name: fullName })}
          mainDescription={
            <>
              {translate('profile.answer_reviews', {
                name: fullName,
                rating: specialist.averageRating
              })}{' '}
              {translate('profile.based_on')}{' '}
              <Link href={`/${router.locale}${pathName}#reviews`} passHref>
                <StyledLink style={{ textTransform: 'capitalize' }}>
                  {translate('reviews.count', { reviews: specialist.reviewsTotal, skipBold: true })}.
                </StyledLink>
              </Link>
            </>
          }
        />
      )}
      {visibilityComponents.faqQuestions.faqQuestionLanguages && (
        <FaqItem
          title={translate('profile.question_languages', { name: fullName })}
          mainDescription={translate(
            languagesCount > 1 ? 'profile.answer_languages_multiple' : 'profile.answer_languages',
            {
              name: fullName,
              language: specialist.languages
                .slice(0, languagesCount - (languagesCount > 1 ? 1 : 0))
                .map((lang) => capitalise(lang.name))
                .join(', '),
              lastLanguage: capitalise(specialist.languages[languagesCount - 1]?.name)
            }
          )}
        />
      )}
      {visibilityComponents.faqQuestions.faqQuestionWhereLocated && (
        <FaqItem
          title={translate('profile.question_where_located', {
            name: fullName
          })}
          mainDescription={
            <>
              {translate('profile.specialist_located_at', {
                name: fullName,
                practice: mainPracticeName,
                address
              })}
              <br />
              {translate(isGermany ? 'mehr' : 'see_more')}{' '}
              <Link href={`/${router.locale}${pathName}#locations`} passHref>
                <StyledLink style={{ textTransform: 'capitalize' }}>{translate('profile.more_locations')}</StyledLink>
              </Link>{' '}
              {isGermany && <>{translate('sehen')}.</>}
            </>
          }
        />
      )}
      {visibilityComponents.faqQuestions.faqQuestionsLocation && (
        <FaqItem
          title={translate('profile.questions_location', {
            name: fullName
          })}
          mainDescription={translate('profile.answer_location', {
            name: fullName,
            hours: workingHoursString
          })}
          additionalDescription={
            <>
              {translate(`profile.find_operates${isGermany ? '.de' : ''}`, {
                name: fullName
              })}{' '}
              <Link href={`/${router.locale}${pathName}#locations`} passHref>
                <StyledLink>{translate('today')}</StyledLink>
              </Link>{' '}
              {isGermany && <>{translate('arbeitet')}.</>}
            </>
          }
        />
      )}
      {visibilityComponents.faqQuestions.faqQuestionAcceptVisits && (
        <FaqItem
          title={translate('profile.question_accept_visits', { name: fullName })}
          mainDescription={translate('profile.answer_accept_visits', { name: fullName })}
          additionalDescription={
            <>
              <StyledLink sx={{ textTransform: 'capitalize' }} onClick={onClickGetInTouch}>
                {translate('get_in_touch')}
              </StyledLink>{' '}
              {translate('profile.short_enquire_as_a_new_patient_specialist')}
            </>
          }
        />
      )}
    </Box>
  )
}

export default SpecialistFAQ
