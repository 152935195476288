import styledComponent from '../../node_modules/@mui/material/styles/styled.esm.js';
import Box from '../../node_modules/@mui/material/Box/Box.esm.js';
import Button from '../../node_modules/@mui/material/Button/Button.esm.js';

styledComponent(Box)({
    position: 'relative'
});
const NavigationContainer = styledComponent(Box, {
    shouldForwardProp: (prop) => prop !== 'bottom' && prop !== 'laterally' && prop !== 'inside' && prop !== 'navigationPosition'
})(({ navigationPosition, theme }) => (Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, (navigationPosition === 'bottom' && {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px'
})), { zIndex: 3 }), (navigationPosition === 'laterally' && {
    button: {
        position: 'absolute',
        zIndex: 500,
        top: 'calc(50% - 20px)',
        '&:first-of-type': {
            left: '-25px'
        },
        '&:last-of-type': {
            right: '-25px'
        }
    }
})), (navigationPosition === 'inside' && {
    position: 'absolute',
    display: 'flex',
    marginTop: '40px',
    width: '30%',
    justifyContent: 'center',
    gap: '10px',
    bottom: '50px',
    zIndex: 1
})), { [theme.breakpoints.down('sm')]: Object.assign({}, (navigationPosition === 'laterally' && {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        gap: '25px',
        marginTop: '35px',
        button: {
            left: '0 !important',
            right: '0 !important',
            position: 'relative'
        }
    })) })));
const NavButton = styledComponent(Button)(({ theme }) => ({
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    padding: 0,
    minWidth: 'auto',
    border: `0.2px solid ${theme.palette.common.white}`,
    backgroundColor: theme.palette.background.paper,
    transition: '0.2s',
    '&.Mui-disabled': {
        pointerEvents: 'auto !important',
        'svg > path': {
            fill: '#D9D9D9'
        },
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            'svg > path': {
                fill: '#D9D9D9'
            }
        }
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        'svg > path': {
            fill: theme.palette.background.default
        }
    },
    [theme.breakpoints.down('sm')]: {
        width: '40px',
        height: '40px'
    }
}));
styledComponent(Box)({
    display: 'grid',
    gap: '45px',
    width: 'inherit',
    minHeight: 'max-content',
    '&>div': {
        width: 'inherit'
    }
});

export { NavButton, NavigationContainer };
