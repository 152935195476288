import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const ArrowRight = (_a) => {
    var { color } = _a, props = __rest(_a, ["color"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "16", height: "16", viewBox: "0 0 16 16" }, props),
        React__default.createElement("path", { d: "M8 2L6.9425 3.0575L11.1275 7.25H2V8.75H11.1275L6.9425 12.9425L8 14L14 8L8 2Z", fill: color || '#2B59E0' })));
};

export { ArrowRight as default };
