import React, { useEffect } from 'react'

interface ErrorProps {
  statusCode: string | number
}

const errorDescriptionMap: Record<string, string> = {
  '404': 'This page could not be found.',
  '500': 'Internal server error.',
  default: 'This page could not be found.'
}

// Mimic default Next error page
const Error = ({ statusCode }: ErrorProps) => {
  useEffect(() => {
    document.body.style.backgroundColor = '#000'
  }, [])

  return (
    <div
      style={{
        fontFamily:
          '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif',
        height: '100vh',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
        <div style={{ borderRight: '1px solid rgba(255, 255, 255, .3)' }}>
          <h1
            style={{
              display: 'inline-block',
              margin: '0',
              padding: '10px 23px 10px 0',
              fontSize: '24px',
              fontWeight: '500',
              verticalAlign: 'top'
            }}
          >
            {statusCode}
          </h1>
        </div>
        <h2
          style={{ fontSize: '14px', fontWeight: 'normal', lineHeight: 'inherit', padding: '0 0 6px 0', margin: '0' }}
        >
          {errorDescriptionMap[statusCode] ?? errorDescriptionMap['default']}
        </h2>
      </div>
    </div>
  )
}

export default Error
