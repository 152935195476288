import { KeyTypes } from '../constants/search'
import { PracticeProfile, PracticeContact } from '../types/practice'
import { SpecialistPractice } from '../types/specialist'

export const getPracticeContacts = ({
  practiceProfile,
  specialistPractice,
  shouldUsePracticeContacts
}: {
  practiceProfile?: PracticeProfile
  specialistPractice?: SpecialistPractice
  shouldUsePracticeContacts?: boolean
}): PracticeContact | undefined => {
  if (practiceProfile) {
    const { phone, email, externalBookingLink } = practiceProfile
    return { phone, email, externalBookingLink }
  }
  if (specialistPractice) {
    const { phone, email } = (specialistPractice.ContactDetails || []).reduce(
      (prev, { phone: p, email: e }) => ({ phone: p || prev.phone, email: e || prev.email }),
      { phone: '', email: '' }
    )
    const { externalBookingLink, contactDetailsPractice } = specialistPractice
    return {
      phone: shouldUsePracticeContacts ? contactDetailsPractice?.phone : phone,
      email: shouldUsePracticeContacts ? contactDetailsPractice?.email : email,
      externalBookingLink
    }
  }
}

const mapContact = (key: keyof PracticeContact, specialistPractices: SpecialistPractice[]) =>
  specialistPractices.reduce((prev, curr) => {
    const contact = getPracticeContacts({ specialistPractice: curr })
    if (!contact?.[key]?.length) {
      return prev
    }
    return [...prev, { link: `${KeyTypes[key]}${contact[key]}`, practice: curr }]
  }, [] as { link: string; practice: SpecialistPractice }[])

export const mapSpecialistPracticesContacts = (specialistPractices: SpecialistPractice[]) => ({
  practicesWithEmails: mapContact('email', specialistPractices),
  practicesWithPhones: mapContact('phone', specialistPractices),
  practicesWithExternalBookingLinks: mapContact('externalBookingLink', specialistPractices)
})
