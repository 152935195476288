import { styled, styledComponent, Menu, Box, useTheme, Grid, Link, Divider } from '@hermes/web-components'
import { Theme } from '@mui/material'

interface PublicationWrapperProps {
  theme?: Theme
  isFirst?: boolean
  isLast?: boolean
}

const textDarkNavy = 'text.darkNavy'

// eslint-disable-next-line complexity
export const themedColor = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const theme = useTheme()
  return {
    textColor: theme.custom?.SpecialistPage?.common?.color ?? textDarkNavy,
    buttonBackground: theme.custom?.SpecialistPage?.common?.background ?? '#2B59E0',
    iconColor: theme.custom?.SpecialistPage?.common?.color ?? theme.palette.text.darkNavy,
    locationTitleColor: theme.custom?.SpecialistPage?.common?.background ?? 'common.white',
    borderRadius: theme.custom?.SpecialistPage?.location?.containerBorderRadius ?? '16px',
    chipsTextColor: theme.custom?.SpecialistPage?.review?.chipsTextColor,
    chipsBorder: theme.custom?.SpecialistPage?.common?.border ?? '1px #2B59E0 solid',
    chipsBorderSelected: theme.custom?.SpecialistPage?.common?.borderSelected ?? '1px #2B59E0 solid',
    showMoreChipsBackground: theme.custom?.SpecialistPage?.review?.showMoreChips ?? 'rgba(255, 99, 92, 0.2)',
    checkboxColor: theme.custom?.SpecialistPage?.common?.background ?? '#2B59E0',
    specialistLocationSectionBackground: theme.custom?.SpecialistPage?.location?.background ?? 'rgba(17, 41, 113, 0.8)',
    practiceLocationSectionBackground:
      theme.custom?.SpecialistPage?.location?.background ?? 'linear-gradient(0deg, #051851, #051851), #2C3341',
    endorsementsBackground: theme.custom?.SpecialistPage?.endorsements?.background ?? 'background.light2',
    endorsementsChipsColor: theme.custom?.SpecialistPage?.endorsements?.chipsColor ?? 'rgba(0, 229, 208, 0.2)',
    endorsementsChipsBackground: theme.custom?.SpecialistPage?.endorsements?.chipsBackground,
    endorsementsChipsBorder: theme.custom?.SpecialistPage?.endorsements?.chipsBorder,
    endorsementsChipsSelectedBackground: theme.custom?.SpecialistPage?.endorsements?.chipsSelectedBackground,
    endorsementsChipsSelectedBorder: theme.custom?.SpecialistPage?.endorsements?.chipsSelectedBorder,
    endorsementsChipsTextColor: theme.custom?.SpecialistPage?.endorsements?.chipsTextColor,
    buttonBorder: theme.custom?.SpecialistPage?.common?.border ?? '',
    buttonBorderRadius: theme.custom?.SpecialistPage?.common?.buttonBorderRadius ?? '4px',
    titleTextTransform: theme.custom?.SpecialistPage?.common?.titleTextTransform ?? 'uppercase',
    reviewTitleColor: theme.custom?.SpecialistPage?.common?.titleColor ?? textDarkNavy,
    linearProgressUnselected: theme.custom?.SpecialistPage?.common?.background ?? '#00E5D0',
    aboutSectionTitleColor: theme.custom?.SpecialistPage?.about?.titleColor,
    aboutSectionTitleFontWeight: theme.custom?.SpecialistPage?.about?.fontWeight ?? '600',
    progressIndicatorColor: theme.custom?.SpecialistPage?.common?.progressIndicatorColor ?? theme.palette.system.navy,
    reviewUnselectedTextColor: theme.custom?.SpecialistPage?.common?.color ?? 'common.white',
    chipBackground: theme.custom?.SpecialistPage?.common?.chipBackground,
    chipBorderColor: theme.custom?.SpecialistPage?.common?.chipBorderColor,
    reviewsChipBorder: theme.custom?.SpecialistPage?.review?.chipsBorder
  }
}

export const StyledButton = styled('button', {
  shouldForwardProp: (prop: string) => prop !== 'open'
})<{ theme?: Theme; open: boolean }>(({ theme, open }) => ({
  ...theme.typography.body2,
  background: 'none',
  border: '1px solid transparent',
  fontWeight: '500',
  borderRadius: '16px',
  display: 'flex',
  paddingLeft: '8px',
  paddingRight: '8px',
  height: '24px',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#112971',
  '&:hover': {
    border: `1px ${theme.custom?.SpecialistPage?.common?.background ?? 'rgba(255, 99, 92, 0.8)'} solid`
  },
  fontSize: '11.85px !important',
  '& svg': {
    transform: `rotate(${open ? '180deg' : '0deg'})`
  }
}))

export const StyledMenu = styledComponent(Menu)(({ theme }) => ({
  '& .MuiMenu-paper ': {
    padding: theme.spacing(2),
    boxShadow: '0px 3px 4px rgba(2, 3, 3, 0.03), 0px 3px 3px rgba(2, 3, 3, 0.02), 0px 1px 8px rgba(2, 3, 3, 0.04)',
    borderRadius: 2,
    border: `1px solid ${theme.palette.grey[600]}`
  },
  '& .MuiList-root ': {
    padding: theme.spacing(0),
    maxHeight: 300,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 8,
      borderRadius: 8,
      background: '#D5DEF9'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: 8
    }
  },
  '& .MuiButtonBase-root ': {
    minHeight: '24px'
  },
  '& .MuiMenuItem-root': {
    padding: theme.spacing(0),
    '&:hover': {
      backgroundColor: 'initial'
    }
  }
}))

export const ItemWrapper = styledComponent(Box, {
  shouldForwardProp: (prop: string) => prop !== 'spacing'
})<{ spacing: 'small' | 'normal' }>(({ theme, spacing }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '100%',
  margin: spacing === 'small' ? theme.spacing(0.5) : theme.spacing(1, 2, 0, 0),
  '&:last-child': {
    marginRight: 0
  }
}))

export const DayAvailabilityRoot = styledComponent(Grid)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  '&:last-child': {
    paddingBottom: 0
  }
}))

export const Wrapper = styledComponent(Grid)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column-reverse'
  }
}))

export const List = styled('ul')<{ theme?: Theme }>(({ theme }) => ({
  ...theme.typography.body1,
  paddingLeft: 0,
  color: theme.palette.text.black900,
  textTransform: 'capitalize'
}))

export const StyledSectionHeaderWrapper = styledComponent(Grid)(({ theme }) => ({
  alignItems: 'center',
  gap: '8px',
  width: 'auto',
  flexWrap: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start'
  },
  b: {
    fontWeight: 600
  }
}))

export const StyledSpecialtiesWrapper = styledComponent(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: '48px'
  }
}))

export const PublicationWrapper = styledComponent(Link)<PublicationWrapperProps>(({ theme, isFirst, isLast }) => ({
  padding: '16px 0',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderBottomColor: isLast ? 'transparent' : theme.palette.grey[600],
  borderTopColor: isFirst ? theme.palette.grey[600] : 'transparent',
  marginBottom: '-1px',
  contentVisibility: 'auto',
  containIntrinsicHeight: '500px',
  '&:hover': {
    backgroundColor: theme.palette.background.default,
    margin: '0 -8px -1px -8px',
    padding: '16px 8px',
    borderColor: 'transparent',
    borderRadius: '8px'
  }
}))

export const StyledDivider = styledComponent(Divider)(() => ({
  borderColor: '#D5DEF9',
  marginTop: 48,
  marginBottom: 48
}))
