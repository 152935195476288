import React, { useContext, useState } from 'react'
import { styled, Avatar, Image, SxProps, Theme, Typography } from '@hermes/web-components'
import useTranslateMessage from '../hooks/useTranslateMessage'
import { SearchReviewsContext } from '../providers/SearchReviews'

const List = styled('ul', {
  shouldForwardProp: (prop: string) => !['columns', 'noPadding', 'largeFont', 'darkText'].includes(prop)
})<{ theme?: Theme; columns: number; noPadding: boolean; largeFont?: boolean; darkText?: boolean }>(
  ({ theme, columns, noPadding, darkText = false }) => {
    const columnSize = 12 / columns
    const columnsTemplate = Array(columns)
      .fill(null)
      .map(() => `${columnSize}fr`)
      .join(' ')
    return {
      ...theme.typography.body1,
      paddingLeft: noPadding ? 0 : theme.spacing(2),
      color: darkText ? theme.palette.text.primary : theme.palette.grey[800],
      textTransform: 'capitalize',
      display: 'block',
      columnCount: columns,
      gridTemplateColumns: columnsTemplate,
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '12fr',
        columnCount: 1
      }
    }
  }
)

export const ToggleButton = styled('button', {
  shouldForwardProp: (prop: string) => !['noSpacing'].includes(prop)
})<{ noSpacing?: boolean; theme?: Theme }>(({ theme, noSpacing }) => ({
  position: 'relative',
  border: 'none',
  textDecoration: 'underline',
  fontSize: '14px',
  background: 'none',
  cursor: 'pointer',
  color: theme.palette.text.black900,
  marginTop: theme.spacing(1),
  width: 'max-content',
  height: 'fit-content',
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.body1.fontSize
  },
  ...(noSpacing && {
    padding: 0
  })
}))

const StyledLi = styled('li', {
  shouldForwardProp: (prop: string) =>
    !['color', 'largeSpacing', 'withImage', 'isExpandButton', 'itemGutterBottom', 'isActive'].includes(prop)
})<{
  color?: string
  withImage?: boolean
  largeSpacing?: boolean
  isExpandButton?: boolean
  itemGutterBottom?: boolean
  isActive?: boolean
}>(({ color, theme, withImage, largeSpacing, isExpandButton, itemGutterBottom, isActive }) => ({
  listStyle: color || withImage ? 'none' : 'initial',
  // TODO: broke dots
  display: withImage || color ? 'flex' : 'list-item',
  margin: largeSpacing ? theme.spacing(0.5) : 0,
  alignItems: color ? 'baseline' : undefined,
  color: theme.palette.text.black900,
  fontWeight: isActive ? 600 : 400,
  lineHeight: '19.6px',
  '&:before': color
    ? {
        content: '""',
        display: 'inline-block',
        minWidth: 16,
        height: 8,
        backgroundColor: color,
        marginRight: theme.spacing(2)
      }
    : {},
  ...(isExpandButton && {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  }),
  ...(itemGutterBottom && {
    marginBottom: '6px'
  })
}))

type ExpandableListProps = {
  items: any[]
  locale?: string
  valueKey: string
  imageKey?: string
  columns?: number
  limit?: number
  displayAll?: boolean
  largeSpacing?: boolean
  largeFont?: boolean
  darkText?: boolean
  noSpacing?: boolean
  hideBullets?: boolean
  itemGutterBottom?: boolean
  sx?: SxProps
  mt?: string
  mb?: string
  subtitle?: string
}

// todo: fix complexity
// eslint-disable-next-line complexity
const ExpandableList = ({
  items,
  valueKey,
  imageKey,
  locale,
  sx = {},
  columns = 1,
  limit = 5,
  displayAll = false,
  largeSpacing = false,
  largeFont = false,
  darkText = false,
  noSpacing = false,
  hideBullets = false,
  itemGutterBottom = false,
  mt,
  mb,
  subtitle
}: ExpandableListProps) => {
  const {
    state: { filters }
  } = useContext(SearchReviewsContext)

  const translate = useTranslateMessage()
  const [open, setOpen] = useState(false)

  const toggleOpen = () => setOpen((prev) => !prev)

  const renderItem = (item: any, index: number) => {
    const value = locale ? item[valueKey][locale] : item[valueKey]
    const image = imageKey ? item[imageKey] : null
    const { isExpandButton } = item

    return (
      <StyledLi
        itemGutterBottom={itemGutterBottom}
        color={item.color}
        key={`list-item-${value}-${item.color}-${index}`}
        withImage={!!image}
        isActive={filters.keyword === item.keyName}
        largeSpacing={largeSpacing}
        isExpandButton={isExpandButton}
        onClick={isExpandButton ? toggleOpen : () => {}}
      >
        {!!image && displayAll && (
          <Avatar sx={{ width: 24, height: 24, mr: 2, background: 'none', '& img': { objectFit: 'contain' } }}>
            <Image lazy width="24px" height="24px" src={image} alt={`${value} Logo`} />
          </Avatar>
        )}
        {value}
      </StyledLi>
    )
  }

  if (!items?.length) {
    return null
  }

  const expandItems = items.filter((el) => el.isExpandButton)
  const filteredItems = items
    .filter((el) => !el.isExpandButton)
    .sort((a, b) => {
      if (filters.keyword === a.keyName) {
        return -1
      }
      if (filters.keyword !== b.keyName) {
        return 1
      }
      return 0
    })
  const itemsSize = filteredItems.length
  const visibleItems = displayAll ? filteredItems : filteredItems.slice(0, limit).filter((el) => !el.isExpandButton)

  return (
    <>
      <List
        sx={{
          ...(hideBullets
            ? {
                padding: 0,
                margin: 0,
                li: {
                  listStyleType: 'none',
                  margin: hideBullets ? '4px 0' : '4px'
                }
              }
            : {}),
          marginTop: mt,
          marginBottom: mb,
          ...sx
        }}
        columns={columns}
        noPadding={!!imageKey || noSpacing}
        largeFont={largeFont}
        darkText={darkText}
      >
        {subtitle && (
          <Typography variant="body1" fontWeight={600} color="text.black900" mb={1}>
            {subtitle}
          </Typography>
        )}
        {visibleItems.map(renderItem)}
        {itemsSize > limit && !!open && filteredItems.slice(limit).map(renderItem)}
        {!!expandItems.length && !open && expandItems.map(renderItem)}
        {columns <= 1 && !expandItems.length && !displayAll && itemsSize > limit && (
          <li style={{ listStyleType: 'none' }}>
            <ToggleButton onClick={toggleOpen} noSpacing={!!imageKey || hideBullets}>
              {open ? translate('show_less') : translate('show_more')}
            </ToggleButton>
          </li>
        )}
      </List>
      {columns > 1 && !expandItems.length && !displayAll && itemsSize > limit && (
        <ToggleButton onClick={toggleOpen} noSpacing={!!imageKey || hideBullets}>
          {open ? translate('show_less') : translate('show_more')}
        </ToggleButton>
      )}
    </>
  )
}

export default ExpandableList
