import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const DumbbellIcon = (_a) => {
    var props = __rest(_a, []);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React__default.createElement("rect", { x: "8.04297", y: "17.5235", width: "2.1208", height: "9.35502", rx: "1.0604", transform: "rotate(180 8.04297 17.5235)", stroke: "#051851", fill: "none" }),
        React__default.createElement("rect", { x: "15.959", y: "8.16846", width: "2.1208", height: "9.35502", rx: "1.0604", stroke: "#051851", fill: "none" }),
        React__default.createElement("path", { d: "M5.80469 15.946L5.49029 15.946C4.66187 15.946 3.99029 15.2744 3.99029 14.446L3.99029 11.246C3.99029 10.4176 4.66187 9.74601 5.49029 9.74601L5.80469 9.74601L5.80469 15.946Z", stroke: "#051851", fill: "none" }),
        React__default.createElement("path", { d: "M18.1973 9.74597L18.5117 9.74597C19.3401 9.74597 20.0117 10.4175 20.0117 11.246L20.0117 14.4459C20.0117 15.2744 19.3401 15.9459 18.5117 15.9459L18.1973 15.9459L18.1973 9.74597Z", stroke: "#051851", fill: "none" }),
        React__default.createElement("path", { d: "M3.79883 14.2834L3.14497 14.2834C2.59269 14.2834 2.14498 13.8357 2.14498 13.2834L2.14498 12.2982C2.14498 11.7459 2.59269 11.2982 3.14497 11.2982L3.79883 11.2982L3.79883 14.2834Z", stroke: "#051851", fill: "none" }),
        React__default.createElement("path", { d: "M20.2031 11.4086L20.857 11.4086C21.4093 11.4086 21.857 11.8563 21.857 12.4086L21.857 13.3938C21.857 13.9461 21.4093 14.3938 20.857 14.3938L20.2031 14.3938L20.2031 11.4086Z", stroke: "#051851", fill: "none" }),
        React__default.createElement("path", { d: "M15.8242 13.5384L8.16868 13.5384", stroke: "#051851", strokeLinecap: "round", strokeLinejoin: "round" }),
        React__default.createElement("path", { d: "M8.16724 11.608L13.0234 11.608", stroke: "#051851", strokeLinecap: "round", strokeLinejoin: "round" }),
        React__default.createElement("path", { d: "M14.6243 11.6044L15.8105 11.6044", stroke: "#051851", strokeLinecap: "round", strokeLinejoin: "round" })));
};

export { DumbbellIcon as default };
