import wpaTheme from './wpa/index.esm.js';
import { createTheme } from '@hermes/web-components';
import defaultTheme from './default/index.esm.js';

const themes = {
    default: defaultTheme,
    wpa: wpaTheme,
};
const getTheme = (themeName = 'default') => {
    return createTheme(Object.assign(Object.assign({}, themes[themeName]), { resolveThemeStyles: (styles) => styles[themeName] || {} }));
};

export { getTheme as default };
