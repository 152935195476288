import React, { useState, useCallback } from 'react';
import ReactPhoneInput from '../../node_modules/react-phone-number-input/min/index.esm.js';
import { PhoneInputContainer } from './styles.esm.js';

const phoneNumberRegex = /^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){7,13}\d$/;
const defaultCountryMeta = {
    countryCode: '+44',
    countryShortName: 'GB',
};
const PhoneInput = ({ initialPhoneNumber, placeholder, required, validate, onInput, }) => {
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber || '');
    const handleInput = useCallback((value) => {
        const input = value !== null && value !== void 0 ? value : '';
        setPhoneNumber(input);
        onInput === null || onInput === void 0 ? void 0 : onInput(input);
    }, [onInput]);
    const handleBlur = useCallback(() => {
        if (required && !phoneNumber) {
            validate === null || validate === void 0 ? void 0 : validate(false);
            return;
        }
        const isValid = phoneNumberRegex.test(phoneNumber);
        validate === null || validate === void 0 ? void 0 : validate(isValid);
    }, [phoneNumber, required, validate]);
    return (React.createElement(PhoneInputContainer, null,
        React.createElement(ReactPhoneInput, { international: true, countryCallingCodeEditable: false, addInternationalOption: false, onBlur: handleBlur, placeholder: placeholder, value: phoneNumber, onChange: handleInput, defaultCountry: defaultCountryMeta.countryShortName })));
};

export { PhoneInput as default };
