import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')(({ theme }) => (Object.assign(Object.assign({ position: 'relative', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px', boxSizing: 'content-box', padding: '16px 8px' }, theme.resolveThemeStyles({
    default: {
        backgroundColor: theme.palette.background.default,
    },
    wpa: {
        backgroundColor: theme.palette.blue[200],
    },
})), { width: '100%', borderTop: `solid thin ${theme.palette.grey[600]}`, borderBottom: `solid thin ${theme.palette.grey[600]}`, '&::before, &::after': {
        content: '""',
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'inherit',
        border: 'inherit',
    }, '&::before': {
        left: 'calc(-100%)',
    }, '&::after': {
        right: 'calc(-100%)',
    }, '@media (max-width: 480px)': {
        padding: '16px 0',
    } })));

export { Container };
