import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    padding: '13px 20px 13px 20px',
    borderRadius: '32px',
    border: '1px solid #D5DEF9',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    gap: '10px',
    width: '100%',
    boxSizing: 'border-box',
    userSelect: 'none',
    '&>svg': {
        color: theme.palette.system.darkNavy900,
    },
    '&:hover, &:active': {
        border: '1px solid #809BEC',
        '&>svg': {
            color: theme.palette.primary.main,
        },
    },
}));
const ContentWrapper = styledComponent('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});
const RatingWrapper = styledComponent('div')({
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    gap: '4px',
    userSelect: 'none',
    '&>span': {
        whiteSpace: 'nowrap',
    },
});
const Label = styledComponent('div')({
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    textAlign: 'left',
});

export { Container, ContentWrapper, Label, RatingWrapper };
