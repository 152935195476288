interface Props {
  followUpFees?: number
  newFees?: number
  currencySymbol?: string
  isRtl?: boolean
}

const getConsultationFeesString = ({ newFees, followUpFees, currencySymbol, isRtl }: Props) => {
  if (!newFees && !followUpFees) {
    return ''
  }

  if (!newFees || !followUpFees) {
    return `${currencySymbol}${newFees || followUpFees}`
  }

  const fees = {
    min: `${currencySymbol}${Math.min(newFees, followUpFees)}`,
    max: `${currencySymbol}${Math.max(newFees, followUpFees)}`
  }

  return isRtl ? `${fees.max}-${fees.min}` : `${fees.min}-${fees.max}`
}

export default getConsultationFeesString
