import { theme } from '@hermes/web-components'
import { CustomStyles } from './customStyles'

const borderBlueColor = '1px #142F76 solid'
const playIconPathColor = '#142F76 !important'
const borderSelected = '1px solid #2258B1'

const custom: CustomStyles = {
  common: {
    searchIconColor: '#2258B1',
    treatmentChip: {
      border: '0.5px solid #3B7D61'
    },
    circleChartColors: ['#2258B1', '#142F76', '#00A3FF', '#3E91E3', '#9FC5EC', '#CFD4DF'],
    ratingBarChartColors: ['#2258B1', '#00A3FF', '#9FC5EC', '#EAEEFC'],
    linearChartEndorsementsColors: ['#2258B1', '#30692E', '#3E91E3', '#C1FAE2', '#B7BFCE'],
    pagination: {
      '&  .MuiButtonBase-root': {
        border: '1px #D5DEF9 solid'
      }
    },
    paginationItem: {
      '&.Mui-selected': {
        backgroundColor: '#142F76',
        color: '#FFF'
      },
      '&  .MuiButtonBase-root': {
        border: '1px #D5DEF9 solid'
      }
    },
    badgeTextColor: '#2C3341',
    disabledTextColor: '#3C4659',
    mainColor: '#142F76'
  },
  practiceLocationCardBadge: {
    marginBottom: '18px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '0px !important'
    }
  },
  RatingBar: {
    sectionTitle: {
      textTransform: 'none',
      fontWeight: 400,
      '&>b': {
        fontWeight: 400
      }
    }
  },
  ProfileIntro: {
    profileTitleTypography: {
      fontSize: '27px',
      fontWeight: 700,
      color: 'common.white',
      [theme.breakpoints.down('sm')]: {
        fontSize: '21px'
      }
    },
    profileKeywordTypography: {
      fontSize: '13.33px',
      fontWeight: 500,
      color: 'common.white',
      lineHeight: '20px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px'
      }
    }
  },
  SearchLayout: {
    backgroundColor: '#FBFDFF',
    marginTop: '0 !important'
  },
  SearchBar: {
    backgroundColor: '#142F76',
    position: 'relative',
    searchTypeCardColor: '#2258B1',
    searchResultBadgeContainer: {
      gap: '6px'
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'inherit',
      right: '-100%',
      top: 0
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'inherit',
      left: '-100%',
      top: 0
    },

    searchButton: {
      position: 'absolute',
      width: '60px',
      height: '60px',
      top: 0,
      right: 0,
      filter: 'drop-shadow(0px 0px 10px #21BBFF)',
      backgroundColor: '#E2EEFA',
      color: '#2050A5 !important',
      '&:hover, &:focus, &:active': {
        background: 'linear-gradient(136deg, #E2EEFA 8.91%, #AAC6F5 84.38%)'
      }
    },
    myLocationIcon: {
      right: 95
    },
    locationContainer: {
      paddingRight: '68px',
      '& svg': {
        color: '#051851'
      }
    },
    profileTypeContainer: {
      '& svg': {
        color: '#142F76'
      }
    },

    mobile: {
      container: {
        padding: '0 !important',

        '& .MuiFormControl-root:first-of-type': {
          marginTop: 0
        }
      },
      section: {
        position: 'relative',
        py: { xs: 2, lg: 4 },
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),

        '&:after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'inherit',
          right: '-100%',
          top: 0
        },
        '&:before': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'inherit',
          left: '-100%',
          top: 0
        },

        '&:nth-child(2)': {
          marginTop: '8px',
          marginBottom: '8px'
        }
      },
      controlsContainer: {
        paddingBottom: '8px !important'
      },
      searchButton: {
        backgroundColor: '#E2EEFA',
        boxShadow: '0px 0px 20px 0px #3E91E3',
        color: playIconPathColor,
        mt: '12px',
        height: '40px',
        borderRadius: '24px'
      },
      profileSelectModal: {
        backgroundColor: '#E2EEFA',

        title: {
          textAlign: 'center'
        },

        profileSelectItem: {
          flex: 1,
          maxWidth: '100%'
        },

        backButton: {
          marginTop: '48px'
        }
      },
      searchResultAutocompleteModal: {
        position: 'relative',
        backgroundColor: '#142F76',
        paddingTop: '16px',
        paddingBottom: '16px',
        color: 'white',

        '& p': {
          color: 'white'
        },

        '& .MuiFormControl-root:first-of-type': {
          marginTop: 0
        },

        backButton: {
          padding: '6px 0'
        },

        backButtonWrapper: {
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          gap: '8px'
        }
      },
      buttonHover: {
        height: '30px',
        borderRadius: '15px',
        borderColor: '#2258B1',
        marginLeft: '4px',
        '&:focus': { outlineOffset: '-1px' },
        '&:hover, &:focus': {
          background: '#2258B1',
          svg: { fill: 'white' },
          '.MuiTypography-root': {
            color: 'white'
          }
        }
      }
    },
    resultsList: {
      div: {
        padding: '6px 16px'
      },
      a: {
        backgroundColor: '#E2EEFA',
        borderRadius: '16px',
        padding: '2px 8px',
        color: '#2258B1',
        textDecoration: 'none',
        maxWidth: '100%',
        display: 'inline-flex',
        fontWeight: 500,
        fontSize: '13.33px',
        boxSizing: 'border-box',
        position: 'relative',
        span: {
          boxSizing: 'border-box',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        },
        border: '0.5px solid #2258B1',
        '&:hover': {
          border: '0.5px solid #3B7D61',
          backgroundColor: '#EBFFF7'
        }
      }
    }
  },
  SearchFilters: {
    chipsVariant: 'standard',
    button: {
      borderRadius: '24px',
      border: borderBlueColor,
      color: '#142F76',
      fontWeight: 800
    },
    select: {
      borderRadius: '24px',
      border: '1px solid #D5DEF9',
      color: '#2C3341',

      '& .MuiFormLabel-root': {
        color: 'red !important'
      },

      '& .MuiSvgIcon-root': {
        width: '20px',
        height: '20px',
        right: '16px',
        color: '#2C3341'
      },
      '.MuiSelect-select': {
        padding: '10.5px 14px',
        '&:focus': {
          borderRadius: '24px',
          background: '#FFF',
          outline: borderSelected
        }
      },

      '&:hover': {
        border: borderSelected
      }
    },
    inputLabel: {
      color: '#2C3341 !important'
    },
    mobile: {
      mainColor: '#2258B1',
      textColor: '#2258B1'
    }
  },
  SearchProfileCard: {
    container: {
      borderRadius: '16px',
      border: '1px solid #D5DEF9',
      background: '#FFFFFF',
      [theme.breakpoints.up('md')]: {
        padding: '24px'
      },
      [theme.breakpoints.down('md')]: {
        padding: '16px'
      }
    },
    mobileBadge: { marginTop: '15px', width: '106px', height: '28px' },
    content: {
      gap: '16px',
      [theme.breakpoints.down('sm')]: {
        gap: '12px'
      }
    },
    contactButton: {
      marginTop: '16px !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: '0px !important'
      },
      transition: 'none'
    },
    rateContainer: {
      backgroundColor: '#F6F6F6'
    },
    avatar: {
      width: '104px',
      height: '104px',
      backgroundColor: '#D5DEF9',
      '& > *': {
        fontSize: '50px'
      },
      [theme.breakpoints.down('sm')]: {
        width: '104px',
        height: '104px'
      }
    },
    profileAvatarContainer: {
      background: 'linear-gradient(124.99deg, #8DFFCF 15.87%, #E7BC40 82.95%) !important',
      border: '0',
      padding: '2px',
      borderRadius: '50%',
      '&:hover': {
        '& .MuiSvgIcon-root': {
          background: 'transparent !important'
        }
      },
      '& .MuiSvgIcon-root': {
        background: '#FFFFFF !important',
        '& path': {
          fill: playIconPathColor
        }
      }
    },
    avatarContainer: {
      '& .MuiSvgIcon-root': {
        backgroundColor: '#FFFFFF !important',
        border: 'solid thin #142F76',

        '& path': {
          fill: playIconPathColor
        }
      }
    },
    locationText: {
      textTransform: 'capitalize',
      color: '#2C3341'
    }
  },
  SpecialistPage: {
    profileMediaHeaderChips: {
      color: 'black'
    },
    common: {
      color: '#FFF',
      background: '#142F76',
      chipBackground: 'transparent',
      chipBorderColor: '#9FC5EC',
      border: borderBlueColor,
      borderSelected: '0.5px #3B7D61 solid',
      buttonBorderRadius: '32px',
      titleTextTransform: 'none',
      titleColor: '#051851',
      progressIndicatorColor: '#112971',
      tabBarItemHover: {
        [theme.breakpoints.up('md')]: {
          '&:hover': {
            color: '#3E91E3'
          }
        }
      }
    },
    ratingBar: {
      title: {
        fontWeight: 600,
        b: {
          fontWeight: 600
        }
      },
      reviewText: {
        color: '#051851',
        fontSize: '14px'
      },
      trustScoreInfo: {
        count: {
          fontSize: '24px',
          lineHeight: '32px',
          color: 'text.darkNavy',
          fontWeight: 700,
          fontFamily: 'ES Klarheit Kurrent',
          [theme.breakpoints.down('sm')]: {
            fontFamily: 'Poppins',
            fontSize: '16px',
            lineHeight: '22px'
          }
        },
        infoText: {
          fontFamily: 'Poppins',
          fontSize: '13px',
          lineHeight: '20px',
          textDecoration: 'underline'
        }
      }
    },
    intro: {
      mt: '16px',
      gap: 2
    },
    about: {
      titleColor: '#051851',
      fontWeight: '600',
      title: {
        mb: 2,
        fontSize: '18px',
        fontWeight: '500',
        lineHeight: '26px',
        fontFamily: 'Poppins'
      },
      subHeader: {
        lineHeight: '26px',
        fontSize: '18px',
        fontWeight: '500 !important',
        b: {
          fontWeight: '500'
        }
      }
    },
    location: {
      background: '#9FC5EC',
      containerBorderRadius: '4px'
    },
    review: {
      showMoreChips: '#E7EAEF',
      chipsTextColor: '#142F76',
      chipsBorder: borderBlueColor,
      chipActiveBackground: 'rgba(0, 229, 208, 0.2)'
    },
    endorsements: {
      background: '#E2EEFA',
      chipsColor: '#EBFFF7',
      chipsBackground: '#E2EEFA',
      chipsBorder: borderBlueColor,
      chipsSelectedBackground: '#EBFFF7',
      chipsSelectedBorder: borderSelected,
      chipsTextColor: '#142F76',
      endorsementsShowMoreChips: {
        border: '0.5px #142F76 solid',
        borderRadius: '16px',
        color: '#142F76',
        fontWeight: '500'
      },
      endorsementsChipsList: {
        cursor: 'pointer',
        fontWeight: '500',
        fontSize: '11.85px !important',
        border: borderBlueColor,
        backgroundColor: 'transparent',
        color: '#142F76'
      },
      endorsementsChipsSelected: {
        backgroundColor: '#142F76',
        color: '#FFF'
      },
      endorsementsShowMoreChipsText: {
        fontSize: '11.85px',
        lineHeight: '17.77px',
        fontWeight: 500
      },
      endorsementsCountText: {
        fontWeight: 500,
        fontSize: '15px',
        lineHeight: '20px',
        textTransform: 'capitalize'
      }
    }
  },
  TeamPracticePage: {
    keywords: {
      keywordsGroupsMargin: '48px'
    }
  },
  reviewsTitle: {
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: 400
  },
  locationCard: {
    lineHeight: '16px'
  },
  reviews: {
    searchField: {
      iconColor: '#2258B1'
    }
  }
}

export default custom
