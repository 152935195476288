import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')(({ theme, disabled, error }) => {
    let baseBorder = `solid thin ${theme.palette.grey[600]}`;
    if (error) {
        baseBorder = `solid thin ${theme.palette.system.coral900}`;
    }
    if (disabled) {
        baseBorder = `solid thin ${theme.palette.divider}`;
    }
    return {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: baseBorder,
        borderRadius: '32px',
        cursor: 'pointer',
        userSelect: 'none',
        pointerEvents: disabled ? 'none' : 'inherit',
        '&:hover': {
            border: disabled
                ? `solid thin ${theme.palette.divider}`
                : `solid thin ${theme.palette.blue[400]}`,
            '&>svg': {
                color: theme.palette.primary.main,
            },
        },
        '&:active, &:focus-within': {
            border: disabled
                ? `solid thin ${theme.palette.divider}`
                : `solid thin ${theme.palette.blue[300]}`,
            '&>svg': {
                color: disabled ? theme.palette.grey[500] : theme.palette.primary.main,
            },
        },
        '&>svg': {
            position: 'absolute',
            right: '20px',
            top: '50%',
            transform: 'translateY(-50%)',
        },
        '&>*': {
            color: disabled ? theme.palette.grey[500] : 'inherit',
        },
    };
});
const Select = styledComponent('div')(({ theme }) => ({
    padding: '13px 16px',
    borderRadius: '32px',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '22.5px',
    textAlign: 'left',
    border: 'none',
    color: theme.palette.text.primary,
    width: '100%',
    outline: 'none',
}));
const Options = styledComponent('div')(({ theme, isOpen }) => ({
    position: 'absolute',
    top: 'calc(100% + 5px)',
    left: '0',
    width: '100%',
    background: theme.palette.common.white,
    border: `solid thin ${theme.palette.grey[600]}`,
    borderRadius: '8px',
    zIndex: 10,
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    maxHeight: '250px',
    overflowY: 'auto',
    display: isOpen ? 'block' : 'none',
}));
const Option = styledComponent('div')(({ theme }) => ({
    fontFamily: `'Poppins', sans-serif`,
    padding: '12px 16px',
    cursor: 'pointer',
    backgroundColor: theme.palette.common.white,
    '&:hover': {
        backgroundColor: theme.palette.background.light,
    },
    '&.selected': {
        backgroundColor: theme.palette.background.light2,
    },
}));
const Input = styledComponent('input')(({ theme }) => ({
    padding: '13px 16px',
    borderRadius: '32px',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '22.5px',
    textAlign: 'left',
    border: 'none',
    color: theme.palette.text.primary,
    width: '100%',
    outline: 'none',
}));
const DOBContainer = styledComponent('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
});
const PhoneInputContainer = styledComponent(Container)(({ theme }) => ({
    '& .DialCode': {
        position: 'absolute',
        left: '28px',
        pointerEvents: 'none',
    },
    '& .PhoneInput': {
        display: 'flex',
        borderRadius: '32px',
        border: 'none',
        width: '100%',
        outline: 'none',
        '&>input': {
            fontFamily: `'Poppins', sans-serif`,
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '22.5px',
            textAlign: 'left',
            color: theme.palette.text.primary,
            width: '100%',
            height: '48px',
            padding: '0 16px',
            border: 'none',
            outline: 'none',
            borderRadius: '0 32px 32px 0',
        },
    },
    '& .PhoneInputCountry': {
        display: 'flex',
        position: 'relative,',
        '& .PhoneInputCountrySelect': {
            maxWidth: '64px',
            border: 'none',
            outline: 'none',
            borderRadius: '32px 0 0 32px',
            backgroundColor: theme.palette.grey[600],
            color: 'transparent',
            '&>option': {
                color: '#000',
            },
        },
    },
    '& .PhoneInputCountryIcon': {
        position: 'absolute',
        left: '17px',
        top: '50%',
        width: '30px',
        height: '20px',
        transform: 'translateY(-50%)',
        pointerEvents: 'none',
    },
}));

export { Container, DOBContainer, Input, Option, Options, PhoneInputContainer, Select };
