import React from 'react';

const getChevronDirection = (direction) => {
    if (direction === 'right')
        return '180';
    if (direction === 'up')
        return '90';
    if (direction === 'down')
        return '-90';
    return '0';
};
const Chevron = ({ direction = 'left' }) => (React.createElement("svg", { width: "9", height: "14", viewBox: "0 0 9 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", style: {
        transform: `rotate(${getChevronDirection(direction)}deg)`,
    } },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8.28906 12.2869C8.28906 11.9722 8.14374 11.6915 7.91655 11.5082L3.41821 7.00003L7.99821 2.41003L7.99227 2.4041C8.17553 2.22283 8.28906 1.97123 8.28906 1.69311C8.28906 1.14083 7.84135 0.693114 7.28906 0.693114C6.95183 0.693114 6.65358 0.860048 6.47245 1.11579L0.588208 7.00003L6.5703 12.9821C6.75211 13.17 7.00694 13.2869 7.28906 13.2869C7.84135 13.2869 8.28906 12.8391 8.28906 12.2869Z", fill: "currentColor" })));

export { Chevron as default };
