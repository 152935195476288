import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const VideotapeIcon = (_a) => {
    var props = __rest(_a, []);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React__default.createElement("circle", { cx: "12", cy: "12.0928", r: "7.71836", stroke: "#051851", strokeWidth: "1.2", fill: "none" }),
        React__default.createElement("circle", { cx: "12.0005", cy: "7.86035", r: "1.43408", stroke: "#051851", fill: "none" }),
        React__default.createElement("circle", { cx: "11.9995", cy: "12.0928", r: "0.70459", fill: "#051851" }),
        React__default.createElement("circle", { cx: "8.1333", cy: "10.7383", r: "1.43408", stroke: "#051851", fill: "none" }),
        React__default.createElement("circle", { cx: "9.48486", cy: "15.3882", r: "1.43408", stroke: "#051851", fill: "none" }),
        React__default.createElement("circle", { cx: "15.8677", cy: "10.7383", r: "1.43408", stroke: "#051851", fill: "none" }),
        React__default.createElement("circle", { cx: "14.4419", cy: "15.3882", r: "1.43408", stroke: "#051851", fill: "none" }),
        React__default.createElement("path", { d: "M20.4336 21.0257C19.6682 20.437 17.5301 19.3374 15.1005 19.6483C12.671 19.9592 11.0277 19.7779 10.5098 19.6483", stroke: "#051851", strokeWidth: "1.2", strokeLinecap: "round" })));
};

export { VideotapeIcon as default };
