import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
});
const StickyComponent = styledComponent('div')({
    position: 'sticky',
    width: '100%',
    bottom: 0,
});

export { Container, StickyComponent };
