import { Marker } from './map'
import { ProfileKeyword } from './keywords'
import { PracticeProfile } from './practice'
import { SpecialistPractice, SpecialistProfile } from './specialist'
import { ReturnTypeProps as BookingCalendarContext } from '../providers/BookingCalendar/types'
import { HiddenProps } from '@hermes/web-components'

export * from './specialist'
export * from './practice'
export * from './contactDetails'
export * from './keywords'
export * from './peerRecommendations'
export * from './reviews'
export * from './statistics'
export * from './tenantSettings'
export * from './user'
export * from './searchProfileData'
export interface PracticeTeamMember {
  id: number
  name: string
  specialty: string
  description: string
  shortName: string
  rating: number
}

export interface PracticeKeyword {
  countInReviews: number
  description: Record<string, string>
  directLink: boolean
  iconUri: string
  id: number
  isTopLevelChild: boolean
  keywordId: number
  keywordType: string
  name: Record<string, string>
  parentId: number
  parentName: Record<string, string>
  relationId: number
  sortOrder: number
}

export interface ProfileInsurer {
  id: number
  iconUri: string
  name: string
  website: string
}

export interface Language {
  id: number
  isoCode: string
  name: string
}

export interface Insurer {
  id: number
  iconUri: string
  name: string
  website: string
}

export interface LocationSuggestion {
  description: string
  place_id: string
  googlePlaceId: string
  name: Record<string, string>
  slug: string
}

export type MapState = {
  isOpen: boolean
  marker: Marker
}

export type ProfileIntroProps = {
  id: string
  isBasic?: boolean
  slug: string
  plan: string
  name: string
  suffix?: string
  image?: string
  address?: PracticeProfile['address']
  keywords?: SpecialistProfile['keywords']
  yearsOfExperience?: SpecialistProfile['yearsOfExperience']
  isPractice?: boolean
  isEntry?: boolean
  practices?: SpecialistProfile['practices']
  isUnclaimed?: boolean
  videoConsultation?: boolean
  premium?: boolean
  feeAgreed?: boolean
  introVideoAvailable?: boolean
  setVideoPlayerVisibility?: (bool: boolean) => void
  connectionsCount?: number
  endorsementsCount?: number
  profileAnalytics?: {
    profile: {
      prf_type: string
      prf_uuid: string
      prf_specialty: string
      prf_practice_type?: string
      prf_facilities?: boolean
    }
    review: {
      rvw_count: string
      vw_score: string
    }
  }
}

export interface ProfileTabsProps extends HiddenProps {
  specialist?: SpecialistProfile
  practice?: PracticeProfile
  isUnclaimed?: boolean
}

export type RequestType = 'Email' | 'Phone' | 'ExternalBookingLink'
export type RequestSource = 'Desktop' | 'Mobile'

type BaseContactRequest = {
  lang: string
  slug: string
  requestType: RequestType
  source: RequestSource
  isPractice?: boolean
  specialistId?: number
  practiceId?: number
  requestHeaders?: Record<any, unknown>
}
type EmailRequest = BaseContactRequest & {
  email: string
  phone?: never
  externalBookingLink?: never
}

type PhoneRequest = BaseContactRequest & {
  phone: string
  email?: never
  externalBookingLink?: never
}

type ExternalBookingLinkRequest = BaseContactRequest & {
  externalBookingLink: string
  email?: never
  phone?: never
}

export type ContactRequest = EmailRequest | PhoneRequest | ExternalBookingLinkRequest

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never }
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U

export type PracticeOrSpecialistProfileData = XOR<PracticeProfile, SpecialistProfile>

export type PracticeOrSpecialistProfileWithLang = XOR<
  { practice: PracticeProfile; language: string; specialty: ProfileKeyword },
  { specialist: SpecialistProfile; language: string; specialty: ProfileKeyword }
>

export type SpecialistPracticeOrSpecialistProfileWithLang = XOR<
  { practice: PracticeProfile | SpecialistPractice; language: string },
  { specialist: SpecialistProfile; language: string }
>

export type IntroductionVideo = {
  url: string
  mediaId: number
  practiceId?: number
  specialistId?: number
}

export type PracticeOrSpecialistProfile = XOR<
  {
    practice: PracticeProfile
    handleSetVideoPlayerModalData: (props: IntroductionVideo) => void
  },
  {
    specialist: SpecialistProfile
    handleSetVideoPlayerModalData: (props: IntroductionVideo) => void
  }
>

export type ModalLayoutProps = {
  isOpen: boolean
  onClose: () => void
}

export interface ContactModalLayoutProps extends ModalLayoutProps {
  heading: string
  avatar?: string
  name?: string
  rating?: number
  reviewsTotal?: number
  hideProfileHeader?: boolean
}

export type ContactsModalProps = {
  practices?: SpecialistPractice[]
}

export type ModalProps = ModalLayoutProps & ContactsModalProps

export interface BookAppointmentModalProps extends ModalProps {
  externalBookingLink?: string
  practice?: PracticeProfile
  specialist?: SpecialistProfile
  currentLocation?: string
  bookingModalActiveButton?: string
  name?: string
  hideProfileHeader?: boolean
  bookingCalendarContext?: Pick<
    BookingCalendarContext,
    | 'isBookingAvailable'
    | 'isInsuranceModalOpen'
    | 'locationModalVariant'
    | 'closeProfileSelectionModal'
    | 'handleInsuranceSelectClick'
    | 'handlePracticeChange'
    | 'getPracticeSlots'
    | 'state'
  >
}

export type Location = {
  country: boolean
  googlePlaceId: string
  id: number
  lat: string
  lon: string
  name: Record<string, string>
  searchDistance: number | null
  slug: string | null
  type: string | null
  children: Location[] | null
  description?: string
  place_id?: string
}

export type SortOptions = {
  name: string
  value: string
}

export type LocationWithChildren = {
  children: LocationWithChildren[]
  geolocation: {
    type: string
    coordinates: number[]
  }
  googlePlaceId: string
  id: number
  name: Record<string, string>
  searchDistance: number | null
  slug: string
  type: string
}

export interface ContactDetailsModalProps extends ModalProps {
  phone?: string
  email?: string
  practice?: PracticeProfile
  specialist?: SpecialistProfile
  useSinglePointOfContact?: boolean
  name?: string
}
