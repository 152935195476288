import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const CupIcon = (_a) => {
    var props = __rest(_a, []);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "24", height: "25", viewBox: "0 0 24 25", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
        React__default.createElement("path", { d: "M4.69141 11.1221H16.377C16.9293 11.1221 17.377 11.5698 17.377 12.1221V14.5137V17.0238C17.377 19.233 15.5861 21.0239 13.377 21.0239H7.69141C5.48227 21.0239 3.69141 19.233 3.69141 17.0239V12.1221C3.69141 11.5698 4.13912 11.1221 4.69141 11.1221Z", stroke: "#051851", strokeWidth: "1.2", fill: "none" }),
        React__default.createElement("path", { d: "M17.4277 12.5598H19.8642C20.9224 12.5598 21.6479 13.626 21.2595 14.6103L20.8385 15.6771C20.6679 16.1096 20.3063 16.4386 19.8596 16.5676L17.4277 17.2702", stroke: "#051851", strokeWidth: "1.2", fill: "none" }),
        React__default.createElement("path", { d: "M6.23273 15.3497C6.56411 15.3497 6.83273 15.0811 6.83273 14.7497C6.83273 14.4183 6.56411 14.1497 6.23273 14.1497V15.3497ZM3.74609 15.3497H6.23273V14.1497H3.74609V15.3497Z", fill: "#051851" }),
        React__default.createElement("path", { d: "M8.60156 14.7959L17.2834 14.7959", stroke: "#051851", strokeWidth: "1.2", strokeLinecap: "round" }),
        React__default.createElement("path", { d: "M8.43615 8.89456L7.96525 8.14678C7.58429 7.54181 7.69242 6.75068 8.22174 6.27012V6.27012C8.86599 5.68524 8.86599 4.67248 8.22174 4.0876L7.60946 3.53174", stroke: "#051851", strokeLinecap: "round" }),
        React__default.createElement("path", { d: "M11.9537 8.89456L11.4828 8.14678C11.1019 7.54181 11.21 6.75068 11.7393 6.27012V6.27012C12.3836 5.68524 12.3836 4.67248 11.7393 4.0876L11.127 3.53174", stroke: "#051851", strokeLinecap: "round" })));
};

export { CupIcon as default };
