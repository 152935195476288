import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const CalendarIcon = (props) => (React__default.createElement(SvgIcon, Object.assign({ fontSize: "inherit", viewBox: "0 0 18 17" }, props),
    React__default.createElement("rect", { x: "3.08594", y: "4.30913", width: "15.166", height: "12.8913", rx: "2", fill: "white", fillOpacity: "0.3", stroke: "currentColor", strokeWidth: "1.2" }),
    React__default.createElement("path", { d: "M3.08594 7.74681H18.252", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" }),
    React__default.createElement("path", { d: "M7.54688 3.44951L7.54687 5.16835", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" }),
    React__default.createElement("path", { d: "M13.791 3.44951L13.791 5.16835", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" }),
    React__default.createElement("path", { d: "M8.29102 17.1997C16.0942 17.1997 18.045 11.0067 18.045 7.91016V15.6997C18.045 16.5281 17.3734 17.1997 16.545 17.1997H8.29102Z", fill: "#1A3586", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" })));

export { CalendarIcon as default };
