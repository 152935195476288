import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const CheckboxCircle = (_a) => {
    var props = __rest(_a, []);
    return (React__default.createElement(SvgIcon, Object.assign({ xmlns: "http://www.w3.org/2000/svg", width: "20", height: "20", fill: "none", viewBox: "0 0 20 20" }, props),
        React__default.createElement("path", { fill: "#008579", fillRule: "evenodd", d: "M19.167 10A9.167 9.167 0 11.833 10a9.167 9.167 0 0118.334 0zM5 10.35l1.175-1.175 2.158 2.15 5.492-5.492L15 7.017l-6.667 6.666L5 10.35z", clipRule: "evenodd" })));
};

export { CheckboxCircle as default };
