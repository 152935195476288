import React from 'react';

const Call = () => {
    return (React.createElement("svg", { width: "21", height: "20", viewBox: "0 0 21 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M9.00526 5.00922L6.87446 2.87842C6.48683 2.4908 5.8594 2.4875 5.46772 2.87102L4.96817 3.36017C3.02778 5.26016 2.95416 8.36687 4.85747 10.304C6.74557 12.2257 8.58644 14.0214 10.5885 15.9491C12.5527 17.8404 15.6736 17.8006 17.5959 15.8666L18.115 15.3444C18.5036 14.9535 18.5026 14.322 18.1129 13.9323L15.7413 11.5607C15.3508 11.1702 14.7176 11.1702 14.3271 11.5607L13.2758 12.612C12.912 12.9757 12.3298 13.0042 11.937 12.672C9.95574 10.9967 9.18956 10.1025 8.0588 8.78264L8.01365 8.72994C7.67205 8.33125 7.69303 7.73567 8.06428 7.36442L9.00526 6.42344C9.39578 6.03291 9.39578 5.39975 9.00526 5.00922Z", stroke: "currentColor", strokeWidth: "1.2" }),
        React.createElement("path", { d: "M6.40039 5.36523L8.03044 6.95488", stroke: "currentColor", strokeLinecap: "round" }),
        React.createElement("path", { d: "M4.98633 3.99609L5.37767 4.37774", stroke: "currentColor", strokeLinecap: "round" }),
        React.createElement("path", { d: "M15.1035 14.127L17.1236 16.147", stroke: "currentColor", strokeLinecap: "round" })));
};

export { Call as default };
