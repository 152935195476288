import '../../_virtual/_tslib.esm.js';
import React__default, { useRef, useEffect, useMemo } from 'react';
import { SplideSlide, Splide as Splide2 } from '../../node_modules/@splidejs/react-splide/dist/js/react-splide.esm.esm.js';
import { NavigationContainer, NavButton } from './styles.esm.js';
import CarouselArrow from '../../icons/CarouselArrow/index.esm.js';
import { theme } from '../../theme/index.esm.js';
import styledComponent from '../../node_modules/@mui/material/styles/styled.esm.js';
import Grid from '../../node_modules/@mui/material/Grid/Grid.esm.js';

styledComponent(SplideSlide)({});
const CarouselNavButton = ({ onClick, type, refObj }) => (React__default.createElement(NavButton, { ref: refObj, sx: { backgroundColor: theme.palette.background.default }, onClick: onClick, "aria-label": `carousel-navigation-${type}` },
    type === 'next' && React__default.createElement(CarouselArrow, null),
    type === 'prev' && React__default.createElement(CarouselArrow, { rotation: 180 })));
const Carousel = ({ children, slidesPerView = 3, navigation = false, navigationPosition = 'laterally', navigationElement = null, pagination = false, isRtl = false, spaceBetween, slidesPerGroup = 1, updateOnMove = false, loop = false, vertical = false, currentSlide = 0, verticalHeight, trimSpace = false, centerFocus = false, slidePadding = 0, wheel = false, lazyLoad = false, sx = {}, breakpoints = {}, disableDrag = false }) => {
    const splide = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const [perPage, setPerPage] = React__default.useState(slidesPerView);
    useEffect(() => {
        if (splide.current) {
            splide.current.go(currentSlide);
        }
    }, [currentSlide, splide]);
    const handleNext = () => {
        var _a;
        (_a = splide.current) === null || _a === void 0 ? void 0 : _a.go('+1');
    };
    const handlePrev = () => { var _a; return (_a = splide.current) === null || _a === void 0 ? void 0 : _a.go('-1'); };
    const directionScroll = useMemo(() => (isRtl ? 'rtl' : 'ltr'), [isRtl]);
    return (React__default.createElement(Grid, { md: 12, lg: 12, xs: 12, sx: sx },
        React__default.createElement(Splide2, { ref: splide, options: Object.assign(Object.assign(Object.assign({}, (vertical
                ? {
                    direction: 'ttb',
                    height: `${verticalHeight}px`,
                    trimSpace,
                    focus: centerFocus ? 'center' : undefined
                }
                : {})), { type: loop ? 'loop' : 'slide', direction: directionScroll, paginationDirection: directionScroll, perPage: slidesPerView, perMove: slidesPerGroup, pagination, arrows: false, gap: spaceBetween, updateOnMove, padding: `${slidePadding}rem`, wheel, drag: !disableDrag, breakpoints }), (lazyLoad
                ? {
                    lazyLoad: 'nearby'
                }
                : {})), onMounted: (e) => {
                setPerPage((e === null || e === void 0 ? void 0 : e.options.perPage) || slidesPerView);
            } }, children),
        React__default.createElement(Grid, { sx: { display: 'flex', gap: '16px', alignItems: 'center', justifyContent: 'space-between', width: '100%' } },
            navigationElement,
            navigation && React__default.Children.count(children) > perPage && (React__default.createElement(NavigationContainer, { navigationPosition: navigationPosition },
                React__default.createElement(CarouselNavButton, { refObj: prevRef, onClick: handlePrev, type: isRtl ? 'next' : 'prev' }),
                React__default.createElement(CarouselNavButton, { refObj: nextRef, onClick: handleNext, type: isRtl ? 'prev' : 'next' }))))));
};

export { Carousel, CarouselNavButton };
