import React from 'react';
import { ContentWrapper, Label, RatingWrapper, Container } from './styles.esm.js';
import Star from '../../icons/Star.esm.js';
import Caret from '../../icons/Caret.esm.js';

const ContainerComponent = ({ children, disabled, onClick, }) => disabled ? (React.createElement("div", { style: { width: '100%' } }, children)) : (React.createElement(Container, { onClick: onClick }, children));
const LocationDropdown = ({ disabled, label, rating, reviewsNumber, onClick, }) => (React.createElement(ContainerComponent, { disabled: disabled, onClick: onClick },
    React.createElement(ContentWrapper, null,
        React.createElement(Label, null, label),
        React.createElement(RatingWrapper, null,
            React.createElement(Star, null),
            React.createElement("span", null,
                rating,
                " (",
                reviewsNumber,
                ")"))),
    disabled ? null : React.createElement(Caret, null)));

export { LocationDropdown as default };
