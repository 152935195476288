import React, { FC } from 'react'
import '@splidejs/react-splide/css'
import { Carousel, Grid, Typography, theme, useMediaQuery } from '@hermes/web-components'
import { SplideSlide } from '@splidejs/react-splide'
import { PracticeProfile } from '../../../../types/practice'
import PracticeCard from './PracticeCard'
import { NavButton } from '../../specialist/OtherTopRated'

export type OtherTopRatedPracticesProps = {
  practices?: PracticeProfile[]
  headerText?: string
  buttonText?: string
  shortButtonText?: string
  buttonLink?: string
  isRtl?: boolean
}

const OtherTopRated: FC<OtherTopRatedPracticesProps> = ({
  practices,
  headerText,
  buttonText,
  shortButtonText,
  buttonLink,
  isRtl
}) => {
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const buttonTextContent = isSmDown ? shortButtonText : buttonText

  const NavigationButton = <NavButton text={buttonTextContent || ''} href={buttonLink || ''} />

  if (!practices?.length) {
    return null
  }

  return (
    <Grid container>
      <Typography variant="body2" textTransform="uppercase" sx={{ mb: 2 }} color="text.darkNavy" fontWeight="700">
        {headerText}
      </Typography>
      <Carousel
        breakpoints={{ 610: { perPage: 1 } }}
        isRtl={isRtl}
        spaceBetween={16}
        navigationElement={NavigationButton}
        slidesPerView={2}
        slidesPerGroup={1}
        vertical={false}
        navigation
        navigationPosition="bottom"
      >
        {practices.map((p) => (
          <SplideSlide key={`other-practices-${p.id}`}>
            <Grid sx={{ pb: 2, height: '100%' }}>
              <PracticeCard practice={p} />
            </Grid>
          </SplideSlide>
        ))}
      </Carousel>
    </Grid>
  )
}

export default OtherTopRated
