export const TENANTS = {
  UK: 'uk',
  DE: 'de',
  AT: 'de-at',
  AU: 'au',
  EN_UAE: 'en-ae',
  AR_UAE: 'ar-ae',
  WPA: 'wpa'
}

export const LANGUAGES = {
  EN: 'en',
  DE: 'de',
  AR: 'ar'
}

export const GTM_ID = 'GTM-K73CL79'
export const WPA_GTM_ID = 'GTM-NBGD8F78'

export const RTL_TENANTS = [TENANTS.AR_UAE]
export const RIGHT_TO_LEFT = 'rtl'
export const LEFT_TO_RIGHT = 'ltr'
export const MUI_RTL = 'muirtl'
export const MUI_LTR = 'muiltr'
export const DEFAULT_LOCALE = 'en'
export const PATH_TO_UNAVAILABLE_IMG = '/hermes-static/images/video-unavailable.webp'

export const remapDaysOfWeekIndex = [7, 1, 2, 3, 4, 5, 6] // Where: 7 - Sunday, 1 - Monday, ..., 6 - Saturday

export const YOUTUBE_REGEX = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/
export const VIMEO_REGEX = /^(https?:\/\/)?(www\.)?(vimeo\.com)\/.+$/
export const imageBasePathS3 = 'https://doctify-athena-static.s3.eu-central-1.amazonaws.com/images/athena-uk'
export const activeMarkerIndicator = `${imageBasePathS3}/static/icons/map-marker-active.svg`
export const inactiveMarkerIndicator = `${imageBasePathS3}/static/icons/map-marker-inactive.svg`
export const wpaMarkerIndicator = `${imageBasePathS3}/static/icons/wpa/wpa-map-marker.svg`
