import { __awaiter } from './_virtual/_tslib.esm.js';
import React, { useState, useCallback, useEffect } from 'react';
import BookingCalendar from './BookingCalendar.esm.js';
import { ThemeProvider } from '@hermes/web-components';
import { SCREENS as SCREENS$1 } from './constants.esm.js';
import { formatTimeStringToDisplay, formatDateStringToDisplay } from './components/Calendar/utils.esm.js';
import getTheme from './theme/index.esm.js';
export { BOOKING_THEMES } from './types.esm.js';

const defaultScreen = SCREENS$1.CALENDAR_PREVIEW;
const App = ({ currentScreen, slots, specialistProfile, practiceProfile, visitReasons, onProfileSelectionClick, onDateSelectionClick, onTimeSelectionClick, onSlotSelectionClick, onBookButtonClick = () => __awaiter(void 0, void 0, void 0, function* () { return true; }), onContactRequestClick, onSlotNotAvailableBackClick, onCalendarExpandClick, onChangeScreen, onBackButtonClick, onInsuranceSelectClick, onShowMoreDatesClick, onShowMoreSlotsClick, onViewAllAvailabilityClick, selectedInsurance, selectedDateString, selectedTimeString, containerStyle, expandedView, displayMobileContactPanel, displayLocationDropdown, formFields, availabilityId, provider, source, theme = 'default', hideTitle = false, }) => {
    const currentTheme = getTheme(theme);
    const [state, setState] = useState({
        practice: practiceProfile,
        specialist: specialistProfile,
        currentScreen: currentScreen !== null && currentScreen !== void 0 ? currentScreen : defaultScreen,
        selectedSlot: null,
        selectedDateString: undefined,
        selectedTimeString: undefined,
    });
    const changeScreen = useCallback((screen) => {
        setState((prev) => (Object.assign(Object.assign({}, prev), { currentScreen: screen })));
        onChangeScreen === null || onChangeScreen === void 0 ? void 0 : onChangeScreen(screen);
    }, [onChangeScreen]);
    const handleDateSelectionClick = useCallback((dateString) => {
        setState((prev) => (Object.assign(Object.assign({}, prev), { selectedDateString: dateString })));
        onDateSelectionClick === null || onDateSelectionClick === void 0 ? void 0 : onDateSelectionClick(dateString);
    }, [onDateSelectionClick]);
    const handleTimeSelectionClick = useCallback((timeString, triggerSource) => {
        setState((prev) => (Object.assign(Object.assign({}, prev), { selectedTimeString: timeString })));
        onTimeSelectionClick === null || onTimeSelectionClick === void 0 ? void 0 : onTimeSelectionClick(timeString, triggerSource);
    }, [onTimeSelectionClick]);
    const handleSlotSelectionClick = useCallback((slot) => {
        setState((prev) => (Object.assign(Object.assign({}, prev), { selectedSlot: slot !== null && slot !== void 0 ? slot : null })));
        onSlotSelectionClick === null || onSlotSelectionClick === void 0 ? void 0 : onSlotSelectionClick(slot);
    }, [onSlotSelectionClick]);
    const handleBookButtonClick = useCallback((formData) => onBookButtonClick(formData), [onBookButtonClick]);
    useEffect(() => {
        setState((prev) => (Object.assign(Object.assign({}, prev), { currentScreen: currentScreen !== null && currentScreen !== void 0 ? currentScreen : prev.currentScreen, practice: practiceProfile !== null && practiceProfile !== void 0 ? practiceProfile : prev.practice, specialist: specialistProfile !== null && specialistProfile !== void 0 ? specialistProfile : prev.specialist, selectedDateString: selectedDateString !== null && selectedDateString !== void 0 ? selectedDateString : prev.selectedDateString, selectedTimeString: selectedTimeString !== null && selectedTimeString !== void 0 ? selectedTimeString : prev.selectedTimeString })));
    }, [
        currentScreen,
        practiceProfile,
        specialistProfile,
        selectedDateString,
        selectedTimeString,
    ]);
    return (React.createElement(ThemeProvider, { theme: currentTheme },
        React.createElement(BookingCalendar, { containerStyle: containerStyle, currentScreen: state.currentScreen, changeScreen: changeScreen, slots: slots, specialistProfile: state.specialist, practiceProfile: state.practice, visitReasons: visitReasons, expandedView: expandedView, onProfileSelectionClick: onProfileSelectionClick, onDateSelectionClick: handleDateSelectionClick, onTimeSelectionClick: handleTimeSelectionClick, onSlotSelectionClick: handleSlotSelectionClick, onBookButtonClick: handleBookButtonClick, onContactRequestClick: onContactRequestClick, onSlotNotAvailableBackClick: onSlotNotAvailableBackClick, onInsuranceSelectClick: onInsuranceSelectClick, selectedInsurance: selectedInsurance, selectedDateString: state.selectedDateString, selectedTimeString: state.selectedTimeString, onCalendarExpandClick: onCalendarExpandClick, onBackButtonClick: onBackButtonClick, displayMobileContactPanel: displayMobileContactPanel, displayLocationDropdown: displayLocationDropdown, onShowMoreDatesClick: onShowMoreDatesClick, onShowMoreSlotsClick: onShowMoreSlotsClick, onViewAllAvailabilityClick: onViewAllAvailabilityClick, formFields: formFields, availabilityId: availabilityId, provider: provider, source: source, hideTitle: hideTitle })));
};
const SCREENS = SCREENS$1;
const calendarUtils = {
    formatTimeStringToDisplay,
    formatDateStringToDisplay,
};

export { SCREENS, calendarUtils, App as default };
