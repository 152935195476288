import { sortDatesFn, getWithTimezoneOffset } from '../Calendar/utils.esm.js';
import { DATE_LOCALE } from '../../constants.esm.js';

const groupSlotsByDate = (timeSlots) => timeSlots
    .map((slot) => ({
    dateTime: new Date(`${slot.date} ${slot.time}`),
    originalSlot: slot,
}))
    .sort((a, b) => sortDatesFn(a.dateTime, b.dateTime))
    .map(({ originalSlot }) => originalSlot)
    .reduce((p, slot) => {
    const dateString = getWithTimezoneOffset(new Date(slot.date)).toLocaleDateString(DATE_LOCALE, {
        weekday: 'long',
        day: 'numeric',
        month: 'long',
    });
    if (!p[dateString]) {
        p[dateString] = [];
    }
    p[dateString].push({
        dateString,
        slot: slot,
    });
    return p;
}, {});

export { groupSlotsByDate };
