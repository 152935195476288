import React, { useState, useRef, useCallback, useEffect } from 'react';
import Caret from '../../icons/Caret.esm.js';
import { Container, Select, Options, Option } from './styles.esm.js';
import useOnClickOutside from '../../hooks/useClickOutside.esm.js';

const LETTER_REGEXP = /^[a-zA-Z]$/;
const Dropdown = ({ items, selected, onChange, validate, disabled, isError, }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSelected, setCurrentSelected] = useState(selected || items[0]);
    const dropdownRef = useRef(null);
    const optionsRef = useRef(null);
    const toggleDropdown = useCallback(() => {
        if (!disabled) {
            setIsOpen((prev) => !prev);
        }
    }, [disabled]);
    const onOutsideClick = useCallback(() => setIsOpen(false), []);
    useOnClickOutside(dropdownRef, onOutsideClick);
    const handleSelect = useCallback((item) => {
        setCurrentSelected(item);
        setIsOpen(false);
        onChange === null || onChange === void 0 ? void 0 : onChange(item);
        validate === null || validate === void 0 ? void 0 : validate(item);
    }, [onChange, validate]);
    useEffect(() => {
        setCurrentSelected(selected !== null && selected !== void 0 ? selected : items[0]);
    }, [items, selected]);
    const handleKeyPress = useCallback((event) => {
        const { key } = event;
        if (!isOpen || !optionsRef.current || !LETTER_REGEXP.test(key)) {
            return;
        }
        const lowerKey = key.toLowerCase();
        const matchingIndex = items.findIndex(({ label }) => label.toLowerCase().startsWith(lowerKey));
        if (matchingIndex !== -1) {
            const matchingElement = optionsRef.current.children[matchingIndex];
            matchingElement === null || matchingElement === void 0 ? void 0 : matchingElement.scrollIntoView({ block: 'nearest' });
        }
    }, [items, isOpen]);
    useEffect(() => {
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);
    return (React.createElement(Container, { ref: dropdownRef, disabled: disabled, error: isError },
        React.createElement(Select, { onClick: toggleDropdown }, currentSelected.label),
        React.createElement(Options, { ref: optionsRef, isOpen: isOpen }, items.map((item, index) => (React.createElement(Option, { key: index, onClick: () => handleSelect(item) }, item.label)))),
        React.createElement(Caret, null)));
};

export { Dropdown as default };
