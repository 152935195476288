import { Grid, Box, Typography, Link, TrustLarge, styledComponent } from '@hermes/web-components'
import { useContext } from 'react'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../providers/AppData'
import ArrowRightIcon from '../../Icons/ArrowRightIcon'

const getLinkPath = (locale: string) => {
  if (locale.includes('de')) {
    return '/vertrauen/die-doctify-bewertungen'
  }

  return '/trust/about-doctify-reviews'
}

const LearnMore = styledComponent(Link)(({ theme }) => ({
  ...theme.typography.caption,
  color: theme.palette.common.white,
  textDecoration: 'underline',
  marginRight: theme.spacing(0.5),
  fontWeight: 500,
  lineHeight: '18px'
}))

const exceptionBuilder = (locale: string) => {
  const objException: { [key: string]: string } = {
    'ar-ae': 'en-ae'
  }

  return objException[locale] ?? locale
}

const SpecialistTrustBanner = () => {
  const translate = useTranslateMessage()

  const { locale } = useContext(AppDataContext)
  const resultLocale = exceptionBuilder(locale)
  const link = `/${resultLocale}/about${getLinkPath(locale)}`

  return (
    <Grid container sx={{ py: 2 }} alignItems="center" wrap="nowrap">
      <TrustLarge />
      <Box sx={{ ml: 2 }}>
        <Typography variant="caption" color="common.white" lineHeight="18px" fontWeight="500" sx={{ mb: 0 }}>
          {translate('profile.trust_banner')}
        </Typography>
        <Box display="flex" alignItems="center">
          <LearnMore href={link}>{translate('learn_more_here')}</LearnMore>
          <ArrowRightIcon reflectIfRtl sx={{ fontSize: 12 }} color="#fff" />
        </Box>
      </Box>
    </Grid>
  )
}

export default SpecialistTrustBanner
