import React, { useState, useMemo, useCallback } from 'react';
import Calendar from '../../components/Calendar/index.esm.js';
import StepFooter from '../../views/StepFooter/index.esm.js';
import { Container, Title, FirstAvailableContainer, ExpandWrapper } from './styles.esm.js';
import LocationDropdown from '../../components/LocationDropdown/index.esm.js';
import StickyBottomContainer from '../../components/StickyBottomContainer/index.esm.js';
import { DATE_LOCALE } from '../../constants.esm.js';
import { getWithTimezoneOffset } from '../../components/Calendar/utils.esm.js';
import useIsDesktop from '../../hooks/useIsDesktop.esm.js';
import Expand from '../../icons/Expand.esm.js';
import ArrowBack from '../../icons/ArrowBack.esm.js';
import { TextItem } from '../../components/SlotSelection/styles.esm.js';
import { FirstAvailable } from '../../components/TimeItem/styles.esm.js';

const DateSelection = ({ selectableDates, onDateSelect, onNextStep, onCalendarExpandClick, onLocationChange, onBackButtonClick, bookingInfo, expandedView, displayLocationDropdown, }) => {
    var _a, _b, _c, _d, _e;
    const [selectedDate, setSelectedDate] = useState();
    const [isExpanded, setIsExpanded] = useState(expandedView);
    const isDesktop = useIsDesktop();
    const firstAvailableDate = useMemo(() => getWithTimezoneOffset(new Date(selectableDates === null || selectableDates === void 0 ? void 0 : selectableDates[0])).toLocaleDateString(DATE_LOCALE, {
        weekday: 'short',
        day: 'numeric',
    }), [selectableDates]);
    const handleDateSelect = useCallback((date) => {
        setSelectedDate(date);
        onDateSelect === null || onDateSelect === void 0 ? void 0 : onDateSelect(date);
        onNextStep === null || onNextStep === void 0 ? void 0 : onNextStep();
    }, [onDateSelect, onNextStep]);
    const handleExpandClick = useCallback(() => {
        onCalendarExpandClick === null || onCalendarExpandClick === void 0 ? void 0 : onCalendarExpandClick();
        setIsExpanded((prev) => !prev);
    }, [onCalendarExpandClick]);
    return (React.createElement(StickyBottomContainer, { stickyComponent: React.createElement(StepFooter, Object.assign({}, bookingInfo, { dateString: selectedDate })) },
        React.createElement(Container, { style: { marginBottom: '24px' } },
            React.createElement(Title, null,
                React.createElement("div", { style: { cursor: 'pointer' }, onClick: onBackButtonClick },
                    React.createElement(ArrowBack, null)),
                React.createElement("span", null, "Select a date")),
            React.createElement(LocationDropdown, { disabled: !displayLocationDropdown, onClick: onLocationChange, label: (_b = (_a = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '', rating: (_d = (_c = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _c === void 0 ? void 0 : _c.rating) !== null && _d !== void 0 ? _d : 0, reviewsNumber: (_e = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _e === void 0 ? void 0 : _e.reviewsNumber }),
            React.createElement(FirstAvailableContainer, { style: { marginBottom: 10 } },
                React.createElement(TextItem, null,
                    React.createElement(FirstAvailable, null),
                    React.createElement("span", null,
                        "First available appointment: ",
                        React.createElement("b", null, firstAvailableDate))),
                isDesktop && onCalendarExpandClick ? (React.createElement(ExpandWrapper, { onClick: handleExpandClick },
                    React.createElement("span", null, isExpanded ? 'Minimize' : 'Expand'),
                    React.createElement(Expand, null))) : null),
            React.createElement(Calendar, { isExpanded: isExpanded, selectableDates: selectableDates, onDateSelect: handleDateSelect }))));
};

export { DateSelection as default };
