import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const InfoOutlinedIcon = (_a) => {
    var { fill } = _a, props = __rest(_a, ["fill"]);
    return (React__default.createElement(SvgIcon, Object.assign({ width: "16", height: "17", viewBox: "0 0 16 17" }, props),
        React__default.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M14 8.3999C14 11.7136 11.3137 14.3999 8 14.3999C4.68629 14.3999 2 11.7136 2 8.3999C2 5.08619 4.68629 2.3999 8 2.3999C11.3137 2.3999 14 5.08619 14 8.3999ZM15 8.3999C15 12.2659 11.866 15.3999 8 15.3999C4.13401 15.3999 1 12.2659 1 8.3999C1 4.53391 4.13401 1.3999 8 1.3999C11.866 1.3999 15 4.53391 15 8.3999ZM7.29999 11.8999H8.69999V7.6999H7.29999V11.8999ZM7.29999 6.2999H8.69999V4.8999H7.29999V6.2999Z", fill: fill || '#112971' })));
};

export { InfoOutlinedIcon as default };
