import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default, { useRef, useEffect } from 'react';
import laztLoadImages from '../../utils/lazyLoadImages.esm.js';
import styled from '../../node_modules/@mui/system/esm/styled.esm.js';

const SlotRoot = styled('span')(({ inline, theme }) => ({
    display: inline ? 'inline' : 'block',
    color: theme.palette.text.black900,
    fontSize: 14,
    b: {
        color: theme.palette.text.darkNavy,
        fontSize: '13.33px'
    }
}));
function HTMLSlot(_a) {
    var { children, className, inline, lazyImages } = _a, restProps = __rest(_a, ["children", "className", "inline", "lazyImages"]);
    const wrapper = useRef(null);
    useEffect(() => {
        if (!wrapper.current) {
            return;
        }
        if (lazyImages) {
            laztLoadImages(wrapper.current);
        }
    }, [children]);
    return children ? (React__default.createElement(SlotRoot, Object.assign({}, restProps, { inline: inline, ref: wrapper, className: className, dangerouslySetInnerHTML: { __html: children } }))) : null;
}
HTMLSlot.defaultProps = {
    inline: false,
    lazyImages: false,
    className: '',
    classes: {}
};

export { HTMLSlot as default };
