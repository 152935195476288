import {
  Avatar,
  Grid,
  LocalPhoneIcon,
  QuestionAnswerIcon,
  styledComponent,
  Typography,
  Box,
  CalendarIcon,
  useTheme,
  useMediaQuery,
  Rating,
  SxProps,
  Button,
  ExternalLinkIcon,
  WhiteTooltip
} from '@hermes/web-components'
import { calendarUtils } from '@hermes/booking-calendar'
import React, { PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { ProfileContext } from '../../../providers/AppData/Profile'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { RequestTypes } from '../../../constants/profile'
import { RevealLink } from '../../RevealLink'
import { ContactDescriptionProps, Event, SpecialistPracticeBookingCardProps } from './types'
import { AppDataContext } from '../../../providers/AppData'
import { LOCATION_MODAL_VARIANTS } from '../../../providers/BookingCalendar/types'
import PremiumBadge from '../../../components/Icons/PremiumBadge'
import { useWpaTenant } from '../../../hooks/useWpaTenant'
import getConfigVariable from '../../../utils/getConfigVariable'
import { useRouter } from 'next/router'

const { formatDateStringToDisplay, formatTimeStringToDisplay } = calendarUtils
const bookAppointmentRevealBook = 'bookAppointment.reveal.book'

const Root = styledComponent(Grid)(({ theme }) => ({
  padding: '16px 0',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.divider}`
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

export const RevealsContainer = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  width: '100%',
  marginTop: '16px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    '& > button, & > a': {
      flex: 1
    }
  }
}))

const clickHandler = (
  event: Event,
  name: string,
  profileType: string,
  targetName: string,
  targetUuid: string,
  uuid: string
) => {
  window.dataLayer.push({
    event,
    cont_prf_name: name,
    cont_prf_type: profileType,
    cont_enquired_at_name: targetName,
    cont_enquired_at_uuid: targetUuid,
    cont_prf_uuid: uuid
  })
}

const PremiumTooltip = ({ children }: { children: React.ReactNode }) => {
  const translate = useTranslateMessage()

  return (
    <WhiteTooltip title={translate('filter.premium.description')}>
      <Box sx={{ width: 'fit-content' }}>{children}</Box>
    </WhiteTooltip>
  )
}

export const ContactDescription = ({
  //todo: fix lint
  // eslint-disable-next-line no-unused-vars
  description,
  revealName,
  skipReveal,
  ...props
}: ContactDescriptionProps) => (
  <RevealLink {...props} skipReveal={skipReveal} newWindow captchaEnabled>
    {revealName}
  </RevealLink>
)

const StyledAvatar = styledComponent(Avatar)(({ theme }) => ({
  width: 82,
  height: 82,
  marginRight: '16px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[600]}`
}))

export const SpecialistPracticeBookingCardLayout = ({
  specialistPractice,
  bookingPossible,
  firstAvailableBooking,
  premium,
  children
}: PropsWithChildren<SpecialistPracticeBookingCardProps>) => {
  const { practice } = useContext(ProfileContext)
  const translate = useTranslateMessage()
  const { language } = useContext(AppDataContext)
  const { isWPA } = useWpaTenant()

  const { contactName, image, logo, overallExperience, reviewsTotal, address } = specialistPractice
  const profileAvatarAbbreviation = contactName?.[0].toUpperCase()

  const RatingAndReviewSection = ({ containerStyles }: { containerStyles?: SxProps }) => (
    <Grid container alignItems="center" columnSpacing={1} sx={{ marginLeft: 0, ...containerStyles }}>
      <Grid container width="auto">
        <Rating size="small" sx={{ fontSize: '15px' }} value={1} max={1} readOnly />
      </Grid>
      <Grid item sx={{ paddingLeft: '4px !important' }}>
        <Typography variant="body2" fontWeight="500">
          {overallExperience} Trust Score
        </Typography>
      </Grid>
      <span style={{ paddingLeft: '8px', paddingRight: '8px' }}>•</span>
      <Grid item sx={{ paddingLeft: '0px !important' }}>
        <Typography variant="body2" fontWeight="400" color="text.disabled">
          {reviewsTotal} {translate('reviews.reviews').toLowerCase()}
        </Typography>
      </Grid>
    </Grid>
  )

  return (
    <Root container direction="column" alignItems="center">
      {(!!specialistPractice || !!practice) && (
        <>
          <Grid container item direction="row" wrap="nowrap">
            <Grid item>
              <StyledAvatar src={image || logo}>{profileAvatarAbbreviation}</StyledAvatar>
            </Grid>
            <Grid container item direction="column" rowSpacing={1}>
              <Grid item mb="4px">
                <Typography fontSize="18px" fontWeight="600" lineHeight="22.4px" variant="h4" color="text.primary">
                  {contactName}
                </Typography>
              </Grid>

              {!!reviewsTotal && (
                <Grid container gap="4px" flexDirection="column">
                  {address && (
                    <Grid item>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                      >{`${address?.street1[language]}, ${address?.city[language]} ${address?.postcode[language]}`}</Typography>
                    </Grid>
                  )}
                  {premium && isWPA && (
                    <PremiumTooltip>
                      <PremiumBadge sx={{ width: '106px', height: '28px' }} />
                    </PremiumTooltip>
                  )}

                  {!bookingPossible && <RatingAndReviewSection />}
                </Grid>
              )}
            </Grid>
          </Grid>
          {bookingPossible && (
            <>
              <RatingAndReviewSection containerStyles={{ margin: '16px 0 8px 0' }} />
              <Box ml={'2px'} mr="auto" display="flex" gap="8px" alignItems="center">
                <Box width="16px" height="16px" bgcolor="#00D68B" borderRadius="50%" />
                <Typography color="#4C5870">Next available:</Typography>{' '}
                <Typography color="#11245A" fontWeight={600}>
                  {firstAvailableBooking}
                </Typography>
              </Box>
            </>
          )}
        </>
      )}

      <RevealsContainer>{children}</RevealsContainer>
    </Root>
  )
}

export const SpecialistPracticeContactDetails = ({
  specialistPractice,
  isSpecialist,
  activeButton,
  premium,
  bookingCalendarContext
}: SpecialistPracticeBookingCardProps) => {
  const { specialist } = useContext(ProfileContext)
  const translate = useTranslateMessage()
  const theme = useTheme()
  const { locale } = useRouter()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isWPA } = useWpaTenant()

  const {
    profileName,
    contactName,
    phone,
    email,
    externalBookingLink,
    externalId,
    practiceExternalId,
    id,
    slug,
    bookingSlots
  } = specialistPractice

  const onClickHandler = (event: Event) => () => {
    clickHandler(
      event,
      profileName,
      isSpecialist ? 'Specialist' : 'Practice',
      contactName as string,
      practiceExternalId as string,
      externalId
    )
  }

  const {
    isBookingAvailable,
    closeProfileSelectionModal,
    handlePracticeChange,
    firstAvailableBooking,
    shouldOmitPractice
  } = useMemo(() => {
    const stage = getConfigVariable('STAGE')
    const practiceSlots = bookingCalendarContext?.getPracticeSlots?.(specialistPractice?.id as number) || bookingSlots
    const firstAvailable = practiceSlots?.[0]
    return {
      isBookingAvailable:
        (stage !== 'production' || (stage === 'production' && locale === 'wpa')) && !!practiceSlots?.length,
      isBookingCalendarSource:
        bookingCalendarContext?.locationModalVariant === LOCATION_MODAL_VARIANTS.PROFILE_SELECTION,
      closeProfileSelectionModal: bookingCalendarContext?.closeProfileSelectionModal ?? (() => null),
      handlePracticeChange: () => bookingCalendarContext?.handlePracticeChange(specialistPractice?.id as number),
      firstAvailableBooking: `${formatDateStringToDisplay(firstAvailable?.date)} • ${formatTimeStringToDisplay(
        firstAvailable?.time
      )}`,
      shouldOmitPractice:
        !practiceSlots?.length &&
        bookingCalendarContext?.locationModalVariant === LOCATION_MODAL_VARIANTS.PROFILE_SELECTION
    }
  }, [bookingCalendarContext, bookingSlots, locale, specialistPractice?.id])

  const handleBookPractice = useCallback(() => {
    handlePracticeChange()
    closeProfileSelectionModal()
  }, [handlePracticeChange, closeProfileSelectionModal])

  const revealNameForBookExternal = useMemo(() => {
    if (isWPA) {
      return `${translate(bookAppointmentRevealBook)} via ${translate('bookAppointment.reveal.website')}`
    }

    return translate(bookAppointmentRevealBook)
  }, [translate, isWPA])

  if (shouldOmitPractice) {
    return null
  }

  const emailHref = isMobile ? `mailto:${email}?subject=${translate('email.subject')}` : undefined

  return (
    <>
      {!!(phone?.length || email?.length || externalBookingLink?.length || isBookingAvailable) && (
        <SpecialistPracticeBookingCardLayout
          bookingPossible={isBookingAvailable}
          isSpecialist={isSpecialist}
          premium={premium}
          specialistPractice={specialistPractice}
          firstAvailableBooking={firstAvailableBooking}
        >
          {isBookingAvailable ? (
            <Button
              sx={{ width: '100%', display: 'flex', flex: 1, gap: '4px', fontWeight: 500, borderRadius: '24px' }}
              variant="contained"
              onClick={handleBookPractice}
            >
              <div style={{ display: 'flex', gap: '8px' }}>
                <CalendarIcon style={{ width: '20px', height: '20px' }} /> Book this practice
              </div>
            </Button>
          ) : (
            <>
              {!!phone && (
                <ContactDescription
                  contactValue={phone as string}
                  active={activeButton === 'call' || activeButton === 'all'}
                  description={translate('phone_no')}
                  href={`tel:${phone}`}
                  specialistId={specialist?.id}
                  practiceId={id}
                  revealName={isMobile ? translate('call') : phone}
                  skipReveal={isMobile}
                  slug={slug}
                  icon={<LocalPhoneIcon />}
                  revealTitle={isMobile ? translate('call') : translate('bookAppointment.reveal.showPhoneNumber')}
                  requestType={RequestTypes.PHONE}
                  onClickRevealHandler={onClickHandler('prf_pg_cont_popup_reveal_tel')}
                  onClickLinkHandler={
                    isMobile
                      ? onClickHandler('prf_pg_cont_popup_reveal_tel')
                      : onClickHandler('prf_pg_cont_popup_tel_link')
                  }
                />
              )}
              {!!email && (
                <ContactDescription
                  contactValue={email as string}
                  active={activeButton === 'email' || activeButton === 'all'}
                  description={translate('email')}
                  href={emailHref}
                  specialistId={specialist?.id}
                  practiceId={id}
                  revealName={isMobile ? translate('email') : email}
                  slug={slug}
                  skipReveal={isMobile}
                  revealLink={emailHref}
                  icon={<QuestionAnswerIcon />}
                  revealTitle={translate('bookAppointment.reveal.email')}
                  requestType={RequestTypes.EMAIL}
                  onClickLinkHandler={() => {
                    !isMobile && navigator.clipboard.writeText(email)
                    onClickHandler('prf_pg_cont_popup_reveal_email')
                  }}
                  onClickRevealHandler={onClickHandler('prf_pg_cont_popup_reveal_email')}
                />
              )}
              {!!externalBookingLink && (
                <ContactDescription
                  contactValue={externalBookingLink}
                  active={activeButton === 'book'}
                  description={translate('profile.booking_external_link')}
                  /*revealName={`Book via ${specialistPractice.contactName}`}*/
                  revealName={revealNameForBookExternal}
                  href={externalBookingLink}
                  icon={<ExternalLinkIcon />}
                  requestType={RequestTypes.EXTERNAL_BOOKING_LINK}
                  revealTitle={translate(bookAppointmentRevealBook)}
                  skipReveal
                  slug={slug}
                  specialistId={specialist?.id}
                  practiceId={id}
                  primaryRevealStyle
                  onClickLinkHandler={onClickHandler('prf_pg_book_popup_booking_link')}
                />
              )}
            </>
          )}
        </SpecialistPracticeBookingCardLayout>
      )}
    </>
  )
}
