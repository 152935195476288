import React from 'react';
import { Container } from './styles.esm.js';
import BookingInfo from '../BookingInfo/index.esm.js';
import { formatDateStringToDisplay, formatTimeStringToDisplay } from '../../components/Calendar/utils.esm.js';

const StepFooter = ({ specialistProfile, practiceProfile, dateString, timeString, }) => {
    return (React.createElement(Container, null,
        React.createElement(BookingInfo, { specialistProfile: specialistProfile, practiceProfile: practiceProfile, dateString: formatDateStringToDisplay(dateString), timeString: formatTimeStringToDisplay(timeString) })));
};

export { StepFooter as default };
