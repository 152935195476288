import { useState, useEffect } from 'react';

const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 955px)').matches);
    useEffect(() => {
        const mediaQuery = window.matchMedia('(min-width: 955px)');
        const handleChange = () => setIsDesktop(mediaQuery.matches);
        mediaQuery.addEventListener('change', handleChange);
        return () => {
            mediaQuery.removeEventListener('change', handleChange);
        };
    }, []);
    return isDesktop;
};

export { useIsDesktop as default };
