import { Box, Button, styledComponent, Typography, Grid } from '@hermes/web-components'
import Avatar from '../../Avatar'

export const StyledPlayVideoButton = styledComponent(Button)({
  position: 'absolute',
  bottom: '6px',
  left: '6px',
  padding: 0,
  zIndex: 2,
  minWidth: 'auto',
  ':hover': {
    svg: {
      background:
        'linear-gradient(37deg, rgba(43, 89, 224, 0.70) -4.44%, rgba(0, 229, 208, 0.70) 86.38%, rgba(0, 229, 208, 0.42) 86.38%, rgba(13, 187, 213, 0.70) 86.38%, rgba(0, 229, 208, 0.70) 86.38%), #2B59E0 !important'
    }
  }
})

export const StyledTypographyAvatar = styledComponent(Typography)(({ theme }) => ({
  fontSize: 96,
  color: '#11245A',
  [theme.breakpoints.down('sm')]: {
    fontSize: 48
  }
}))

export const TextBox = styledComponent(Box)(({ theme }) => ({
  width: 'max-content',
  backgroundColor: theme.palette.common.white,
  borderRadius: 16,
  display: 'flex',
  justifyContent: 'space-around'
}))

export const TopKeywordText = styledComponent(Typography)(
  ({ theme }) => theme.custom.ProfileIntro.profileKeywordTypography
)

export const IntroTextInformation = styledComponent(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  lineHeight: '20px'
}))

export const StyledAvatarContainer = styledComponent(Grid)(({ theme }) => ({
  width: '192px',
  height: '192px',
  [theme.breakpoints.down('sm')]: {
    width: '104px'
  }
}))

export const StyledAvatar = styledComponent(Avatar)(({ theme }) => ({
  width: '192px',
  height: '192px',
  [theme.breakpoints.down('sm')]: {
    width: '104px',
    height: '104px'
  }
}))

export const ProfileDetailsContainer = styledComponent(Grid)(({ theme }) => ({
  gap: theme.custom.SpecialistPage?.intro?.gap,
  marginTop: theme.custom.SpecialistPage?.intro?.mt,
  [theme.breakpoints.down('sm')]: {
    gap: '8px',
    marginTop: 0
  }
}))
