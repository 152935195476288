import getAddressSchema from './address'
import { getAddressFromPractices, removeVideoConsultation, transformAddressToString } from '../address'
import { capitalise } from '../strings'
import { SpecialistProfile } from '../../types/specialist'
import getImageFromNextImage from '../getImageFromNextImage'
import getConsultationFeesString from '../getConsultationFeesString'
import { currencySymbol } from '../price'

// todo: fix complexity
// eslint-disable-next-line complexity
export default function getSpecialistSchema(
  specialist: SpecialistProfile,
  lang: string,
  locale: string,
  translate: (key: string, options?: Record<string, string | number | boolean> | undefined) => string,
  visibilityComponents: any,
  isRtl?: boolean
) {
  const idSchema = `https://www.doctify.com/${locale}/specialist/${specialist.slug}`

  const isEntry = specialist.basic || specialist.plan.includes('unclaimed')

  const topLevelKeyword = specialist?.keywords.filter(
    (keyword) => keyword.isTopLevelChild && keyword.keywordType === 'specialty'
  )?.[0]
  const subSpecialties = specialist?.keywords.filter(
    (keyword) => !keyword.isTopLevelChild && keyword.keywordType === 'specialty'
  )

  const nameSchema = [specialist.title, specialist.fullName]
    .map((i) => i?.[lang])
    .filter((i) => i?.length > 1)
    .join(' ')

  const mainPractice = specialist?.practices?.filter(removeVideoConsultation)[0]
  const addressToTransform = getAddressFromPractices(specialist?.practices)
  const mainPracticeAddress = addressToTransform ? transformAddressToString(addressToTransform, lang) : ''
  const specialtiesString = subSpecialties.map((subSpec) => subSpec?.name?.[lang])?.join(', ') || ''
  const aboutSchema = ['uk', 'en-ae', 'wpa'].includes(locale)
    ? `${nameSchema} offers a range of ${specialtiesString} treatments and video consultations in ${mainPracticeAddress}. Read reviews and book online now.`
    : (specialist.about?.[lang] || '')
        .replace(/<\/?[^>]+(>|$)/g, '')
        .replace(/&rsquo;/g, '’')
        .replace(/&nbsp;/g, ' ')

  const hospitalAffiliationSchema = specialist.practices
    .filter(removeVideoConsultation)
    .filter((p) => !!p.images?.logo)
    .map((practice) => {
      const practiceUrl = `https://www.doctify.com/${locale}/practice/${practice.slug}`

      return {
        name: practice.name[lang],
        '@type': 'Hospital',
        url: practiceUrl,
        sameAs: practiceUrl,
        address: getAddressSchema(practice.address, lang),
        telephone: practice.ContactDetails.filter((cd) => ['public', 'singlePointPublic'].includes(cd.type) && cd.phone)
          .map((cd) => cd.phone)
          .join(', ')
      }
    })

  const memberOfSchema = specialist.insurers?.map((insurer) => ({
    name: insurer.name,
    '@type': 'ProgramMembership',
    url: insurer.website,
    sameAs: insurer.website
  }))

  const aggregateRatingSchema =
    specialist.reviewsTotal > 0
      ? {
          '@type': 'AggregateRating',
          '@id': idSchema,
          identifier: idSchema,
          bestRating: 5,
          worstRating: 1,
          reviewCount: specialist.reviewsTotal,
          ratingValue: specialist.averageRating
        }
      : null

  const potentialActionSchema = {
    '@type': 'reserveAction',
    target: {
      '@type': 'EntryPoint',
      urlTemplate: idSchema,
      actionPlatform: [
        'http://schema.org/DesktopWebPlatform',
        'http://schema.org/IOSPlatform',
        'http://schema.org/AndroidPlatform'
      ],
      inLanguage: locale
    },
    result: {
      '@type': 'Reservation',
      name: translate('book_appointment')
    }
  }

  const practicesAddressesSchema = specialist.practices
    .filter(removeVideoConsultation)
    .map((practice) => getAddressSchema(practice.address, lang))

  const practicesGeoSchema = specialist.practices
    .filter(removeVideoConsultation)
    .map((practice) => {
      const { lat, lon } = practice.address?.geolocation || {}
      return lat && lon
        ? {
            '@type': 'GeoCoordinates',
            latitude: lat,
            longitude: lon
          }
        : null
    })
    .filter(Boolean)

  const parseFee = (fee?: string) => {
    if (!fee) {
      return
    }

    const normalizedFee = String(fee).replace(',', '.')

    if (normalizedFee.includes('-')) {
      return parseFloat(normalizedFee.split('-')[0])
    }

    return parseFloat(normalizedFee)
  }

  const { new: newFee, followUp, currency } = specialist.consultationFees || {}
  const parsedNewFee = parseFee(newFee as string)
  const parsedFollowUpFee = parseFee(followUp as string)

  const currentCurrencySymbol = currencySymbol(specialist.consultationFees?.currency || '', locale)

  const feesString = getConsultationFeesString({
    currencySymbol: currentCurrencySymbol,
    isRtl,
    followUpFees: parsedFollowUpFee,
    newFees: parsedNewFee
  })

  const isShowFeesSchema = !!currency && !isEntry && (!!newFee || !!followUp)

  const feesSchema = (
    isShowFeesSchema
      ? [
          newFee
            ? {
                price: newFee,
                priceCurrency: currency,
                name: translate('profile.new_appointment'),
                description: translate('profile.new_appointment')
              }
            : null,
          followUp
            ? {
                price: followUp,
                priceCurrency: currency,
                name: translate('profile.follow_up_appointemnt'),
                description: translate('profile.follow_up_appointemnt')
              }
            : null
        ]
      : []
  ).filter(Boolean)

  const subSpecialtiesSchema = subSpecialties.map((subSpec) => ({
    price: 'N/A',
    '@type': 'Offer',
    name: subSpec.name?.[lang],
    url: idSchema,
    description: `${subSpec.name?.[lang]} Specialist`,
    itemOffered: {
      '@type': 'Service',
      url: idSchema
    }
  }))

  const makesOffer = [feesSchema, ...subSpecialtiesSchema].filter(Boolean)

  const faqQuestions = [
    visibilityComponents.faqQuestions.faqQuestionReviews
      ? {
          '@type': 'Question',
          name: translate('profile.question_reviews', { name: nameSchema, skipBold: true }),
          acceptedAnswer: {
            '@type': 'Answer',
            text: translate('profile.answer_reviews', {
              name: nameSchema,
              rating: specialist.averageRating
            })
          }
        }
      : null,
    visibilityComponents.faqQuestions.faqQuestionLanguages
      ? {
          '@type': 'Question',
          name: translate('profile.question_languages', { name: nameSchema, skipBold: true }),
          acceptedAnswer: {
            '@type': 'Answer',
            text: translate(
              specialist.languages.length > 1 ? 'profile.answer_languages_multiple' : 'profile.answer_languages',
              {
                name: nameSchema,
                language: specialist.languages
                  .slice(
                    0,
                    specialist.languages.length > 1 ? specialist.languages.length - 1 : specialist.languages.length
                  )
                  .map((lng) => capitalise(lng.name))
                  .join(', '),
                lastLanguage: capitalise(specialist.languages[specialist.languages.length - 1]?.name)
              }
            )
          }
        }
      : null,
    visibilityComponents.faqQuestions.faqQuestionWhereLocated && {
      '@type': 'Question',
      name: translate('profile.question_where_located', { name: nameSchema, skipBold: true }),
      acceptedAnswer: {
        '@type': 'Answer',
        text: translate('profile.practices_located_at', {
          name: nameSchema,
          practice: mainPractice?.name?.[lang],
          address: transformAddressToString(mainPractice?.address, lang)
        })
      }
    },
    visibilityComponents.faqQuestions.faqQuestionAcceptVisits && {
      '@type': 'Question',
      name: translate('profile.question_accept_visits', { name: nameSchema, skipBold: true }),
      acceptedAnswer: {
        '@type': 'Answer',
        text: [
          translate('profile.answer_accept_visits', {
            name: nameSchema
          }),
          translate('get_in_touch'),
          translate('profile.short_enquire_as_a_new_patient_specialist')
        ].join(' ')
      }
    }
  ]

  const faqSchema = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqQuestions.filter(Boolean)
  }

  return JSON.stringify([
    {
      '@type': 'Physician',
      '@context': 'http://schema.org',
      '@id': idSchema,
      url: idSchema,
      name: nameSchema,
      description: aboutSchema,
      image: getImageFromNextImage(specialist.images?.logo),
      telephone: specialist.phones?.join(',') || '',
      hospitalAffiliation: hospitalAffiliationSchema,
      memberOf: memberOfSchema,
      makesOffer,
      potentialAction: potentialActionSchema,
      address: practicesAddressesSchema,
      geo: practicesGeoSchema,
      medicalSpecialty: topLevelKeyword?.name?.[lang],
      ...(!specialist.basic && specialist.reviewsTotal > 0 ? { aggregateRating: aggregateRatingSchema } : {}),
      ...(!feesString.includes('NaN') ? { priceRange: feesString } : {})
    },
    ...(!visibilityComponents.faq ? [] : [faqSchema])
  ])
}
