import React from 'react'
import { Breadcrumbs as HermesBreadcrumbs, Link, Typography } from '@hermes/web-components'
import ChevronRightIcon from '../Icons/ChevronRightIcon'
import useTranslateMessage from '../../hooks/useTranslateMessage'

interface BreadcrumbsProps {
  items: { label: string; url: string }[]
}

function Breadcrumbs({ items }: BreadcrumbsProps) {
  const translate = useTranslateMessage()

  return (
    <HermesBreadcrumbs
      aria-label="breadcrumbs"
      sx={{ '.MuiBreadcrumbs-ol': { rowGap: '12px' } }}
      separator={<ChevronRightIcon reflectIfRtl fontSize="small" />}
    >
      {items.map((item) => {
        let { label } = item
        if (item.label.includes('***')) {
          const splitedBreadcrum = item.label.split('***')
          label = translate('breadcrumbs_in_label', { keyword: splitedBreadcrum[0], city: splitedBreadcrum[1] })
        }
        return (
          <Link
            key={`breadcrumb-${item.label}-${item.url}`}
            href={item.url}
            color="inherit"
            sx={{ textDecoration: 'none' }}
          >
            <Typography variant="body2" color="primary.textBlack700">
              {decodeURI(label)}
            </Typography>
          </Link>
        )
      })}
    </HermesBreadcrumbs>
  )
}

export default Breadcrumbs
