import React__default from 'react';
import SvgIcon from '../../node_modules/@mui/material/SvgIcon/SvgIcon.esm.js';

const ExternalLinkIcon = (props) => (React__default.createElement(SvgIcon, Object.assign({ fontSize: "inherit", viewBox: "0 0 18 17" }, props),
    React__default.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 20, height: 19, fill: "none" },
        React__default.createElement("rect", { width: 10.595, height: 9.674, x: 3.824, y: 4.921, fill: "#fff", fillOpacity: 0.3, rx: 1 }),
        React__default.createElement("path", { fill: "#fff", fillRule: "evenodd", d: "M10.588 3.142a.533.533 0 0 0-.4.142.473.473 0 0 0 0 .694.532.532 0 0 0 .373.144v.002l4.107.003-4.462 4.227a.474.474 0 0 0-.013.708.54.54 0 0 0 .736 0l4.474-4.238.003 3.89h.002a.476.476 0 0 0 .151.363c.202.192.53.192.733 0a.476.476 0 0 0 .151-.365v-5.57h-5.855Z", clipRule: "evenodd" }),
        React__default.createElement("path", { fill: "#fff", d: "m10.188 3.284.109.104-.11-.104Zm.4-.142-.01.146h.01v-.146Zm-.027.98h.154v-.148l-.156.002.002.146Zm0 .002h-.154v.146h.154v-.146Zm4.107.003.108.103.263-.248h-.371v.145Zm-4.462 4.227.106.106.003-.003-.109-.103Zm.723.708.109-.103-.11-.103-.108.103.109.103Zm0 0-.109.103.109.103.109-.103-.109-.103Zm4.474-4.238h.154v-.352l-.263.249.109.103Zm.003 3.89h-.154v.146h.154v-.146Zm.002 0 .154.005.005-.151h-.16v.146Zm1.035-.002h-.154v.005l.154-.005Zm0-5.57h.154v-.146h-.154v.146Zm-6.146.246a.374.374 0 0 1 .281-.1l.02-.292a.692.692 0 0 0-.52.185l.219.207Zm0 .487a.332.332 0 0 1 0-.487l-.218-.207a.613.613 0 0 0 0 .9l.218-.206Zm.262.101a.373.373 0 0 1-.262-.1l-.218.206a.69.69 0 0 0 .485.186l-.005-.292Zm.156.148v-.002h-.308v.002h.308Zm3.953-.143-4.106-.003v.292l4.105.003v-.292Zm-4.353 4.476 4.461-4.227-.217-.206-4.461 4.226.217.207Zm-.012.012a.567.567 0 0 1 .008-.009l-.21-.213a.562.562 0 0 0-.016.015l.217.207Zm0 .49a.334.334 0 0 1 0-.49l-.218-.207a.615.615 0 0 0 0 .903l.217-.206Zm.517 0a.38.38 0 0 1-.518 0l-.217.206a.7.7 0 0 0 .953 0l-.218-.206Zm.218 0-.218.206.218-.206Zm4.256-4.238L10.82 8.959l.218.206 4.474-4.238-.218-.206Zm.266 3.993-.003-3.89h-.308l.003 3.89h.308Zm-.152-.146h-.002v.292h.002v-.292Zm.26.406a.334.334 0 0 1-.106-.255l-.308-.01c-.006.17.06.342.196.471l.218-.206Zm.515 0a.378.378 0 0 1-.515 0l-.218.206a.699.699 0 0 0 .95 0l-.217-.206Zm.106-.257a.334.334 0 0 1-.106.257l.218.206a.617.617 0 0 0 .196-.473l-.308.01Zm0-5.575v5.57h.308v-5.57h-.308Zm-5.7.146h5.854v-.292h-5.855v.292Z" }),
        React__default.createElement("path", { stroke: "#fff", strokeLinecap: "round", strokeWidth: 1.5, d: "M6.586 4.957H5.04a1.2 1.2 0 0 0-1.2 1.2v7.588a1.2 1.2 0 0 0 1.2 1.2h8.586a1.2 1.2 0 0 0 1.2-1.2v-1.836" }))));

export { ExternalLinkIcon as default };
