import { Typography, Chip, useTheme } from '@hermes/web-components'
import { useRouter } from 'next/router'
import {
  AvatarWrapper,
  GetToKnowWrapper,
  HeaderSpecialistInfo,
  MediaCenterHeader,
  MediaContentWrapper,
  MediaHeader,
  NavigationButtonsWrapper
} from './styles'
import { getInitialsFromFullName } from '../../Intro'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import Avatar from '../../../Avatar'
import getImageWithWWW from '../../../../utils/getImageWithWWW'

export interface ProfileMediaHeaderProps {
  isBasic: boolean
  isUnclaimed: boolean
  name: string
  image: string
  socialsAvailable: boolean
  videosAvailable: boolean
  articlesAvailable: boolean
  websiteLinkAvailable: boolean
}

const ProfileMediaHeader = ({
  isBasic = false,
  isUnclaimed = false,
  image,
  name,
  socialsAvailable,
  videosAvailable,
  articlesAvailable,
  websiteLinkAvailable
}: ProfileMediaHeaderProps) => {
  const initials = getInitialsFromFullName(name)
  const translate = useTranslateMessage()
  const theme = useTheme()
  const router = useRouter()
  const pathname = `/${router.locale}${router.pathname.replace('[slug]', String(router.query.slug))}`
  const imageWithWWW = getImageWithWWW(image)

  return (
    <MediaCenterHeader>
      <MediaContentWrapper>
        <MediaHeader container gap="15px" wrap="nowrap">
          <HeaderSpecialistInfo container wrap="nowrap" gap="15px">
            <AvatarWrapper item>
              <Avatar
                width={84}
                height={84}
                src={!isBasic && !isUnclaimed ? imageWithWWW : undefined}
                sx={{ backgroundColor: 'blue.200' }}
                alt={`${name} logo`}
              >
                <Typography variant="h2">{initials}</Typography>
              </Avatar>
            </AvatarWrapper>
            <GetToKnowWrapper component="h2" nameLength={name.length} item>
              <Typography component="span" className="media-centre-title">
                {translate('media_center.title') + ' '}
              </Typography>
              <Typography
                sx={{
                  wordBreak: name.includes(' ') ? 'normal' : 'break-all'
                }}
                component="span"
                className="media-centre-profile-name"
              >
                {name}
              </Typography>
            </GetToKnowWrapper>
          </HeaderSpecialistInfo>
          {([videosAvailable, articlesAvailable, socialsAvailable].filter((x) => x).length > 1 ||
            !websiteLinkAvailable) && (
            <NavigationButtonsWrapper item>
              {videosAvailable && (
                <a href={`${pathname}#videos`} style={{ textDecoration: 'none' }}>
                  <Chip variant="blue800" label={translate('media_center.videos')} clickable />
                </a>
              )}
              {articlesAvailable && (
                <a href={`${pathname}#articles`} style={{ textDecoration: 'none' }}>
                  <Chip
                    variant="aqua800"
                    label={translate('media_center.articles')}
                    sx={theme.custom.SpecialistPage.profileMediaHeaderChips}
                    clickable
                  />
                </a>
              )}
              {socialsAvailable && (
                <a href={`${pathname}#socials`} style={{ textDecoration: 'none' }}>
                  <Chip
                    variant="coralPrimary"
                    label={translate('media_center.socials')}
                    sx={theme.custom.SpecialistPage.profileMediaHeaderChips}
                    clickable
                  />
                </a>
              )}
            </NavigationButtonsWrapper>
          )}
        </MediaHeader>
      </MediaContentWrapper>
    </MediaCenterHeader>
  )
}

export default ProfileMediaHeader
