import React from 'react';

const CalendarStyled = () => {
    return (React.createElement("svg", { width: "76", height: "71", viewBox: "0 0 76 71", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("rect", { x: "2.9375", y: "6.93945", width: "70.125", height: "61.875", rx: "8", fill: "#B4FFFB" }),
        React.createElement("rect", { x: "2.9375", y: "6.93945", width: "70.125", height: "61.875", rx: "8", fill: "url(#paint0_linear_10322_35454)", fillOpacity: "0.7" }),
        React.createElement("rect", { x: "2.9375", y: "6.93945", width: "70.125", height: "61.875", rx: "8", stroke: "#1A3586", strokeWidth: "4" }),
        React.createElement("path", { d: "M2.9375 23.4399H73.0625", stroke: "#1A3586", strokeWidth: "4", strokeLinecap: "round" }),
        React.createElement("path", { d: "M23.5629 2.81445L23.5629 11.0645", stroke: "#1A3586", strokeWidth: "4", strokeLinecap: "round" }),
        React.createElement("path", { d: "M52.4382 2.81445L52.4382 11.0645", stroke: "#1A3586", strokeWidth: "4", strokeLinecap: "round" }),
        React.createElement("path", { d: "M35.6626 68.5401C65.5826 68.5401 73.0626 43.6068 73.0626 31.1401V60.5401C73.0626 64.9584 69.4809 68.5401 65.0626 68.5401H35.6626Z", fill: "#00897D", stroke: "#1A3586", strokeWidth: "4", strokeLinecap: "round" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_10322_35454", x1: "2.9375", y1: "37.877", x2: "73.0625", y2: "37.877", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#B4FFFB" }),
                React.createElement("stop", { offset: "0.9999", stopColor: "#00E5D0" }),
                React.createElement("stop", { offset: "1", stopColor: "#00E5D0" })))));
};

export { CalendarStyled as default };
