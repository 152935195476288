import React from 'react';
import { Container, FirstAvailable, ValueWrapper } from './styles.esm.js';

const TimeItem = ({ sx, value, firstAvailable, selected, disabled, onClick, }) => {
    return (React.createElement(Container, { sx: sx, onClick: onClick, disabled: disabled, selected: selected },
        firstAvailable && !selected ? React.createElement(FirstAvailable, null) : null,
        React.createElement(ValueWrapper, null, value)));
};

export { TimeItem as default };
