import { useEffect, useRef } from 'react'

const DATA_PROTECTION_ACCEPTED = 'data-protection-accepted'

const DataProtectionBanner = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const onLoad = (frame: HTMLIFrameElement) => {
      const dataProtectionIsAccepted = JSON.parse(localStorage.getItem(DATA_PROTECTION_ACCEPTED) || 'false')

      if (!dataProtectionIsAccepted) {
        frame.contentWindow?.postMessage({ type: 'trigger-modal-open', modal: 'data-protection-modal' }, '*')
      }

      window.__tcfapi('addEventListener', 2, (event) => {
        if (event.eventStatus === 'useractioncomplete' && event.gdprApplies) {
          window.localStorage.setItem(DATA_PROTECTION_ACCEPTED, 'false')
          frame.contentWindow?.postMessage({ type: 'trigger-modal-open', modal: 'data-protection-modal' }, '*')
        }
      })

      window.addEventListener('message', function(event) {
        if (event.data.type === 'display-iframe_modals-widget') {
          frame.style.display = 'block'
        }

        if (event.data.type === 'hide-iframe_modals-widget') {
          localStorage.setItem(DATA_PROTECTION_ACCEPTED, 'true')
          frame.style.display = 'none'
        }
      })
    }

    if (iframeRef.current) {
      onLoad(iframeRef.current)

      iframeRef.current.addEventListener('load', () => {
        onLoad(iframeRef.current!)
      })
    }
  }, [iframeRef])

  return (
    <iframe
      ref={iframeRef}
      title="modals-widget"
      style={{
        display: 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 9999,
        transition: 'opacity 0.3s',
        border: 0
      }}
      src="https://www.doctify.com/wv2/modals?tenantId=athena-at&amp;widgetname=modals-widget"
    />
  )
}

export default DataProtectionBanner
