import React, { useMemo } from 'react'
import { TEST_IDS } from '../../__tests__/testUtils/constants'
import { Grid, Hidden, useMediaQuery, useTheme } from '@hermes/web-components'
import NextImage from 'next/image'
import getConfigVariable from '../../utils/getConfigVariable'

type WpaHeaderProps = {
  shouldReverse?: boolean
}

const WpaHeader: React.FC<WpaHeaderProps> = ({ shouldReverse }) => {
  const imageSrc = useMemo(() => `/hermes-static/wpa-logo-${shouldReverse ? 'navy' : 'light'}.svg`, [shouldReverse])
  const stage = getConfigVariable('STAGE')
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const getHomeLink = () => {
    const suffix = stage === 'production' ? '' : `-${stage || 'development'}`
    return `https://healthcarefinder${suffix}.doctify.com/wpa`
  }

  const handleClickImage = () => {
    location.href = getHomeLink()
  }

  const handleLogoStyle = () => {
    if (shouldReverse) {
      return 'flex-end'
    }
    return isMobile ? 'center' : 'flex-start'
  }

  return (
    <Grid
      container
      data-testid={TEST_IDS.HEADER_FIND_PAGE}
      sx={{
        backgroundColor: shouldReverse ? '#fff' : '#142F76',
        height: { sm: 64, xs: 48 },
        pl: { sm: 2.5, xs: 0 },
        pr: 2,
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 1100,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: 'common.white'
      }}
      alignItems="center"
      alignContent="center"
      justifyContent={handleLogoStyle()}
    >
      <Grid container component={Hidden} implementation="css" mdUp width="auto">
        <NextImage
          src={imageSrc}
          alt="Doctify logo"
          onClick={handleClickImage}
          width={400}
          height={27}
          objectFit="contain"
          style={{ cursor: 'pointer' }}
          layout="fixed"
          priority
        />
      </Grid>
      <Grid container component={Hidden} mdDown width="auto">
        <NextImage
          src={imageSrc}
          alt="Doctify logo"
          width={400}
          onClick={handleClickImage}
          height={40}
          objectFit="contain"
          style={{ cursor: 'pointer' }}
          layout="fixed"
          priority
        />
      </Grid>
    </Grid>
  )
}

export default WpaHeader
