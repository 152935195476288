import { Container, styledComponent } from '@hermes/web-components'

const ContentTopContainer = styledComponent(Container, {
  shouldForwardProp: (prop: string) => !['withKeywords', 'isWPA'].includes(prop)
})<{ withKeywords?: boolean, isWPA?: boolean }>(({ theme, withKeywords = false, isWPA = false }) => ({
  paddingLeft: `${theme.spacing(5)}`,
  paddingRight: `${theme.spacing(5)}`,
  marginTop: isWPA ? '16px' : 0,
  maxWidth: theme.breakpoints.values.xl,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  [theme.breakpoints.down('xl')]: {
    paddingLeft: `${theme.spacing(4)}`,
    paddingRight: `${theme.spacing(4)}`
  },
  [theme.breakpoints.down('lg')]: {
    boxSizing: 'border-box',
    maxWidth: theme.breakpoints.values.sm,
    paddingLeft: `${theme.spacing(0)}`,
    paddingRight: `${theme.spacing(0)}`
  },
  [theme.breakpoints.down('md')]: {
    marginTop: withKeywords || isWPA ? 0 : '16px',
    paddingLeft: `${theme.spacing(2)}`,
    paddingRight: `${theme.spacing(2)}`
  }
}))

export default ContentTopContainer
