import { useContext, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useTheme, useMediaQuery } from '@hermes/web-components'
import { useSpecialistPracticesContacts } from './useSpecialistPractices'
import { useVisibilityComponents } from './useVisibilityComponents'
import { useTopLevelKeywords } from './useKeywordsMapper'
import useTranslateMessage from './useTranslateMessage'
import { ProfileKeyword, SpecialistProfile } from '../types'
import { Breadcrumb } from '../types/breadcrumbs'
import { BookingSlot } from '../types/booking'
import { capitalise } from '../utils/strings'
import useBreadCrumbsSchema from './useBreadcrumbsSchema'
import { AppDataContext } from '../providers/AppData'
import useStatistic from './useStatistic'
import useSpecialistSchema from './useSpecialistSchema'
import { useGetContactsData } from './useGetProfileData'

type SpecialistPageConfigHookProps = {
  specialist: SpecialistProfile
  bookingSlots: BookingSlot[]
  totalReviews: number
  topKeyword?: ProfileKeyword
  topLocation?: {
    label: string
    slug: string
  }
  isUnclaimed: boolean
  breadcrumbs?: Breadcrumb[]
}

export const useSpecialistPageConfig = ({
  specialist,
  breadcrumbs,
  isUnclaimed,
  topKeyword,
  topLocation,
  bookingSlots,
  totalReviews
}: SpecialistPageConfigHookProps) => {
  const router = useRouter()
  const { language, isRtl, locale } = useContext(AppDataContext)
  const theme = useTheme()
  const specSchema = useSpecialistSchema(specialist)
  const translate = useTranslateMessage()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isLaptop = useMediaQuery(theme.breakpoints.down('lg'))

  const profileVideo = specialist.media?.filter((media) => media.type === 'profile' && media.enabled)[0]
  const profileName = useMemo(() => {
    let profileName = ''
    if (specialist.title[language]) {
      profileName = specialist.title[language] + ' '
    }
    return profileName + specialist.fullName[language]
  }, [language, specialist.fullName, specialist.title])

  const visibilityComponents = useVisibilityComponents({ specialist })
  const introVideoAvailable = useMemo(
    () => Boolean(!isUnclaimed && visibilityComponents.introductionVideoAvailable && profileVideo),
    [isUnclaimed, profileVideo, visibilityComponents.introductionVideoAvailable]
  )
  const publications = specialist.media?.filter((media) => media.type === 'publication' && media.enabled)
  const specialistSuffix = specialist.title[language]?.length > 1 ? specialist.title[language] : undefined
  const contacts = useGetContactsData({ specialistData: specialist })
  const mappedBreadcrumbs = breadcrumbs?.length
    ? [
        ...breadcrumbs.slice(0, -1),
        {
          label: translate('search.breadcrumb_location', {
            name:
              topKeyword?.id === 1
                ? translate('search.all_specialists')
                : (topKeyword?.practitioner
                    ? `${topKeyword?.practitioner?.[language] || topKeyword?.practitioner?.en}s`
                    : topKeyword?.name[language]) || '',
            location: capitalise(decodeURIComponent(decodeURI(breadcrumbs[breadcrumbs.length - 1]?.label || language)))
          }),
          url: breadcrumbs[breadcrumbs.length - 1]?.url,
          id: breadcrumbs[breadcrumbs.length - 1]?.id
        }
      ]
    : []

  const pageSchema = useBreadCrumbsSchema(specSchema, mappedBreadcrumbs)

  const { practicesWithContactDetails } = useSpecialistPracticesContacts(specialist.practices)

  const { reviewStatistic, peerRecommendationStatistic, mergedStatistic } = useStatistic(specialist.statistic)

  const showBookButton = contacts.some((contact) => contact.externalBookingLink?.length)
  const showEmailButton = contacts.some((contact) => contact.email?.length)
  const showPhoneButton = contacts.some((contact) => contact.phone?.length)

  const hideBookAppointment = !showBookButton && !showEmailButton && !showPhoneButton

  const keywords = useTopLevelKeywords(specialist.keywords || [])

  const topKeywordName = topKeyword?.name[language].toLowerCase() || ''

  const maxTopKeywordLength = (() => {
    if (language === 'de') {
      return 10
    } else {
      return 20
    }
  })()

  const topKeywordLengthIsTooLong = topKeywordName.length > maxTopKeywordLength

  const otherSpecialistsButtonText = translate(
    topKeywordLengthIsTooLong ? 'other.specialists.buttonText.withoutKeyword' : 'other.specialsts.buttonText',
    {
      keyword: topKeywordLengthIsTooLong ? '' : topKeywordName,
      location: ''
    }
  )

  const otherPracticesButtonText = translate(
    topKeywordLengthIsTooLong ? 'other.practices.buttonText.withoutKeyword' : 'other.practices.buttonText',
    {
      keyword: topKeywordLengthIsTooLong ? '' : topKeywordName,
      location: ''
    }
  )

  const urlKeyword = topKeyword?.slug?.[0] || 'all'

  const otherSpecialistSearchUrl = `/${router.locale}/find/${urlKeyword}/${topLocation?.slug}/specialists`
  const otherPracticesSearchUrl = `/${router.locale}/find/${urlKeyword}/${topLocation?.slug}/practices`

  const profileAnalytics = {
    profile: {
      prf_type: specialist.plan,
      prf_practice_count: specialist.practices.length,
      prf_book_button: showBookButton,
      prf_contact_email: showEmailButton,
      prf_contact_phone: showPhoneButton,
      prf_booking_link: showBookButton,
      prf_media_centre: visibilityComponents.media,
      prf_uuid: specialist.externalId,
      prf_specialty: keywords,
      prf_practice_type: undefined,
      // false for now as described in doc
      prf_facilities: false
    },
    review: {
      rvw_count: specialist.reviewsTotal?.toString() || '0',
      vw_score: specialist.averageRating?.toString() || '0'
    }
  }

  const sortedLanguages = useMemo(() => {
    return specialist.languages
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      .sort((a, b) => {
        if (a.isoCode === language) {
          return -1
        }
        if (b.isoCode === language) {
          return 1
        }
        return 0
      })
  }, [language, specialist.languages])

  const bookingCalendarSpecialistProfile = useMemo(
    () => ({
      id: specialist.id,
      name: specialist.fullName[language],
      rating: specialist.averageRating,
      reviewsNumber: totalReviews,
      specialty: keywords,
      title: specialist.title[language],
      imageSrc: specialist.images.logo,
      pageLink: `/${router.locale}/specialist/${specialist.slug}`
    }),
    [
      keywords,
      language,
      router.locale,
      specialist.averageRating,
      specialist.fullName,
      specialist.id,
      specialist.images.logo,
      specialist.slug,
      specialist.title,
      totalReviews
    ]
  )

  const bookingCalendarSpecialistPractices = useMemo(
    () =>
      specialist.practices.map((specialistPractice) => ({
        id: specialistPractice.id,
        name: specialistPractice.name?.[language],
        rating: specialistPractice.reviews.overallExperience,
        reviewsNumber: specialistPractice.reviews?.reviewsTotal ?? 0,
        contactNumber: specialistPractice.contactDetailsPractice?.phone
      })),
    [language, specialist.practices]
  )

  const bookingCalendarSlots = useMemo(
    () =>
      specialist.practices.map((specialistPractice) => ({
        practiceId: specialistPractice.id,
        slots: bookingSlots.filter((f) => f.practice.id === specialistPractice.id)
      })),
    [specialist.practices, bookingSlots]
  )

  const bookingCalendarVisitReasons = useMemo(
    () => [
      { name: 'Select', value: null },
      ...specialist.keywords.map((keyword) => ({
        name: keyword.name?.[language],
        value: String(keyword.id)
      })),
      { name: 'Other', value: 'other' }
    ],
    [language, specialist.keywords]
  )

  return {
    router,
    locale,
    theme,
    language,
    isRtl,
    translate,
    isMobile,
    isLaptop,
    profileVideo,
    profileName,
    visibilityComponents,
    introVideoAvailable,
    publications,
    specialistSuffix,
    contacts,
    mappedBreadcrumbs,
    pageSchema,
    practicesWithContactDetails,
    reviewStatistic,
    peerRecommendationStatistic,
    mergedStatistic,
    hideBookAppointment,
    otherSpecialistsButtonText,
    otherPracticesButtonText,
    otherSpecialistSearchUrl,
    otherPracticesSearchUrl,
    profileAnalytics,
    sortedLanguages,
    bookingCalendarSpecialistProfile,
    bookingCalendarSpecialistPractices,
    bookingCalendarSlots,
    bookingCalendarVisitReasons
  }
}
