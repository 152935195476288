import React__default from 'react';

const DonutChart = ({ data, width = 200, height = 200, thickness = 3 }) => {
    const total = data.reduce((sum, item) => sum + item.value, 0);
    const calculateOffset = (index) => {
        if (index === 0) {
            return 0;
        }
        return data.slice(0, index).reduce((sum, item) => sum + (item.value / total) * 100, 0);
    };
    return (React__default.createElement("svg", { width: width, height: height, viewBox: "0 0 42 42", className: "donut" }, data.map((item, index) => {
        const offset = calculateOffset(index);
        const valuePercentage = (item.value / total) * 100;
        return (React__default.createElement("circle", { key: `donut-segment-${item.name}`, className: "donut-segment", cx: "21", cy: "21", r: "15.91549431", fill: "transparent", stroke: item.color, strokeWidth: thickness, strokeDasharray: `${valuePercentage} ${100 - valuePercentage}`, strokeDashoffset: -offset }));
    })));
};

export { DonutChart as default };
