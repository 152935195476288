import { BoxProps } from '@hermes/web-components'
import NextImage from 'next/image'
import { useCallback, useState, memo, FC, useMemo } from 'react'
import { AvatarChildrenContainer, AvatarContainer } from './styles'
import { defaultLoader, doctifyCDNLoader } from '../../utils/imageLoaders'

export interface AvatarProps extends BoxProps {
  src: string | undefined
  alt: string
  width?: number
  height?: number
  preload?: boolean
  lazy?: boolean
  withDoctifyCDNLoader?: boolean
}

const Avatar: FC<AvatarProps> = ({
  src: avatarSrc,
  alt,
  children,
  width,
  height,
  preload = false,
  lazy = false,
  withDoctifyCDNLoader,
  ...props
}) => {
  const [isFallback, setIsFallback] = useState(!avatarSrc)

  const priority = useMemo(() => (lazy ? false : preload), [lazy, preload])

  const loading = useMemo(() => (lazy ? 'lazy' : 'eager'), [lazy])

  const handleLoadError = useCallback(() => setIsFallback(true), [])

  return (
    <AvatarContainer
      className="profile-avatar-container"
      bgcolor={isFallback ? 'grey.400' : 'common.white'}
      width={width}
      height={height}
      {...props}
    >
      {avatarSrc && (
        <NextImage
          alt={alt}
          sizes="(max-width: 610px) 156px, (min-width: 610px) 256px, 384px"
          src={avatarSrc}
          width={width}
          height={height}
          onError={handleLoadError}
          onAbort={handleLoadError}
          loader={withDoctifyCDNLoader ? doctifyCDNLoader : defaultLoader}
          priority={priority}
          loading={loading}
        />
      )}
      <AvatarChildrenContainer container alignItems="center" justifyContent="center">
        {isFallback && children}
      </AvatarChildrenContainer>
    </AvatarContainer>
  )
}

const AvatarComponent = memo(Avatar)

export default AvatarComponent
