import {
  PracticeProfileType,
  SpecialistProfileType,
  SlotType,
  DateString,
  TimeString,
  VisitReason,
  AppProps,
  InsuranceType
} from '@hermes/booking-calendar'
import { Dispatch, PropsWithChildren, SetStateAction } from 'react'

export enum MODAL_VARIANTS {
  FULL_SCREEN = 'fullScreen',
  SIDE_MODAL = 'sideModal',
  BOTTOM_MODAL = 'bottomModal',
  NONE = 'none'
}

export enum LOCATION_MODAL_VARIANTS {
  CONTACT = 'contact',
  PROFILE_SELECTION = 'profileSelection',
  NONE = 'none'
}

export interface StateProps {
  practiceProfile?: PracticeProfileType
  practices: Array<PracticeProfileType>
  isBookingAvailable?: boolean
  specialistProfile?: SpecialistProfileType
  currentScreen: number
  slots?: SlotType[]
  visitReasons?: VisitReason[]
  selectedInsurance?: { value: any; label: string }
  selectedSlot: SlotType | null
  selectedDateString?: DateString
  selectedTimeString?: TimeString
}

export interface ProviderProps extends PropsWithChildren, Omit<Partial<AppProps>, 'slots'> {
  slots?: Array<{ practiceId: number; slots: SlotType[] }>
  toggleBookingModal: () => void
  practices: Array<PracticeProfileType>
}

export type FormDataProps = Record<string, string | number | boolean>

export interface ReturnTypeProps {
  state: StateProps
  isFullScreenMode?: boolean
  isBookingAvailable: boolean
  modalVariant: MODAL_VARIANTS
  isInsuranceModalOpen?: boolean
  locationModalVariant: LOCATION_MODAL_VARIANTS
  closeProfileSelectionModal: () => void
  changeScreen: (screen: number) => void
  getPracticeSlots: (practiceId: number) => Array<SlotType>
  setModalVariant: Dispatch<SetStateAction<MODAL_VARIANTS>>
  insurances: Array<InsuranceType>
  handleBookButtonClick: (formData: FormDataProps) => Promise<boolean>
  handleContactRequestClick: () => void
  handleInsuranceSelectionClick: (insurance?: InsuranceType) => void
  handleDateSelectionClick: (dateString?: DateString) => void
  handleTimeSelectionClick: (timeString?: TimeString, triggerSource?: string) => void
  handleProfileSelectionClick: () => void
  handleSlotNotAvailableBackClick: () => void
  handleSlotSelectionClick: (slot?: SlotType) => void
  handleExpandButtonClick: () => void
  handlePracticeChange: (id: number) => void
  handleInsuranceSelectClick: () => void
  handleShowMoreDatesClick: () => void
  handleShowMoreSlotsClick: () => void
  handleViewAllAvailabilityClick?: () => void
  displayPracticeSelector: boolean
}
