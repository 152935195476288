import { useMemo, ReactNode, useContext, useState, useCallback, SyntheticEvent, useEffect } from 'react'
import { Tabs, Tab, Box, styledComponent, styled, WhiteTooltip, Link, useTheme, Hidden } from '@hermes/web-components'

import usePageScrollProgress from '../../hooks/usePageScrollProgress'
import useTranslateMessage from '../../hooks/useTranslateMessage'
import useActiveTabTracker from '../../hooks/useActiveTabTracker'
import { AppDataContext } from '../../providers/AppData'
import ContentTopContainer from '../ContentTopContainer'
import { ProfileTabsProps } from '../../types/index'
import { useVisibilityComponents } from '../../hooks/useVisibilityComponents'
import ArrowRightIcon from '../Icons/ArrowRightIcon'
import NextLink from 'next/link'
import { themedColor } from './styles'

const TabsRoot = styledComponent(Box)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.blue[200]}`,
  position: 'sticky',
  top: 64,
  left: 0,
  marginTop: 0,
  zIndex: 999,
  overflowX: 'auto',
  '& .MuiTab-root': {
    '&:after': {
      display: 'none !important'
    }
  },
  [theme.breakpoints.down('sm')]: {
    top: 48,
    borderTop: `1px solid ${theme.palette.blue[200]}`
  }
}))

const LinkComponent = styledComponent(Link)(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.darkNavy,
  padding: '10px 12px',
  fontSize: 15,
  fontWeight: 400,
  opacity: 1,
  flexWrap: 'nowrap',
  textDecoration: 'none',
  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.body2.fontSize
  },
  [theme.breakpoints.up('md')]: {
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

const StyledTab = styledComponent(Tab)(({ theme }) => ({
  padding: '0 !important',
  minWidth: 'max-content',
  '&.Mui-active': {
    a: {
      color: `${theme.palette.primary.dark} !important`,
      fontWeight: 600,
      '&:after': {
        // eslint-disable-next-line quotes
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        background: '',
        width: '100%',
        borderRadius: 4,
        height: 4,
        left: 0
      }
    }
  }
}))

const ScrollIndicator = styled('div', {
  shouldForwardProp: (prop: string) => prop !== 'width'
})<{ width: number }>(({ width, theme, color }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  height: 4,
  width: `${width}%`,
  background: color,
  borderRadius: 4,
  [theme.breakpoints.down('sm')]: {
    height: 1,
    background: color
  }
}))

function ProfileTabs({ specialist, practice, isUnclaimed = false, ...props }: ProfileTabsProps) {
  const theme = useTheme()
  const { locale, isRtl } = useContext(AppDataContext)
  const { progressIndicatorColor } = themedColor()
  const translate = useTranslateMessage()
  const visibilityComponents = useVisibilityComponents({
    specialist,
    practice
  })

  const tabs = useMemo(() => {
    const profileTabs: { label: string | ReactNode; selector: string }[] = []

    if (visibilityComponents.about) {
      profileTabs.push({ label: translate('about'), selector: 'about' })
    }

    if (specialist) {
      if (visibilityComponents.fees) {
        profileTabs.push({ label: translate('fees', { defaultMessage: 'Fees' }), selector: 'fees' })
      }
      if (visibilityComponents.location) {
        profileTabs.push({
          label: translate('locations'),
          selector: 'locations'
        })
      }
      if (visibilityComponents.reviews) {
        profileTabs.push({
          label: translate('reviews.reviews'),
          selector: 'reviews'
        })
      }
      if (visibilityComponents.peerRecommendations) {
        profileTabs.push({
          label: translate('skills'),
          selector: 'endorsements'
        })
      }
    }
    if (practice) {
      if (visibilityComponents.location) {
        profileTabs.push({
          label: translate('working_hours'),
          selector: 'locations'
        })
      }
      if (visibilityComponents.reviews) {
        profileTabs.push({
          label: translate('reviews.reviews'),
          selector: 'reviews'
        })
      }
      if (visibilityComponents.specialists) {
        profileTabs.push({
          label: translate('specialists'),
          selector: 'specialists'
        })
      }
      if (visibilityComponents.facilities || visibilityComponents.services || visibilityComponents.parking) {
        profileTabs.push({
          label: translate('facilities'),
          selector: 'services-facilities'
        })
      }
      if (visibilityComponents.gallery) {
        profileTabs.push({
          label: translate('gallery'),
          selector: 'gallery'
        })
      }
    }

    if (visibilityComponents.videos) {
      profileTabs.push({
        label: (
          <WhiteTooltip isRtl={isRtl} title={translate('media-tooltip.text')}>
            <Box
              sx={{
                borderRadius: '50px',
                background:
                  'linear-gradient(90deg, rgba(0, 163, 255, 0.20) 0%, rgba(0, 229, 208, 0.20) 99.99%, rgba(0, 229, 208, 0.20) 100%)',
                border: '1.5px solid #00A3FF',
                padding: '2px 10px'
              }}
            >
              {translate('media_center.videos')}
            </Box>
          </WhiteTooltip>
        ),
        selector: 'media'
      })
    }

    if (visibilityComponents.articles) {
      profileTabs.push({
        label: translate('media_center.articles'),
        selector: 'articles'
      })
    }

    if (visibilityComponents.faq) {
      profileTabs.push({
        label: translate('faq'),
        selector: 'faq'
      })
    }

    return profileTabs
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialist, isUnclaimed, practice])

  const trackingTabs = useMemo(() => {
    if (visibilityComponents.about) {
      return [...tabs, { label: translate('about'), selector: 'about' }]
    }
    return tabs
  }, [tabs, translate, visibilityComponents.about])

  const getTabIndex = useCallback(
    (id: string) => {
      if (tabs.findIndex((el) => el.selector === id) === -1) {
        return false
      }
      return tabs.findIndex((el) => el.selector === id)
    },
    [tabs]
  )

  const [tabsValue, setTabsValue] = useState(0)

  const activeElement = useActiveTabTracker(trackingTabs)
  const slug = (specialist && specialist.slug) || (practice && practice.slug)
  const isSpecialist = !!specialist
  const scrollProgress = usePageScrollProgress()

  const onClick = (tabLabel: string) => () => {
    window.dataLayer.push({ event: 'prf_pg_navigaton', prf_navigation_label: tabLabel })
  }

  const handleChangeSelectedTab = useCallback((_: SyntheticEvent, ev: string) => setTabsValue(+ev), [])

  useEffect(() => {
    const newTabIndex = getTabIndex(activeElement)
    if (newTabIndex) {
      setTabsValue(newTabIndex)
    }
  }, [activeElement, getTabIndex])

  return (
    <TabsRoot component={Hidden} {...props}>
      <Tabs
        sx={{
          marginTop: '0 !important',
          backgroundColor: 'transparent',
          '.MuiTabs-indicator': {
            display: 'none'
          },
          '& .MuiTabs-scrollButtons': {
            height: '34px',
            width: '34px',
            borderRadius: '50px',
            border: '1px solid #D5DEF9',
            backgroundColor: 'transparent',
            padding: '8px'
          }
        }}
        value={tabsValue}
        onChange={handleChangeSelectedTab}
        component={ContentTopContainer}
        maxWidth="xl"
        ScrollButtonComponent={(props) => (
          <ArrowRightIcon
            directionLeft={props.direction === 'left'}
            style={{
              display: props.disabled ? 'none' : 'flex',
              boxShadow: `${props.direction === 'left' ? '4px -4px' : '-4px 0px'} 10px 0px #D5DEF94D`,
              backgroundColor: 'white',
              zIndex: 999
            }}
            {...props}
          />
        )}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
      >
        {tabs.map((tab, index) => (
          <StyledTab
            className={tab.selector === activeElement ? 'Mui-active' : ''}
            label={
              <NextLink
                href={`/${locale}/${isSpecialist ? 'specialist' : 'practice'}/${slug}#${tab.selector}`}
                passHref
              >
                <LinkComponent sx={theme.custom?.SpecialistPage?.common?.tabBarItemHover}>{tab.label}</LinkComponent>
              </NextLink>
            }
            key={tab.selector}
            value={getTabIndex(tab.selector)}
            onClick={onClick(tab.selector)}
            sx={{
              paddingRight: index === tabs.length - 1 ? '24px' : '12px'
            }}
          />
        ))}
      </Tabs>
      <ScrollIndicator width={scrollProgress} color={progressIndicatorColor} />
    </TabsRoot>
  )
}

export default ProfileTabs
