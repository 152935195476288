import React from 'react';

const Expand = () => {
    return (React.createElement("svg", { width: "17", height: "17", viewBox: "0 0 17 17", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M6.90107 6.92862C7.13527 7.16305 7.51517 7.16322 7.74959 6.92902C7.98402 6.69482 7.9842 6.31492 7.74999 6.08049L6.90107 6.92862ZM7.74999 6.08049L3.79946 2.12623L2.95054 2.97436L6.90107 6.92862L7.74999 6.08049Z", fill: "currentColor" }),
        React.createElement("path", { d: "M3.3584 6.50456V2.55029H7.23305", stroke: "currentColor", strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M10.8528 9.19443C10.6186 8.96 10.2387 8.95982 10.0043 9.19403C9.76989 9.42823 9.76971 9.80813 10.0039 10.0426L10.8528 9.19443ZM10.0039 10.0426L13.9544 13.9968L14.8034 13.1487L10.8528 9.19443L10.0039 10.0426Z", fill: "currentColor" }),
        React.createElement("path", { d: "M14.3955 9.61849L14.3955 13.5728L10.5209 13.5728", stroke: "currentColor", strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M10.0172 6.08661C9.78276 6.32082 9.78258 6.70072 10.0168 6.93514C10.251 7.16957 10.6309 7.16975 10.8653 6.93554L10.0172 6.08661ZM10.8653 6.93554L14.8196 2.98501L13.9714 2.13608L10.0172 6.08661L10.8653 6.93554Z", fill: "currentColor" }),
        React.createElement("path", { d: "M10.4412 2.5437L14.3955 2.5437L14.3955 6.41836", stroke: "currentColor", strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" }),
        React.createElement("path", { d: "M7.73673 10.0364C7.97115 9.80223 7.97133 9.42233 7.73713 9.18791C7.50292 8.95348 7.12302 8.9533 6.8886 9.18751L7.73673 10.0364ZM6.8886 9.18751L2.93433 13.138L3.78246 13.987L7.73673 10.0364L6.8886 9.18751Z", fill: "currentColor" }),
        React.createElement("path", { d: "M7.31266 13.5793L3.3584 13.5793L3.3584 9.70469", stroke: "currentColor", strokeWidth: "1.2", strokeLinecap: "round", strokeLinejoin: "round" })));
};

export { Expand as default };
