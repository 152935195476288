import React from 'react';
import { Container, ContentWrapper, SummarySection, Title } from './styles.esm.js';
import CalendarStyled from '../../icons/CalendarStyled.esm.js';
import { Button, Typography } from '@hermes/web-components';
import resolveThemeComponent from '../../utils/resolveThemeComponent.esm.js';

const SlotNotAvailable = ({ onButtonClick }) => {
    return (React.createElement(Container, null,
        React.createElement(ContentWrapper, null,
            resolveThemeComponent({
                default: React.createElement(CalendarStyled, null),
            }),
            React.createElement(SummarySection, null,
                React.createElement(Title, null, "Sorry, this time slot is no longer available."),
                React.createElement("span", null, "We are sorry but this time slot is no longer available. Please select another time.")),
            React.createElement("div", null,
                React.createElement(Button, { variant: "tertiary", onClick: onButtonClick },
                    React.createElement(Typography, { component: "span" }, "Find another time slot"))))));
};

export { SlotNotAvailable as default };
