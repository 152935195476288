import React, { useContext } from 'react'
import { Typography, LocationOnIcon, Grid, Paper, Link, theme, Rating } from '@hermes/web-components'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'
import { transformAddressToString } from '../../../../utils/address'
import Avatar from '../../../Avatar'

import { AppDataContext } from '../../../../providers/AppData'
import { fixSlug } from '../../../../utils/checkProfileSlugRedirect'
import { PracticeProfile } from '../../../../types/practice'

function PracticeCard({ practice }: { practice: PracticeProfile }) {
  const translate = useTranslateMessage()
  const { language, tenantSettings, locale } = useContext(AppDataContext)

  return (
    <Paper
      sx={{
        border: 1,
        borderColor: theme.palette.background.default,
        p: 2,
        height: '100%',
        boxShadow:
          '0px 6px 10px rgba(2, 3, 3, 0.03), 0px 1px 18px rgba(2, 3, 3, 0.02), 0px 3px 5px rgba(2, 3, 3, 0.04)',
        borderRadius: 2
      }}
    >
      <Link href={`/${locale}/practice/${fixSlug(practice.slug)}`} underline="none">
        <Grid container borderRadius="8px" bgcolor="white" gap="16px" height="100%" justifyContent="space-between">
          <Grid container alignItems="flex-start" item xs={12} columnSpacing={2}>
            <Grid item xs="auto">
              <Avatar
                alt={practice?.name[language]}
                width={64}
                height={64}
                src={practice?.images?.logo}
                withDoctifyCDNLoader
              />
            </Grid>
            <Grid container item xs direction="row" alignItems="center">
              <Grid item xs={12}>
                <Typography variant="h4" component="h4" color="text.primary" fontWeight={600} gutterBottom p={0}>
                  {practice.name[language]}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container item spacing={1} alignItems="center">
            <Grid item xs="auto">
              <LocationOnIcon />
            </Grid>
            <Grid item xs>
              <Typography variant="body2" color="text.disabled">
                {practice.address
                  ? transformAddressToString(practice.address, language)
                  : tenantSettings?.defaultLocations[0].name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item alignSelf="flex-end" xs={12}>
            <Paper
              sx={{
                px: 2,
                py: 1.5,
                boxShadow: 'none',
                backgroundColor: 'background.default'
              }}
            >
              <Grid container spacing={2}>
                <Grid item display="flex" alignItems="center">
                  <Typography color="primary.dark" variant="h2" fontWeight={900}>
                    {practice.reviewObject?.averageRating?.toFixed(2)}
                  </Typography>
                </Grid>
                <Grid container item xs>
                  <Grid item xs={12}>
                    <Rating value={practice.reviewObject?.averageRating} size="small" readOnly />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {`(${translate('reviews.total_patient_reviews', {
                        total: practice.reviewObject.reviewsTotal
                      })})`}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Link>
    </Paper>
  )
}

export default PracticeCard
