import { __rest } from '../../_virtual/_tslib.esm.js';
import React__default from 'react';
import { InView } from '../../node_modules/react-intersection-observer/index.esm.js';
import Box from '../../node_modules/@mui/material/Box/Box.esm.js';

function Lazy(_a) {
    var { children, ssrOnly, sx, margin, onChange } = _a, rest = __rest(_a, ["children", "ssrOnly", "sx", "margin", "onChange"]);
    return (React__default.createElement(InView, { skip: ssrOnly, initialInView: ssrOnly, triggerOnce: true, onChange: onChange, rootMargin: `${margin || 40}px` }, ({ inView, ref }) => (React__default.createElement(Box, Object.assign({ ref: ref, sx: sx }, rest), inView && children))));
}
Lazy.defaultProps = {
    ssrOnly: false,
    sx: {}
};

export { Lazy as default };
