import React from 'react';
import { Container, StickyComponent } from './styles.esm.js';

const StickyBottomContainer = ({ children, stickyComponent, }) => {
    return (React.createElement(Container, null,
        children,
        React.createElement(StickyComponent, null, stickyComponent)));
};

export { StickyBottomContainer as default };
