import { __rest } from '../../_virtual/_tslib.esm.js';
import React from 'react';
import { Button } from '@hermes/web-components';
import Chevron from '../../icons/Chevron.esm.js';

const PreviousButton = (_a) => {
    var { sx } = _a, props = __rest(_a, ["sx"]);
    return (React.createElement(Button, Object.assign({ sx: Object.assign({ minWidth: 'auto', width: '40px', height: '40px', padding: 0, backgroundColor: 'background.light' }, sx) }, props),
        React.createElement(Chevron, null)));
};

export { PreviousButton as default };
