import React, { useState } from 'react'
import { Typography, Box, HTMLSlot } from '@hermes/web-components'

const ReadMore: React.FC<{ text: string; maxLength: number }> = ({ text, maxLength }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleExpanded = () => setExpanded(!expanded)

  const isTextOverflowing = text.length > maxLength

  return (
    <Box>
      <HTMLSlot>{isTextOverflowing && !expanded ? `${text.slice(0, maxLength)}...` : text}</HTMLSlot>
      {isTextOverflowing && (
        <Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={toggleExpanded}>
          {expanded ? 'Read less' : 'Read more'}
        </Typography>
      )}
    </Box>
  )
}

export default ReadMore
