import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
    gap: '70px',
    flexWrap: 'wrap',
});
const FormSection = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    maxWidth: '512px',
    flex: '0 0 80%',
});
const ProfileInfoSection = styledComponent('div')(({ theme }) => ({
    flex: '0 0 20%',
    width: '100%',
    minWidth: '417px',
    maxWidth: '512px',
    display: 'flex',
    flexDirection: 'column',
    border: `solid thin ${theme.palette.grey[600]}`,
    borderRadius: '16px',
    padding: '20px',
    '&>hr': {
        margin: '24px 0',
        width: '100%',
        border: 'none',
        borderTop: `solid thin ${theme.palette.grey[600]}`,
    },
    '&>span': {
        color: theme.palette.system.navy900,
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
}));
const ProfileInfo = styledComponent('div')({
    display: 'flex',
    gap: '16px',
    '&>img': {
        borderRadius: '8px',
        width: '82px',
        height: '82px',
    },
});
const ProfileMetrics = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    color: theme.palette.system.navy900,
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    '&>h5': {
        color: theme.palette.system.navy900,
        textAlign: 'center',
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '26px',
        margin: 0,
    },
    '&>h6': {
        color: theme.palette.system.navy900,
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '20px',
        margin: 0,
    },
}));
const ReviewsInfo = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    '&>span': {
        color: theme.palette.system.navy900,
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
}));
const BookingInfo = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    gap: '8px',
    '&>span': {
        color: theme.palette.system.navy900,
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
    },
}));
const HeaderWrapper = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});
const Header = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '&>h5': {
        color: theme.palette.text.darkNavy,
        fontFamily: `'Klarheit Kurrent', sans-serif`,
        fontSize: '21.36px',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '25px',
        margin: 0,
    },
    '&>span': {
        color: theme.palette.text.black700,
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '24px',
    },
}));
const BottomSection = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
});
const CommonText = styledComponent('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
}));

export { BookingInfo, BottomSection, CommonText, Container, FormSection, Header, HeaderWrapper, ProfileInfo, ProfileInfoSection, ProfileMetrics, ReviewsInfo };
