import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')(({ theme, selected }) => ({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '16px',
    backgroundColor: selected
        ? theme.palette.primary.main
        : theme.palette.background.default,
    color: selected ? theme.palette.common.white : theme.palette.text.primary,
    border: `solid thin ${theme.palette.grey[600]}`,
    width: '84px',
    padding: '11px 10px',
    gap: '8px',
    cursor: 'pointer',
    userSelect: 'none',
    '&:hover, &:active': {
        borderColor: theme.palette.primary.main,
    },
    '&[disabled]': {
        backgroundColor: theme.palette.background.light3,
        borderColor: theme.palette.grey[100],
        color: theme.palette.text.secondary,
        pointerEvents: 'none',
        '&>span': {
            fontWeight: 400,
        },
    },
    '@media (max-width: 480px)': {
        padding: '11px 0',
    },
}));
const ValueWrapper = styledComponent('span')({
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
});
const FirstAvailable = styledComponent('div')(({ theme }) => ({
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: theme.palette.system.green,
    height: '16px',
    width: '16px',
    minHeight: '16px',
    minWidth: '16px',
}));

export { Container, FirstAvailable, ValueWrapper };
