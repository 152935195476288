import getImageWithWWW from './getImageWithWWW'
import { PracticePlans, SpecialistPlans } from '../constants/profile'
import { PracticeProfile, PracticeSpecialist } from '../types/practice'
import { SpecialistPractice, SpecialistProfile } from '../types/specialist'

type FilteringProfileDataByPlan = {
  practice?: PracticeProfile
  specialist?: SpecialistProfile
  specialistPractice?: SpecialistPractice
  practiceSpecialist?: PracticeSpecialist
}

const UNCLAIMED_PLANS = [PracticePlans.UNCLAIMED, SpecialistPlans.UNCLAIMED]
const BASIC_PLANS = [PracticePlans.ENTRY, SpecialistPlans.ENTRY]

// todo: fix complexity
// eslint-disable-next-line complexity
export const filteringProfileDataByPlan = ({
  practice,
  specialist,
  specialistPractice,
  practiceSpecialist
}: FilteringProfileDataByPlan):
  | PracticeProfile
  | SpecialistProfile
  | SpecialistPractice
  | PracticeSpecialist
  | undefined => {
  const profile = practice || specialist || specialistPractice || practiceSpecialist
  const isUnclaimed = UNCLAIMED_PLANS.some((plan) => profile?.plan.includes(plan))
  const isBasic = BASIC_PLANS.some((plan) => profile?.plan.includes(plan))

  if (practice) {
    const imagesWithWWW = {
      cover: getImageWithWWW(practice?.images?.cover || ''),
      logo: getImageWithWWW(practice?.images?.logo || ''),
      images: practice?.images?.images?.map((href) => getImageWithWWW(href)) || []
    }

    const resultPractice: PracticeProfile = {
      ...practice,
      images: imagesWithWWW,
      reviewObject: { ...practice.reviewObject },
      specialists:
        practice.specialists?.map(
          (pr) => filteringProfileDataByPlan({ practiceSpecialist: pr }) as PracticeSpecialist
        ) || []
    }
    if (isBasic || isUnclaimed) {
      resultPractice.averageRating = 0
      resultPractice.reviewsTotal = 0
      resultPractice.overallExperience = 0
      resultPractice.lastReview = null
      resultPractice.images.logo = ''
      resultPractice.insurers = []
      resultPractice.reviewObject = {
        overallExperience: 0,
        reviewsTotal: 0,
        averageRating: 0,
        bedsideManner: 0,
        careAndSupport: 0,
        cleanliness: 0,
        explanation: 0,
        facilities: 0,
        friendliness: 0,
        waitTime: 0
      }
    }
    return resultPractice
  }

  if (practiceSpecialist) {
    const imagesWithWWW = {
      logo: getImageWithWWW(practiceSpecialist?.images?.logo || '')
    }

    const resultPracticeSpecialist: PracticeSpecialist = {
      ...practiceSpecialist,
      images: imagesWithWWW,
      review: { ...practiceSpecialist.review } as PracticeSpecialist['review']
    }
    if (isBasic || isUnclaimed) {
      resultPracticeSpecialist.averageRating = 0
      resultPracticeSpecialist.reviewsTotal = 0
      resultPracticeSpecialist.overallExperience = 0
      resultPracticeSpecialist.explanation = 0
      resultPracticeSpecialist.bedsideManner = 0
      resultPracticeSpecialist.averageRating = 0
      resultPracticeSpecialist.lastReview = null
      resultPracticeSpecialist.images.logo = ''
      resultPracticeSpecialist.insurers = []
      resultPracticeSpecialist.review = {
        overallExperience: 0,
        reviewsTotal: 0,
        averageRating: 0,
        bedsideManner: 0,
        careAndSupport: 0,
        cleanliness: 0,
        explanation: 0,
        facilities: 0,
        friendliness: 0,
        waitTime: 0
      }
    }
    if (isUnclaimed) {
      resultPracticeSpecialist.registrationBodies = []
    }
    return resultPracticeSpecialist
  }

  if (specialist) {
    const imagesWithWWW = {
      logo: getImageWithWWW(specialist?.images?.logo || ''),
      images: specialist?.images?.images?.map((href) => getImageWithWWW(href)) || []
    }

    const resultSpecialist: SpecialistProfile = {
      ...specialist,
      images: imagesWithWWW,
      review: { ...specialist.review } as SpecialistProfile['review'],
      practices:
        specialist.practices?.map(
          (pr) => filteringProfileDataByPlan({ specialistPractice: pr }) as SpecialistPractice
        ) || []
    }
    if (isBasic || isUnclaimed) {
      resultSpecialist.averageRating = 0
      resultSpecialist.reviewsTotal = 0
      resultSpecialist.overallExperience = 0
      resultSpecialist.lastReview = null
      resultSpecialist.insurers = []
      resultSpecialist.review = {
        overallExperience: 0,
        reviewsTotal: 0,
        averageRating: 0,
        bedsideManner: 0,
        careAndSupport: 0,
        cleanliness: 0,
        explanation: 0,
        facilities: 0,
        friendliness: 0,
        waitTime: 0
      }
    }
    if (isUnclaimed) {
      resultSpecialist.registrationBodies = []
    }
    return resultSpecialist
  }

  if (specialistPractice) {
    const imagesWithWWW = {
      cover: getImageWithWWW(specialistPractice?.images?.cover || ''),
      logo: getImageWithWWW(specialistPractice?.images?.logo || ''),
      images: specialistPractice?.images?.images?.map((href) => getImageWithWWW(href)) || []
    }

    const resultSpecialistPractice: SpecialistPractice = {
      ...specialistPractice,
      images: imagesWithWWW,
      reviews: { ...specialistPractice.reviews }
    }
    if (isBasic || isUnclaimed) {
      resultSpecialistPractice.images.logo = ''
      resultSpecialistPractice.reviews = {
        overallExperience: 0,
        reviewsTotal: 0,
        averageRating: 0,
        bedsideManner: 0,
        careAndSupport: 0,
        cleanliness: 0,
        explanation: 0,
        facilities: 0,
        friendliness: 0,
        waitTime: 0
      }
    }
    return resultSpecialistPractice
  }
  return undefined
}
