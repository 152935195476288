import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
    width: '100%',
    minWidth: '340px',
});
const Label = styledComponent('label')(({ theme, disabled, required }) => {
    let color;
    if (disabled) {
        color = theme.palette.grey[500];
    }
    else if (required) {
        color = theme.palette.text.primary;
    }
    else {
        color = theme.palette.text.black700;
    }
    return {
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '13.33px',
        fontWeight: 400,
        lineHeight: '20px',
        textAlign: 'left',
        color,
        '&::after': required
            ? {
                content: '"*"',
                color: theme.palette.error.main,
                padding: '2px',
                fontSize: '13.33px',
            }
            : {},
    };
});
const InputWrapper = styledComponent('div')({
    width: '100%',
});
const InputError = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    boxSizing: 'border-box',
    width: '100%',
    padding: '4px 8px',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '13.33px',
    fontWeight: 400,
    lineHeight: '20px',
    textAlign: 'left',
    color: theme.palette.system.coral900,
    backgroundColor: theme.palette.error.light,
}));
const ErrorText = styledComponent('span')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

export { Container, ErrorText, InputError, InputWrapper, Label };
