import React from 'react';

const AirplaneWithTracer = () => {
    return (React.createElement("svg", { width: "176", height: "134", viewBox: "0 0 176 134", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M110.843 97.1949V97.1949C102.836 117.1 79.7753 126.22 60.3197 117.177L58.2272 116.204L47.653 112.393C39.0474 109.292 35.4692 98.9867 40.1207 91.1103V91.1103C45.3562 82.245 58.0515 81.4432 64.1434 89.7434V89.7434C69.5128 97.0592 66.8267 107.46 58.5862 111.261L38.9206 120.331C32.9141 123.102 26.1972 123.945 19.6922 122.745L1.99986 119.483", stroke: "url(#paint0_linear_12502_5491)", strokeWidth: "3", strokeLinecap: "round", strokeDasharray: "4 8" }),
        React.createElement("path", { d: "M113.761 86.5233L111.656 64.8313C111.591 64.1543 112.206 63.6107 112.869 63.7587L121.604 65.7068C121.719 65.7324 121.828 65.7779 121.927 65.8414L127.845 69.6425C128.342 69.9614 128.456 70.6387 128.091 71.1024L115.542 87.0452C114.985 87.7536 113.848 87.4205 113.761 86.5233Z", fill: "#00897D", stroke: "#1A3586", strokeWidth: "2", strokeLinecap: "round" }),
        React.createElement("path", { d: "M139.585 20.5286L87.202 57.4009C85.0606 58.9082 85.7508 62.262 88.3146 62.8068L116.981 68.8981C117.507 69.01 117.995 69.2621 118.391 69.628L139.994 89.5544C141.926 91.3366 145.037 89.9499 145.008 87.3193L144.301 22.9695C144.275 20.5485 141.556 19.1414 139.585 20.5286Z", fill: "#B4FFFB" }),
        React.createElement("path", { d: "M139.585 20.5286L87.202 57.4009C85.0606 58.9082 85.7508 62.262 88.3146 62.8068L116.981 68.8981C117.507 69.01 117.995 69.2621 118.391 69.628L139.994 89.5544C141.926 91.3366 145.037 89.9499 145.008 87.3193L144.301 22.9695C144.275 20.5485 141.556 19.1414 139.585 20.5286Z", fill: "url(#paint1_linear_12502_5491)", fillOpacity: "0.7" }),
        React.createElement("path", { d: "M139.585 20.5286L87.202 57.4009C85.0606 58.9082 85.7508 62.262 88.3146 62.8068L116.981 68.8981C117.507 69.01 117.995 69.2621 118.391 69.628L139.994 89.5544C141.926 91.3366 145.037 89.9499 145.008 87.3193L144.301 22.9695C144.275 20.5485 141.556 19.1414 139.585 20.5286Z", stroke: "#1A3586", strokeWidth: "3" }),
        React.createElement("path", { d: "M128.253 54.299L121.405 69.967C121.303 70.2012 121.041 70.3197 120.797 70.2415L110.788 67.0241C110.409 66.9024 110.316 66.4068 110.624 66.1566L126.711 53.113C127.523 52.4542 128.672 53.3381 128.253 54.299Z", fill: "#1A3586" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: "paint0_linear_12502_5491", x1: "2", y1: "103.015", x2: "110.843", y2: "103.015", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { offset: "0.05", stopColor: "#75F6EC" }),
                React.createElement("stop", { offset: "0.53", stopColor: "#5681FF" }),
                React.createElement("stop", { offset: "1", stopColor: "#1A3586" })),
            React.createElement("linearGradient", { id: "paint1_linear_12502_5491", x1: "97.0188", y1: "31.093", x2: "160.723", y2: "63.6157", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#B4FFFB" }),
                React.createElement("stop", { offset: "0.9999", stopColor: "#00E5D0" }),
                React.createElement("stop", { offset: "1", stopColor: "#00E5D0" })))));
};

export { AirplaneWithTracer as default };
