import React from 'react';
import { ProfileInfoWrapper, ProfileLogo, BookingInfoWrapper, ProfileNameText, SecondaryText } from './styles.esm.js';

const BookingInfo = ({ specialistProfile, practiceProfile, dateString, timeString, }) => {
    return (React.createElement(ProfileInfoWrapper, null,
        React.createElement(ProfileLogo, { src: specialistProfile === null || specialistProfile === void 0 ? void 0 : specialistProfile.imageSrc, alt: '' }),
        React.createElement(BookingInfoWrapper, null,
            React.createElement(ProfileNameText, null, specialistProfile === null || specialistProfile === void 0 ? void 0 : specialistProfile.name),
            (practiceProfile === null || practiceProfile === void 0 ? void 0 : practiceProfile.name) && (React.createElement(SecondaryText, null, practiceProfile.name)),
            React.createElement(SecondaryText, null,
                dateString && React.createElement("span", null, dateString),
                timeString && React.createElement("span", null,
                    " at ",
                    timeString)))));
};

export { BookingInfo as default };
