import { theme, ThemeOptions, deepmerge } from '@hermes/web-components'
import typography from './typography'
import components from './components'
import palette from './palette'
import custom from './custom'
import { CustomStyles } from './customStyles'

const defaultTheme: ThemeOptions & { custom: CustomStyles } = {
  ...theme,
  breakpoints: {
    values: {
      xl: 1440,
      lg: 1056,
      md: 992,
      sm: 610,
      xs: 0
    }
  },
  typography: deepmerge(theme.typography, typography),
  components: deepmerge(theme.components, components),
  palette: deepmerge(theme.palette, palette),
  custom
}
export default defaultTheme
