import { styledComponent } from '@hermes/web-components';

const ProfileInfoWrapper = styledComponent('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
});
const ProfileLogo = styledComponent('img')({
    borderRadius: '6px',
    width: '54px',
    height: '54px',
});
const BookingInfoWrapper = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
});
const ProfileNameText = styledComponent('span')(({ theme }) => ({
    color: theme.palette.text.darkNavy,
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
}));
const SecondaryText = styledComponent('span')(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
}));

export { BookingInfoWrapper, ProfileInfoWrapper, ProfileLogo, ProfileNameText, SecondaryText };
