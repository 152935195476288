import { useMemo } from 'react';
import { useTheme } from '@hermes/web-components';

const useWpaTenant = () => {
    const theme = useTheme();
    const isWPA = useMemo(() => theme.name === 'wpa', [theme]);
    return { isWPA };
};

export { useWpaTenant };
