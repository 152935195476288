import React, { useState, useCallback, useMemo } from 'react';
import SlotSelection$1 from '../../components/SlotSelection/index.esm.js';
import { Container } from './styles.esm.js';
import { Title } from '../DateSelection/styles.esm.js';
import LocationDropdown from '../../components/LocationDropdown/index.esm.js';
import StepFooter from '../../views/StepFooter/index.esm.js';
import StickyBottomContainer from '../../components/StickyBottomContainer/index.esm.js';
import ArrowBack from '../../icons/ArrowBack.esm.js';

const NEXT_DATES_NUMBER = 14;
const SlotSelection = ({ timeSlots, onSlotSelect, onNextStep, onLocationChange, onBackButtonClick, bookingInfo, displayLocationDropdown, }) => {
    var _a, _b, _c, _d, _e, _f;
    const [selectedSlot, setSelectedSlot] = useState();
    const handleSlotSelect = useCallback((slot) => {
        setSelectedSlot(slot);
        onSlotSelect === null || onSlotSelect === void 0 ? void 0 : onSlotSelect(slot);
        onNextStep === null || onNextStep === void 0 ? void 0 : onNextStep();
    }, [onSlotSelect]);
    const selectedTimeString = useMemo(() => (selectedSlot ? selectedSlot.time : ''), [selectedSlot]);
    const filteredSlots = useMemo(() => {
        if (bookingInfo.dateString) {
            return timeSlots.filter((f) => {
                const slotDate = new Date(f.date);
                const selectedDate = new Date(bookingInfo.dateString);
                const maxDate = new Date(selectedDate);
                maxDate.setDate(maxDate.getDate() + NEXT_DATES_NUMBER);
                return slotDate >= selectedDate && slotDate <= maxDate;
            });
        }
        return timeSlots;
    }, [timeSlots, bookingInfo]);
    return (React.createElement(StickyBottomContainer, { stickyComponent: React.createElement(StepFooter, Object.assign({}, bookingInfo, { dateString: bookingInfo.dateString, timeString: selectedTimeString })) },
        React.createElement(Container, { style: { marginBottom: '24px' } },
            React.createElement(Title, null,
                React.createElement("div", { style: { cursor: 'pointer' }, onClick: onBackButtonClick },
                    React.createElement(ArrowBack, null)),
                React.createElement("span", null, "Select a time")),
            React.createElement(LocationDropdown, { disabled: !displayLocationDropdown, onClick: onLocationChange, label: (_b = (_a = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : '', rating: (_d = (_c = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _c === void 0 ? void 0 : _c.rating) !== null && _d !== void 0 ? _d : 0, reviewsNumber: (_f = (_e = bookingInfo === null || bookingInfo === void 0 ? void 0 : bookingInfo.practiceProfile) === null || _e === void 0 ? void 0 : _e.reviewsNumber) !== null && _f !== void 0 ? _f : 0 }),
            React.createElement(SlotSelection$1, { timeSlots: filteredSlots, onSlotSelect: handleSlotSelect }))));
};

export { SlotSelection as default };
