import React, { useState, useCallback } from 'react';
import { Container, Input } from './styles.esm.js';

const TextInput = ({ defaultValue, placeholder, validate, onInput, }) => {
    const [value, setValue] = useState(defaultValue !== null && defaultValue !== void 0 ? defaultValue : '');
    const handleUpdate = useCallback((e) => {
        var _a, _b;
        const input = (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : '';
        setValue(input);
        onInput === null || onInput === void 0 ? void 0 : onInput(input);
    }, [onInput]);
    const handleBlur = useCallback(() => {
        validate === null || validate === void 0 ? void 0 : validate(value);
    }, [validate, value]);
    return (React.createElement(Container, null,
        React.createElement(Input, { placeholder: placeholder, value: value, onChange: handleUpdate, onBlur: handleBlur })));
};

export { TextInput as default };
