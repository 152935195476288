import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '24px',
});
styledComponent('h2')(({ theme }) => ({
    margin: '0',
    width: '100%',
    color: theme.palette.text.darkNavy,
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
}));

export { Container };
