import { styledComponent, Box, Typography } from '@hermes/web-components';

const Container = styledComponent(Box, {
    shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => (Object.assign(Object.assign({ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', backgroundColor: selected
        ? theme.palette.primary.main
        : theme.palette.background.default, width: 50, height: 50, cursor: 'pointer', userSelect: 'none' }, theme.resolveThemeStyles({
    default: {
        border: `solid thin ${theme.palette.grey[600]}`,
        color: selected ? theme.palette.text.light : theme.palette.text.primary,
    },
    wpa: {
        border: `solid thin ${selected ? theme.palette.primary.main : theme.palette.grey[600]}`,
        color: selected ? theme.palette.common.white : theme.palette.text.primary,
    },
})), { '&:hover, &:active': {
        borderColor: theme.palette.primary.main,
    }, '&[disabled]': Object.assign(Object.assign({ backgroundColor: 'transparent', border: 'none' }, theme.resolveThemeStyles({
        default: {
            color: theme.palette.text.secondary,
        },
        wpa: {
            color: theme.palette.grey[400],
        },
    })), { pointerEvents: 'none', '&>span': {
            fontWeight: 400,
        } }), '@media (max-width: 480px)': {
        width: 35,
        height: 35,
    } })));
const ValueWrapper = styledComponent(Typography)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'center',
});
const FirstAvailable = styledComponent(Box)(({ theme }) => ({
    position: 'absolute',
    borderRadius: '50%',
    backgroundColor: theme.palette.system.green,
    height: '16px',
    width: '16px',
    bottom: 0,
    right: -2,
}));

export { Container, FirstAvailable, ValueWrapper };
