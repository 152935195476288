import { styledComponent } from '@hermes/web-components';

const Container = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});
const TextItem = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    color: theme.palette.text.secondary,
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontHeight: 400,
    lineHeight: '20px',
    '&>span>b': Object.assign({}, theme.resolveThemeStyles({
        default: {
            fontWeight: 600,
        },
        wpa: {
            fontWeight: 500,
        },
    })),
}));
const TimesContent = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});
const DateTimeSection = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});
const DateLabel = styledComponent('label')(({ theme }) => (Object.assign({ color: theme.palette.text.darkNavy, fontFamily: `'Poppins', sans-serif`, fontSize: '16px', fontStyle: 'normal', lineHeight: '24px' }, theme.resolveThemeStyles({
    default: {
        fontWeight: 600,
    },
    wpa: {
        fontWeight: 500,
    },
}))));
const TimesWrapper = styledComponent('div')({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(78px, 1fr))',
    gap: '8px',
});

export { Container, DateLabel, DateTimeSection, TextItem, TimesContent, TimesWrapper };
