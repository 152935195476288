import { useEffect } from 'react';

const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current &&
                event.target instanceof Node &&
                !ref.current.contains(event.target)) {
                handler === null || handler === void 0 ? void 0 : handler();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('touchstart', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [handler, ref]);
};

export { useOnClickOutside as default };
