import React, { useState, useEffect } from 'react';
import Caret from '../../icons/Caret.esm.js';
import { Container, Select } from '../FormInput/styles.esm.js';

const InsuranceDropdown = ({ onClick, selected, validate, disabled, isError, }) => {
    const [firstRender, setFirstRender] = useState(true);
    const [selectedItem] = useState(selected);
    useEffect(() => {
        if (selected) {
            if (firstRender && (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.value) === (selected === null || selected === void 0 ? void 0 : selected.value)) {
                return;
            }
            else {
                setFirstRender(false);
                validate === null || validate === void 0 ? void 0 : validate(selected);
            }
        }
    }, [selected, firstRender]);
    return (React.createElement(Container, { disabled: disabled, error: isError },
        React.createElement(Select, { onClick: onClick }, selected === null || selected === void 0 ? void 0 : selected.label),
        React.createElement(Caret, null)));
};

export { InsuranceDropdown as default };
