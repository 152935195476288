import React, { useContext, MouseEvent, useCallback, useEffect, useState } from 'react'
import NextLink from 'next/link'
import {
  Typography,
  Grid,
  Button,
  Pagination,
  PaginationItem,
  Link,
  Edit,
  Box,
  Lazy,
  useTheme
} from '@hermes/web-components'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { transformToQuery } from '../../../utils/query'
import { SearchReviewsContext } from '../../../providers/SearchReviews'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { AppDataContext } from '../../../providers/AppData'
import { doctifyProviderDomain, endpoint, pathMap } from '../../../constants/profile'
import { Percentage, ReviewsProps } from '../../../types/reviews'
import ReviewCard from './ReviewCard'
import BasicProfileReviews from '../specialist/BasicProfileReviews'
import { handleAddCookieCTA } from '../../../utils/cookie'
import { StyledButton } from './styles'
import { themedColor } from '../styles'
import { useWpaTenant } from '../../../hooks/useWpaTenant'

const ReviewOverview = dynamic(() => import('./ReviewOverview'), { ssr: false })

const HowToWriteModal = dynamic(() => import('./HowToWriteModal'), { ssr: false })

// todo: move out if it's grow
const useComponentsConfig = () => {
  const { isWPA } = useWpaTenant()

  return { renderLoadMoreAsLink: !isWPA }
}

// eslint-disable-next-line complexity
function Reviews({
  statistic,
  showPagination,
  slug,
  id,
  plan,
  isPractice = false,
  isBasic = false,
  percentage,
  name = '',
  isProOrExpertPro = false,
  isEntry = false,
  isUnclaimed = false
}: ReviewsProps) {
  const router = useRouter()
  const { locale } = useContext(AppDataContext)
  const { buttonBackground, buttonBorder, buttonBorderRadius, reviewTitleColor } = themedColor()
  const translate = useTranslateMessage()
  const theme = useTheme()
  const { renderLoadMoreAsLink } = useComponentsConfig()
  const {
    state: {
      reviews,
      totalCount,
      filters: { page, sort, search, keyword, rating },
      canLoadNext,
      initialTotalCount,
      keywordNames
    },
    changePage
  } = useContext(SearchReviewsContext)

  const [initialPercentage, setInitialPercentage] = useState<Percentage>()
  const [howToWriteModalOpen, setHowToWriteModalOpen] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const { isWPA } = useWpaTenant()

  useEffect(() => {
    const query = transformToQuery({ sort, search, rating })

    if (query) {
      setSearchQuery(`#${query}`)
    } else {
      setSearchQuery('')
    }
  }, [sort, search, rating])

  useEffect(() => {
    setInitialPercentage(percentage)
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMarketingEmail = router.query?.utm_source === 'marketingemail'

  const claimButtonTranslate = isMarketingEmail ? 'claim-your-profile' : 'manage_your_profile'

  const entryTierLink = isMarketingEmail
    ? `/${locale}${router.asPath.split('?')[0]}/${
        locale === 'de' || locale === 'de-at' ? 'beanspruchen-sie-ihr-profil' : 'claim-your-profile'
      }`
    : doctifyProviderDomain + pathMap[locale] + endpoint

  const onChangePage = (_ev: unknown, newPage: number) => {
    if (newPage !== 1) {
      window.scroll(0, 0)
      changePage(newPage)
    }
  }

  const onLoadMore = useCallback(
    (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      event.nativeEvent.preventDefault()
      event.preventDefault()
      changePage(page ? page + 1 : 1)
      window.dataLayer.push({ event: 'prf_pg_reviews_load_more' })
    },
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  )

  const handleOpenHowToWriteModal = useCallback(() => {
    window.dataLayer.push({
      event: 'prf_pg_write_review_button'
    })
    setHowToWriteModalOpen(!howToWriteModalOpen)
  }, [howToWriteModalOpen])

  return (
    <Grid>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 3 }}>
        <Typography variant="h2" component="h2" sx={{ ...theme.custom.reviewsTitle, color: reviewTitleColor }}>
          {translate('reviews.reviews')}
        </Typography>
        {!isWPA &&
          (isUnclaimed && isBasic ? (
            <Link
              href={entryTierLink}
              underline="none"
              display="flex"
              justifyContent="space-between"
              color="primary.main"
              alignItems="center"
              onClick={() => handleAddCookieCTA({ id, slug, type: plan })}
            >
              <Typography color="inherit" mr={1} variant="body2">
                {translate(isPractice ? 'manage_name_profile' : claimButtonTranslate, {
                  name,
                  defaultMessage: 'Manage your profile'
                })}
              </Typography>
              <Edit color="inherit" fontSize="inherit" />
            </Link>
          ) : (
            <StyledButton variant="contained" onClick={handleOpenHowToWriteModal} buttonBackground={buttonBackground}>
              {translate('review.info-modal.how-to-write')}
            </StyledButton>
          ))}
      </Box>
      {(isBasic && !showPagination) || !initialTotalCount ? (
        <BasicProfileReviews isProOrExpertPro={isProOrExpertPro} isEntry={isEntry} name={name} />
      ) : (
        <>
          <Box
            sx={{
              contentVisibility: 'auto',
              containIntrinsicHeight: '535px'
            }}
          >
            <Lazy>
              <ReviewOverview
                statistic={statistic}
                keywordNames={keywordNames}
                percentage={percentage}
                initialPercentage={initialPercentage}
                reviewNumber={totalCount}
              />
            </Lazy>
          </Box>
          {reviews?.map((review) => (
            <ReviewCard
              selectedKeyword={keyword}
              key={`specialist-review-${review.id}`}
              isEntry={isEntry}
              review={review}
              highlightedText={search}
              isPractice={isPractice}
            />
          ))}
          <Grid container justifyContent="center" sx={{ mt: 3 }}>
            {showPagination ? (
              <Pagination
                sx={{ mb: 3 }}
                page={page}
                count={Math.ceil(totalCount / 10)}
                onChange={onChangePage}
                renderItem={(props) => {
                  if (['start-ellipsis', 'end-ellipsis'].includes(props.type) || props.disabled) {
                    return <PaginationItem {...props} />
                  }

                  return props.page !== 1 ? (
                    <NextLink
                      href={{
                        pathname: `/${locale}/${isPractice ? 'practice' : 'specialist'}/[slug]/reviews/[page]`,
                        query: { slug, page: `page-${props.page}` }
                      }}
                      as={`/${locale}/${isPractice ? 'practice' : 'specialist'}/${slug}/reviews/page-${
                        props.page
                      }${searchQuery}`}
                      passHref
                      shallow
                    >
                      <a style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <PaginationItem {...props} />
                      </a>
                    </NextLink>
                  ) : (
                    <NextLink
                      href={{
                        pathname: `/${locale}/${isPractice ? 'practice' : 'specialist'}/[slug]`,
                        query: { slug, page: `page-${props.page}` }
                      }}
                      as={`/${locale}/${isPractice ? 'practice' : 'specialist'}/${slug}#reviews`}
                      passHref
                    >
                      <a style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <PaginationItem {...props} />
                      </a>
                    </NextLink>
                  )
                }}
              />
            ) : (
              canLoadNext && (
                <>
                  {renderLoadMoreAsLink ? (
                    <NextLink
                      href={{
                        pathname: `/${locale}/${isPractice ? 'practice' : 'specialist'}/[slug]/reviews/[page]`,
                        query: { slug, page: `page-${page ? page + 1 : 1}` }
                      }}
                      passHref
                    >
                      <a style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Button
                          variant="outlined"
                          sx={{
                            display: canLoadNext ? 'inline-flex' : 'none',
                            color: buttonBackground,
                            border: buttonBorder,
                            borderRadius: buttonBorderRadius,
                            borderColor: buttonBackground
                          }}
                          onClick={onLoadMore}
                        >
                          {translate('reviews.load_more')}
                        </Button>
                      </a>
                    </NextLink>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{
                        display: canLoadNext ? 'inline-flex' : 'none',
                        color: buttonBackground,
                        border: buttonBorder,
                        borderRadius: buttonBorderRadius,
                        borderColor: buttonBackground
                      }}
                      onClick={onLoadMore}
                    >
                      {translate('reviews.load_more')}
                    </Button>
                  )}
                </>
              )
            )}
          </Grid>
        </>
      )}
      {howToWriteModalOpen && <HowToWriteModal handleClose={handleOpenHowToWriteModal} />}
    </Grid>
  )
}

export default Reviews
