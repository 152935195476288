import React from 'react';

const Bell = () => {
    return (React.createElement("svg", { width: "30", height: "31", viewBox: "0 0 30 31", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { clipPath: "url(#clip0_12502_5501)" },
            React.createElement("path", { d: "M19.7603 10.0944C19.3484 8.55734 18.3429 7.24683 16.9648 6.45118C15.5866 5.65553 13.9489 5.43992 12.4118 5.85178C10.8748 6.26364 9.56425 7.26923 8.7686 8.64734C7.97295 10.0254 7.75734 11.6632 8.1692 13.2003C9.98093 19.9617 7.60079 22.67 7.60079 22.67L24.9875 18.0113C24.9875 18.0113 21.572 16.8559 19.7603 10.0944Z", stroke: "#11245A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" }),
            React.createElement("path", { d: "M19.0003 23.7568C18.9089 24.0951 18.7303 24.4034 18.4823 24.6509C18.2343 24.8984 17.9256 25.0764 17.5872 25.1671C17.2487 25.2578 16.8924 25.258 16.5539 25.1676C16.2153 25.0773 15.9065 24.8996 15.6582 24.6523", stroke: "#11245A", strokeWidth: "1.5", strokeLinecap: "round", strokeLinejoin: "round" })),
        React.createElement("defs", null,
            React.createElement("clipPath", { id: "clip0_12502_5501" },
                React.createElement("rect", { width: "24", height: "24", fill: "white", transform: "translate(0.302979 7.02588) rotate(-15)" })))));
};

export { Bell as default };
