import React, { useState, useMemo } from 'react';
import TimeItem from '../TimeItem/index.esm.js';
import { Container, TextItem, TimesContent, DateTimeSection, DateLabel, TimesWrapper } from './styles.esm.js';
import { FirstAvailable } from '../TimeItem/styles.esm.js';
import { groupSlotsByDate } from './utils.esm.js';
import { DATE_LOCALE } from '../../constants.esm.js';
import { formatTime } from '../Calendar/utils.esm.js';

const SlotSelection = ({ timeSlots = [], onSlotSelect, }) => {
    const [selectedSlot, setSelectedSlot] = useState();
    const slotsGroupedByDate = useMemo(() => groupSlotsByDate(timeSlots), [timeSlots]);
    const firstSlot = useMemo(() => {
        var _a, _b;
        const firstKey = (_a = Object.keys(slotsGroupedByDate)) === null || _a === void 0 ? void 0 : _a[0];
        if (!firstKey) {
            return null;
        }
        return (_b = slotsGroupedByDate[firstKey]) === null || _b === void 0 ? void 0 : _b[0].slot;
    }, [slotsGroupedByDate]);
    const firstAvailableDateTime = useMemo(() => {
        if (!firstSlot) {
            return null;
        }
        const firstSlotDateTime = new Date(`${firstSlot.date} ${firstSlot.time}`);
        return `${firstSlotDateTime.toLocaleDateString(DATE_LOCALE, {
            weekday: 'short',
            day: 'numeric',
        })}, ${formatTime(firstSlotDateTime)}`;
    }, [firstSlot]);
    const handleSlotSelect = (slot) => {
        setSelectedSlot(slot);
        onSlotSelect === null || onSlotSelect === void 0 ? void 0 : onSlotSelect(slot);
    };
    return (React.createElement(Container, null,
        firstAvailableDateTime ? (React.createElement(TextItem, { style: { marginBottom: '24px' } },
            React.createElement(FirstAvailable, null),
            React.createElement("span", null,
                "First available appointment: ",
                React.createElement("b", null, firstAvailableDateTime)))) : null,
        React.createElement(TimesContent, null, Object.keys(slotsGroupedByDate).map((date, index) => (React.createElement(DateTimeSection, { key: index },
            React.createElement(DateLabel, null, date),
            React.createElement(TimesWrapper, null, slotsGroupedByDate[date].map(({ slot }, index) => (React.createElement(TimeItem, { key: index, onClick: () => handleSlotSelect(slot), value: slot.time, firstAvailable: (firstSlot === null || firstSlot === void 0 ? void 0 : firstSlot.id) === slot.id, selected: selectedSlot && selectedSlot.id === slot.id }))))))))));
};

export { SlotSelection as default };
