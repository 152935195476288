import { styledComponent, Box } from '@hermes/web-components';

const CalendarNavigationButton = styledComponent('button')({
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    outline: 'none',
    '&:disabled': {
        pointerEvents: 'none',
        cursor: 'none',
    },
});
const Container = styledComponent(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
    '@media (max-width: 480px)': {
        gap: '16px',
    },
});
const CalendarNavigationContainer = styledComponent(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    userSelect: 'none',
    padding: '0 3%',
    color: theme.palette.text.darkNavy,
    textAlign: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    '&>span': {
        fontWeight: 600,
    },
    '@media (max-width: 480px)': {
        padding: 0,
    },
}));
const CalendarContent = styledComponent(Box)(({ cols }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${cols !== null && cols !== void 0 ? cols : 7}, 1fr)`,
    width: '100%',
    gap: '24px',
    '@media (max-width: 480px)': {
        gap: '16px !important',
    },
}));
const PreviousMonthButton = styledComponent(CalendarNavigationButton)({
    transform: 'rotate(90deg)',
});
const NextMonthButton = styledComponent(CalendarNavigationButton)({
    transform: 'rotate(-90deg)',
});
const Cell = styledComponent(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '13.33px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    textTransform: 'uppercase',
}));
const ExpandedCalendarContainer = styledComponent(Box)({
    display: 'flex',
    gap: '10%',
    marginTop: '16px',
    width: '100%',
});
const ExpandedCalendarSide = styledComponent(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    flex: 1,
});

export { CalendarContent, CalendarNavigationContainer, Cell, Container, ExpandedCalendarContainer, ExpandedCalendarSide, NextMonthButton, PreviousMonthButton };
