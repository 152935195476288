export class GoogleRecaptcha {
  private captchaKey?: string

  constructor(captchaKey?: string) {
    this.captchaKey = captchaKey
    this.init()
  }

  private init() {
    if (typeof window !== 'undefined') {
      if (this.captchaKey) {
        const scriptSrc = `https://www.google.com/recaptcha/api.js?render=${this.captchaKey}`
        const exists = Array.from(document.scripts).find((script) => script.src === scriptSrc)

        if (!exists) {
          const script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = scriptSrc
          document.head.appendChild(script)
        }
      } else {
        console.info('Captcha key is not specified')
      }
    }
  }

  public async handleWithValidation(handler?: (headers: Record<string, unknown>) => any) {
    const headers = {
      'google-recaptcha-token': '',
      'google-recaptcha-site-id': this.captchaKey || ''
    }

    if (typeof window !== 'undefined' && window.grecaptcha && this.captchaKey) {
      await new Promise<void>((resolve) => window.grecaptcha.ready(resolve))
      const token = await window.grecaptcha.execute(this.captchaKey, { action: 'submit' })
      headers['google-recaptcha-token'] = token

      if (!handler) {
        return headers
      }

      return handler(headers)
    }

    // Fallback to process without captcha. The actual error should be caught in response
    return handler ? handler({}) : {}
  }
}
