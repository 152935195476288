function laztLoadImages(element, lazyAttribute = 'data-src') {
    if (!element) {
        return;
    }
    const imagesToLazy = Array.from(element.querySelectorAll(`img[${lazyAttribute}]`));
    if (!imagesToLazy.length) {
        return;
    }
    let observer;
    const load = (img) => {
        const src = img.getAttribute(lazyAttribute);
        const onload = () => {
            img.removeAttribute(lazyAttribute);
            img.removeEventListener('load', onload);
        };
        img.addEventListener('load', onload);
        img.setAttribute('src', src);
    };
    const observeHandler = (entries, self) => {
        for (const entry of entries) {
            if (entry.isIntersecting) {
                load(entry.target);
                if (self) {
                    self.unobserve(entry.target);
                }
            }
        }
    };
    try {
        observer = new window.IntersectionObserver(observeHandler);
        for (const img of imagesToLazy) {
            observer.observe(img);
        }
    }
    catch (_e) {
        for (const img of imagesToLazy) {
            load(img);
        }
    }
}

export { laztLoadImages as default };
