import React, { useMemo } from 'react';
import { styledComponent } from '@hermes/web-components';
import PreviewCalendar from './screens/PreviewCalendar/index.esm.js';
import DateSelection from './screens/DateSelection/index.esm.js';
import SlotSelection from './screens/SlotSelection/index.esm.js';
import BookingForm from './screens/BookingForm/index.esm.js';
import SuccessAppointment from './screens/SuccessAppointment/index.esm.js';
import SlotNotAvailable from './screens/SlotNotAvailable/index.esm.js';
import { SCREENS } from './constants.esm.js';

const AppContainer = styledComponent('div')({
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    boxSizing: 'border-box',
});
const visitTypeOptions = [
    { label: 'New patient', value: 'new' },
    { label: 'Return patient', value: 'return' },
];
const bookForOptions = [
    { label: 'Select', value: null },
    { label: 'I am booking for myself', value: 'self' },
    { label: 'I am booking for a family member', value: 'family-member' },
];
const genderOptions = [
    {
        label: 'Select',
        value: null,
        prefix: '',
    },
    {
        label: 'Female',
        value: 'F',
        prefix: 'Ms',
    },
    {
        label: 'Male',
        value: 'M',
        prefix: 'Mr',
    },
    {
        label: 'Other',
        value: 'O',
        prefix: 'Mx',
    },
];
const BookingCalendar = ({ containerStyle, currentScreen, slots, specialistProfile, practiceProfile, visitReasons, onProfileSelectionClick, onDateSelectionClick, onTimeSelectionClick, onSlotSelectionClick, onBookButtonClick, onContactRequestClick, onSlotNotAvailableBackClick, onCalendarExpandClick, onBackButtonClick, onInsuranceSelectClick, onShowMoreDatesClick, onShowMoreSlotsClick, onViewAllAvailabilityClick, selectedInsurance, selectedDateString, selectedTimeString, changeScreen, expandedView, displayMobileContactPanel, displayLocationDropdown, formFields, availabilityId, provider, source, hideTitle = false, }) => {
    const screenNavigation = useMemo(() => {
        var _a, _b, _c;
        return ({
            [SCREENS.CALENDAR_PREVIEW]: (React.createElement(PreviewCalendar, { practiceProfile: practiceProfile, specialistProfile: specialistProfile, timeSlots: slots || [], onLocationChange: onProfileSelectionClick, onDateSelect: (date) => {
                    onDateSelectionClick === null || onDateSelectionClick === void 0 ? void 0 : onDateSelectionClick(date);
                    changeScreen(SCREENS.SLOT_SELECTION);
                }, onSlotSelect: (slot) => {
                    onSlotSelectionClick === null || onSlotSelectionClick === void 0 ? void 0 : onSlotSelectionClick(slot);
                    onDateSelectionClick === null || onDateSelectionClick === void 0 ? void 0 : onDateSelectionClick(slot === null || slot === void 0 ? void 0 : slot.date);
                    onTimeSelectionClick === null || onTimeSelectionClick === void 0 ? void 0 : onTimeSelectionClick(slot === null || slot === void 0 ? void 0 : slot.time, 'profile');
                    changeScreen(SCREENS.BOOKING_FORM);
                }, onCalendarOpen: onShowMoreDatesClick, onSlotsOpen: onShowMoreSlotsClick, onContactClick: onContactRequestClick, onViewAllAvailabilityClick: onViewAllAvailabilityClick, displayMobileContactPanel: displayMobileContactPanel, displayLocationDropdown: displayLocationDropdown, hideTitle: hideTitle })),
            [SCREENS.DATE_SELECTION]: (React.createElement(DateSelection, { onBackButtonClick: onBackButtonClick, selectableDates: (slots === null || slots === void 0 ? void 0 : slots.map((slot) => slot.date)) || [], onLocationChange: onProfileSelectionClick, onDateSelect: onDateSelectionClick, onCalendarExpandClick: onCalendarExpandClick, onNextStep: () => changeScreen(SCREENS.SLOT_SELECTION), expandedView: expandedView, bookingInfo: {
                    specialistProfile: specialistProfile,
                    practiceProfile: practiceProfile,
                    dateString: selectedDateString,
                    timeString: selectedTimeString,
                }, displayLocationDropdown: displayLocationDropdown })),
            [SCREENS.SLOT_SELECTION]: (React.createElement(SlotSelection, { onBackButtonClick: onBackButtonClick, timeSlots: slots || [], onLocationChange: onProfileSelectionClick, onSlotSelect: (slot) => {
                    onSlotSelectionClick === null || onSlotSelectionClick === void 0 ? void 0 : onSlotSelectionClick(slot);
                    onDateSelectionClick === null || onDateSelectionClick === void 0 ? void 0 : onDateSelectionClick(slot === null || slot === void 0 ? void 0 : slot.date);
                    onTimeSelectionClick === null || onTimeSelectionClick === void 0 ? void 0 : onTimeSelectionClick(slot === null || slot === void 0 ? void 0 : slot.time);
                }, onNextStep: () => changeScreen(SCREENS.BOOKING_FORM), bookingInfo: {
                    specialistProfile: specialistProfile,
                    practiceProfile: practiceProfile,
                    dateString: selectedDateString,
                    timeString: selectedTimeString,
                }, displayLocationDropdown: displayLocationDropdown })),
            [SCREENS.BOOKING_FORM]: (React.createElement(BookingForm, { onBackButtonClick: onBackButtonClick, onFormSubmit: onBookButtonClick, onInsuranceSelectClick: onInsuranceSelectClick, visitTypeOptions: visitTypeOptions, visitReasonOptions: (_a = visitReasons === null || visitReasons === void 0 ? void 0 : visitReasons.map((item) => ({
                    label: item.name,
                    value: item.name,
                }))) !== null && _a !== void 0 ? _a : [], bookForOptions: bookForOptions, genderOptions: genderOptions, bookingInfo: {
                    specialistProfile: specialistProfile,
                    practiceProfile: practiceProfile,
                    dateString: selectedDateString,
                    timeString: selectedTimeString,
                    availabilityId,
                    provider,
                }, selectedInsurance: selectedInsurance, formFields: formFields, source: source })),
            [SCREENS.SUCCESS_APPOINTMENT]: (React.createElement(SuccessAppointment, { location: (_b = practiceProfile === null || practiceProfile === void 0 ? void 0 : practiceProfile.name) !== null && _b !== void 0 ? _b : '', locationContactNumber: practiceProfile === null || practiceProfile === void 0 ? void 0 : practiceProfile.contactNumber, profilePageLink: (_c = specialistProfile === null || specialistProfile === void 0 ? void 0 : specialistProfile.pageLink) !== null && _c !== void 0 ? _c : '' })),
            [SCREENS.SLOT_NOT_AVAILABLE]: (React.createElement(SlotNotAvailable, { onButtonClick: onSlotNotAvailableBackClick })),
        });
    }, [
        practiceProfile,
        specialistProfile,
        slots,
        onProfileSelectionClick,
        onShowMoreDatesClick,
        onShowMoreSlotsClick,
        onContactRequestClick,
        onViewAllAvailabilityClick,
        displayMobileContactPanel,
        displayLocationDropdown,
        hideTitle,
        onBackButtonClick,
        onDateSelectionClick,
        onCalendarExpandClick,
        expandedView,
        selectedDateString,
        selectedTimeString,
        onBookButtonClick,
        onInsuranceSelectClick,
        visitReasons,
        availabilityId,
        provider,
        selectedInsurance,
        formFields,
        source,
        onSlotNotAvailableBackClick,
        changeScreen,
        onSlotSelectionClick,
        onTimeSelectionClick,
    ]);
    return (React.createElement(AppContainer, { style: containerStyle }, screenNavigation[currentScreen]));
};

export { BookingCalendar as default };
