import { styledComponent } from '@hermes/web-components';
export { FirstAvailable } from '../../components/TimeItem/styles.esm.js';
export { TextItem } from '../../components/SlotSelection/styles.esm.js';

const Container = styledComponent('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '24px',
    '@media (max-width: 480px)': {
        gap: '18px',
    },
});
const FirstAvailableContainer = styledComponent('div')({
    display: 'flex',
    width: '100%',
});
const ExpandWrapper = styledComponent('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    padding: '0 8px',
    color: theme.palette.system.navy900,
    cursor: 'pointer',
    userSelect: 'none',
}));
const Title = styledComponent('h2')(({ theme }) => ({
    display: 'flex',
    gap: '10px',
    margin: '0',
    width: '100%',
    color: theme.palette.text.darkNavy,
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '32px',
}));

export { Container, ExpandWrapper, FirstAvailableContainer, Title };
