import React, { useState, useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import { FilterToggleWrapper, PseudoBackground, FilterToggleItem } from './styles.esm.js';

const FilterToggle = ({ onChange, items, value }) => {
    const [activeIndex, setActiveIndex] = useState(items.findIndex((item) => item.value === value));
    const [dimensions, setDimensions] = useState({ width: 0, left: 0 });
    const itemRefs = useRef([]);
    const wrapperRef = useRef(null);
    const handleChangeActiveToggle = useCallback((item, index) => {
        setActiveIndex(index);
        onChange === null || onChange === void 0 ? void 0 : onChange(item);
    }, [onChange]);
    useEffect(() => {
        setActiveIndex(items.findIndex((item) => item.value === value));
    }, [value, items]);
    useLayoutEffect(() => {
        var _a;
        const activeItemWidth = ((_a = itemRefs.current[activeIndex]) === null || _a === void 0 ? void 0 : _a.offsetWidth) || 0;
        const leftOffset = itemRefs.current
            .slice(0, activeIndex)
            .reduce((acc, ref) => acc + ((ref === null || ref === void 0 ? void 0 : ref.offsetWidth) || 0), 0);
        setDimensions({ width: activeItemWidth, left: leftOffset });
    }, [activeIndex, items]);
    return (React.createElement(FilterToggleWrapper, { ref: wrapperRef },
        React.createElement(PseudoBackground, { style: {
                left: `${dimensions.left}px`,
                width: `${dimensions.width}px`,
                transition: 'left 0.3s ease, width 0.3s ease',
            } }),
        items.map((item, index) => (React.createElement(FilterToggleItem, { key: item.value, active: activeIndex === index, onClick: useCallback(() => handleChangeActiveToggle(item, index), []), ref: (el) => {
                itemRefs.current[index] = el;
            } }, item.label)))));
};

export { FilterToggle as default };
