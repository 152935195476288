import { styledComponent } from '@hermes/web-components';

const PseudoBackground = styledComponent('div')(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: '20px',
    height: '100%',
    position: 'absolute',
    top: 0,
    transition: 'left 0.3s ease-in-out',
    width: '100%',
    zIndex: 0,
    transform: 'scale(99%, 90%)',
}));
const FilterToggleWrapper = styledComponent('div')(({ theme }) => ({
    alignItems: 'center',
    backgroundColor: theme.palette.grey[600],
    borderRadius: '27px',
    display: 'flex',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.blue[600],
    },
    p: {
        fontFamily: `'Poppins', sans-serif`,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        '@media (max-width: 560px)': {
            display: 'none',
        },
    },
}));
const FilterToggleItem = styledComponent('div')(({ theme, active }) => ({
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    borderRadius: '20px',
    fontFamily: `'Poppins', sans-serif`,
    fontSize: '14px',
    fontWeight: active ? 500 : 400,
    color: active ? theme.palette.primary.main : theme.palette.system.navy900,
    cursor: 'pointer',
    padding: '10px 0',
    textAlign: 'center',
    userSelect: 'none',
    zIndex: 1,
}));

export { FilterToggleItem, FilterToggleWrapper, PseudoBackground };
