import { FC } from 'react'
import Error from './Error'

export default function withError<P>(Component: FC<P>) {
  return ({ errorCode, ...props }: P & { errorCode: number }) => {
    if (errorCode) {
      return <Error statusCode={errorCode} />
    }

    return <Component {...(props as P)} errorCode={errorCode} />
  }
}
