import React, { useState, PropsWithChildren, ReactNode } from 'react'
import { styledComponent, Box, useTheme, useMediaQuery } from '@hermes/web-components'
import { RequestTypes } from '../constants/profile'
import { useSendAnalytic } from '../hooks/useSendAnalytic'
import { GoogleRecaptcha } from '../utils/captcha'
import getConfigVariable from '../utils/getConfigVariable'
import { useWpaTenant } from '../hooks/useWpaTenant'

export const DetailsLink = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'primary' && prop !== 'secondary' && prop !== 'tertiary'
})<{ type: 'primary' | 'secondary' | 'tertiary'; href?: string; target?: string; isWPA?: boolean }>(
  ({ theme, type, isWPA }) => ({
    maxWidth: '100%',
    width: '100%',
    flex: 1,
    color: theme.palette.common.white,
    textDecoration: 'none',
    '&:hover, &:active': isWPA
      ? {
          background: 'linear-gradient(90deg, rgb(34, 88, 177) 0%, rgb(20, 57, 119) 100%)'
        }
      : undefined,
    ...(!isWPA && type === 'primary'
      ? {
          ...(theme.components?.MuiButton?.styleOverrides?.root as object),
          ...(theme.components?.MuiButton?.styleOverrides?.containedPrimary as object)
        }
      : {}),
    ...(!isWPA && type === 'secondary'
      ? {
          backgroundColor: theme.palette.secondary.light
        }
      : {}),
    ...(!isWPA && type === 'tertiary'
      ? {
          ...(theme.components?.MuiButton?.styleOverrides?.outlinedPrimary as object)
        }
      : {}),
    cursor: 'pointer',
    padding: '8px 16px',
    borderRadius: '24px',
    border: isWPA ? 'none' : `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    backgroundColor: '#2258B1',
    ...(!isWPA && type === 'primary'
      ? {
          backgroundColor: '#2B59E0'
        }
      : {}),
    ...(!isWPA && type === 'secondary'
      ? {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main
        }
      : {}),
    ...(!isWPA && type === 'tertiary'
      ? {
          backgroundColor: theme.palette.secondary.light
        }
      : {}),
    '&:active, &:focus': isWPA
      ? undefined
      : {
          outline: 'none'
        },
    p: {
      ...theme.typography[type === 'secondary' ? 'body2' : 'body1'],
      fontSize: '15px',
      fontWeight: 500,
      margin: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    svg: {
      width: '18px',
      height: '18px',
      marginRight: '9px'
    }
  })
)

export const renderWithSpecialCharacters = (value = '', mimType = 'text/html') => {
  try {
    if (!value) {
      return ''
    }
    const parser = new DOMParser()
    return parser.parseFromString(value, mimType as DOMParserSupportedType).body.textContent || ''
  } catch (_err) {
    return value || ''
  }
}

const GOOGLE_CAPTCHA_KEY = getConfigVariable('BOOK_ACTIONS_GOOGLE_CAPTCHA_KEY')
const googleRecaptcha = new GoogleRecaptcha(GOOGLE_CAPTCHA_KEY)

export const RevealLink = ({
  children,
  href,
  revealTitle,
  skipReveal,
  newWindow,
  requestType,
  onClickRevealHandler,
  onClickLinkHandler,
  specialistId,
  practiceId,
  slug,
  icon,
  active,
  revealLink,
  contactValue,
  captchaEnabled
}: PropsWithChildren<{
  href?: string
  revealTitle: string
  skipReveal?: boolean
  newWindow?: boolean
  requestType: RequestTypes
  onClickRevealHandler?: () => void
  onClickLinkHandler?: () => void
  specialistId?: number
  practiceId?: number
  slug?: string
  icon?: ReactNode
  primaryRevealStyle?: boolean
  active?: boolean
  revealLink?: string
  contactValue: string
  captchaEnabled?: boolean
}>) => {
  const [reveal, setReveal] = useState(false)
  const sendAnalytic = useSendAnalytic(slug, specialistId, practiceId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const { isWPA } = useWpaTenant()

  const activeButtonStyle = active ? 'primary' : 'tertiary'
  const skipRevealButtonStyle = skipReveal ? activeButtonStyle : 'secondary'

  const handleAnalyticsAndCaptcha = (onSuccess: () => void) => {
    if (captchaEnabled) {
      const sendAnalyticRequest = (headers?: Record<string, unknown>) =>
        sendAnalytic(requestType, contactValue, headers).then((success) => {
          if (success) {
            onSuccess()
          }
        })
      googleRecaptcha.handleWithValidation(sendAnalyticRequest)
    } else {
      sendAnalytic(requestType, contactValue)
      onSuccess()
    }
  }

  const onClickReveal = () => {
    handleAnalyticsAndCaptcha(() => {
      setReveal(true)
      if (onClickRevealHandler) {
        onClickRevealHandler()
      }
    })
  }

  const onClickLink = () => {
    handleAnalyticsAndCaptcha(() => {
      if (onClickLinkHandler) {
        onClickLinkHandler()
      }
    })
  }

  if (!reveal && !skipReveal) {
    const type = active ? 'primary' : 'tertiary'

    return (
      <DetailsLink
        /* eslint-disable-next-line no-nested-ternary */
        component={isMobile ? 'a' : revealLink ? 'a' : 'button'}
        type={type}
        role="button"
        onClick={onClickReveal}
        isWPA={isWPA}
      >
        {icon}
        <p>{revealTitle}</p>
      </DetailsLink>
    )
  }

  return (
    <DetailsLink
      type={skipRevealButtonStyle}
      component="a"
      href={href ? renderWithSpecialCharacters(href) : undefined}
      target={newWindow && href ? '_blank' : undefined}
      onClick={onClickLink}
      role="link"
      isWPA={isWPA}
    >
      <p>{children}</p>
    </DetailsLink>
  )
}
