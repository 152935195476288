import React__default from 'react';

const CalendarOutlinedIcon = (props) => (React__default.createElement("svg", Object.assign({ width: "21", height: "21", viewBox: "0 0 21 21", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props),
    React__default.createElement("rect", { x: "2.91406", y: "4.12317", width: "15.166", height: "12.8913", rx: "2", fill: "white", fillOpacity: "0.3", stroke: "white", strokeWidth: "1.2" }),
    React__default.createElement("path", { d: "M2.91406 7.56091H18.0801", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" }),
    React__default.createElement("path", { d: "M7.375 3.26355L7.375 4.98239", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" }),
    React__default.createElement("path", { d: "M13.6172 3.26355L13.6172 4.98239", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" }),
    React__default.createElement("path", { d: "M8.125 17.0138C15.9282 17.0138 17.879 10.8208 17.879 7.72424V15.5138C17.879 16.3422 17.2074 17.0138 16.379 17.0138H8.125Z", fill: "#1A3586", stroke: "white", strokeWidth: "1.2", strokeLinecap: "round" })));

export { CalendarOutlinedIcon as default };
