import React, { useCallback, useState } from 'react'
import { Box } from '@hermes/web-components'
import ProfileMediaHeader from './Header'
import Posts from './Posts'
import { SharedLinkData } from '../../../types'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import ProfileSocials from './Socials'
import { SocialsUnavailable } from './styles'
import dynamic from 'next/dynamic'

const MediaVideoPlayerModal = dynamic(() => import('../../MediaVideoPlayerModal'), { ssr: false })

export type MediaCenterProps = {
  profileName: string
  profileImage: string
  isUnclaimed: boolean
  isBasic: boolean
  posts: SharedLinkData[]
  setVideoPlayerVisibility: (bool: boolean) => void
  profileAnalytics: {
    profile: {
      prf_type: string
      prf_uuid: string
      prf_specialty: string
      prf_practice_type?: string
      prf_facilities: boolean
    }
    review: {
      rvw_count: string
      vw_score: string
    }
  }
}

const MediaCenter = ({
  profileImage,
  profileName,
  isBasic,
  isUnclaimed,
  posts,
  setVideoPlayerVisibility,
  profileAnalytics
}: MediaCenterProps) => {
  const [selectVideoUrl, setSelectVideoUrl] = useState('')

  const videoPosts = posts.filter((post) => post.type === 'video' && post.enabled)
  const introductionVideo = posts.filter((post) => post.type === 'profile' && post.enabled)[0]
  const articlePosts = posts.filter((post) => post.type === 'article' && post.enabled)
  const socials = posts.filter(
    (post) => !['video', 'profile', 'article', 'publication', 'website'].includes(post.type) && post.enabled
  )
  const websiteLink = posts.filter((post) => post.type === 'website' && post.enabled)[0]

  const videosAvailable = (videoPosts && !!videoPosts.length) || !!introductionVideo
  const articlesAvailable = articlePosts && !!articlePosts.length
  const socialsAvailable = socials && !!socials.length
  const websiteLinkAvailable = posts.some((post) => post.type === 'website' && post.enabled)
  const translate = useTranslateMessage()

  const triggerIntroVideoAnalytics = () => {
    if (window) {
      window.dataLayer.push({
        event: 'prf_pg_prf_vid_click',
        cont_prf_name: profileName,
        ...profileAnalytics
      })
    }
  }

  const triggerVideoAnalytics = () => {
    if (window) {
      window.dataLayer.push({
        event: 'prf_pg_mc_vid_click',
        cont_prf_name: profileName,
        ...profileAnalytics
      })
    }
  }

  const triggerArticleAnalytics = () => {
    if (window) {
      window.dataLayer.push({
        event: 'prf_pg_mc_art_click',
        cont_prf_name: profileName,
        ...profileAnalytics
      })
    }
  }

  const triggerSocialsAnalytics = (type: string) => {
    if (window) {
      window.dataLayer.push({
        event: 'prf_pg_mc_soc_click',
        sc_type: type,
        cont_prf_name: profileName,
        ...profileAnalytics
      })
    }
  }

  const triggerWebAnalytics = () => {
    if (window) {
      window.dataLayer.push({
        event: 'prf_pg_mc_web_click',
        cont_prf_name: profileName,
        ...profileAnalytics
      })
    }
  }

  const onVideoClick = useCallback((url: string) => setSelectVideoUrl(url), [])

  return (
    <Box bgcolor="primary.dark">
      <ProfileMediaHeader
        videosAvailable={videosAvailable}
        articlesAvailable={articlesAvailable}
        socialsAvailable={socialsAvailable}
        websiteLinkAvailable={websiteLinkAvailable}
        isBasic={isBasic}
        name={profileName}
        image={profileImage}
        isUnclaimed={isUnclaimed}
      />
      {videosAvailable && (
        <Posts
          sortByDateText={translate('media_center.view_latest_videos')}
          loadMoreText={translate('media_center.load_more')}
          onVimeoOrYouTubeClick={onVideoClick}
          className="videos"
          title={translate('media_center.videos.title')}
          subtitle={translate('media_center.videos.subtitle', {
            name: profileName
          })}
          posts={videoPosts}
          introductionVideo={introductionVideo}
          setVideoPlayerVisibility={setVideoPlayerVisibility}
          triggerIntroVideoAnalytics={triggerIntroVideoAnalytics}
          triggerVideoAnalytics={triggerVideoAnalytics}
        />
      )}
      {articlesAvailable && (
        <Posts
          sortByDateText={translate('media_center.view_latest_articles')}
          loadMoreText={translate('media_center.load_more_articles')}
          onVimeoOrYouTubeClick={onVideoClick}
          className="articles"
          title={translate('media_center.articles.title')}
          subtitle={translate('media_center.articles.subtitle', {
            name: profileName
          })}
          posts={articlePosts}
          triggerArticleAnalytics={triggerArticleAnalytics}
        />
      )}
      {socialsAvailable || websiteLinkAvailable ? (
        <ProfileSocials
          websiteLink={websiteLink}
          socials={socials}
          articlesAvailable={articlesAvailable}
          isBasic={isBasic}
          profileName={profileName}
          profileImage={profileImage}
          isUnclaimed={isUnclaimed}
          triggerWebAnalytics={triggerWebAnalytics}
          triggerSocialsAnalytics={triggerSocialsAnalytics}
        />
      ) : (
        <SocialsUnavailable articlesAvailable={articlesAvailable} />
      )}
      <MediaVideoPlayerModal url={selectVideoUrl} isOpen={!!selectVideoUrl} onClose={() => setSelectVideoUrl('')} />
    </Box>
  )
}

export default MediaCenter
