import { useState, useMemo, useCallback } from 'react';

const useFormData = (formOptions) => {
    const [errorMessages, setErrorMessages] = useState(Object.assign({}, Object.keys(formOptions).reduce((p, key) => (Object.assign(Object.assign({}, p), { [key]: '' })), {})));
    const formSchema = useMemo(() => (Object.assign({}, Object.keys(formOptions).reduce((p, key) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return (Object.assign(Object.assign({}, p), { [key]: {
                required: (_a = formOptions[key]) === null || _a === void 0 ? void 0 : _a.required,
                type: (_b = formOptions[key]) === null || _b === void 0 ? void 0 : _b.type,
                label: (_c = formOptions[key]) === null || _c === void 0 ? void 0 : _c.label,
                items: (_d = formOptions[key]) === null || _d === void 0 ? void 0 : _d.items,
                placeholder: (_e = formOptions[key]) === null || _e === void 0 ? void 0 : _e.placeholder,
                onChange: (_f = formOptions[key]) === null || _f === void 0 ? void 0 : _f.onChange,
                onClick: (_g = formOptions[key]) === null || _g === void 0 ? void 0 : _g.onClick,
                hint: (_h = formOptions[key]) === null || _h === void 0 ? void 0 : _h.hint,
                validate: (input) => setErrorMessages((prevState) => {
                    var _a, _b, _c;
                    return (Object.assign(Object.assign({}, prevState), { [key]: (_c = (_b = (_a = formOptions[key]) === null || _a === void 0 ? void 0 : _a.validateFn) === null || _b === void 0 ? void 0 : _b.call(_a, input)) !== null && _c !== void 0 ? _c : '' }));
                }),
            } }));
    }, {}))), [formOptions]);
    const closeErrorMessage = useCallback((key) => setErrorMessages((prevState) => (Object.assign(Object.assign({}, prevState), { [key]: '' }))), []);
    return useMemo(() => [formSchema, errorMessages, closeErrorMessage], [formSchema, errorMessages, closeErrorMessage]);
};

export { useFormData as default };
